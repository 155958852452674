import { IDatoCmsModularDescription } from '@types'
import { CompanyDescriptionDetail } from '@components'

export const CompanyDescription = ({
  description,
  setActive,
  currentUser,
  mainDealsId,
  title,
  companySlug,
}: {
  description?: IDatoCmsModularDescription[]
  setActive?: Function
  currentUser?: any
  mainDealsId?: number
  title?: string
  companySlug?: string
}) => {
  let textRecord = 0
  if (
    description?.filter(item => item.__typename === 'TextRecord').length === 1
  )
    textRecord = 2

  return description ? (
    <div>
      {description.map((item, index) => {
        if (item.__typename === 'TextRecord') textRecord += 1
        return (
          <CompanyDescriptionDetail
            item={item}
            key={index}
            textRecord={textRecord}
            setActive={setActive}
            currentUser={currentUser}
            mainDealsId={mainDealsId}
            title={title ? title : ''}
            companySlug={companySlug ? companySlug : ''}
          />
        )
      })}
    </div>
  ) : (
    <></>
  )
}
