import React, { useState, useRef } from 'react'
import { Tooltip, ImageKit } from '@components'

export interface CurrencyInputProps {
  label?: string
  onChange: Function
  value: number | null
  name: string
  defaultValue?: string
  description?: string
  required?: boolean
  error?: boolean | string
  min?: number
  max?: number
  inputWidth?: string
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  disabled?: boolean
  tooltip?: string
}

export const InvestmentInput = ({
  onChange,
  value,
  name,
  defaultValue,
  description,
  required,
  error,
  inputProps,
  min,
  max,
  inputWidth,
  disabled,
}: CurrencyInputProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const numberInputRef = useRef<HTMLInputElement>(null)
  const toCurrency = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })

    return formatter.format(value)
  }

  const classess = {
    default: 'focus:ring-primary focus:border-primary border-gray-300 ',
    error:
      'focus:ring-red-500 focus:border-red-500 focus:outline-none border-red-300 placeholder-red-300',
  }

  return (
    <div>
      <div className={`relative mt-1 h-20 rounded ${inputWidth}`}>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <span className="!text-3xl font-light text-gray-500 sm:text-lg">
            $
          </span>
        </div>
        {isEditing ? (
          <input
            ref={numberInputRef}
            autoComplete="none"
            id={name}
            name={name}
            defaultValue={defaultValue}
            type="number"
            min={min}
            max={max}
            className={`${
              error ? classess.error : classess.default
            } block h-20 w-full rounded pr-10 !text-3xl shadow-sm sm:text-lg`}
            onChange={e => {
              onChange(e.target.value)
            }}
            value={value || ''}
            required={required}
            aria-describedby={name}
            onBlur={() => {
              if (min || max) {
                let _inputElement = numberInputRef?.current
                let _value = _inputElement && parseInt(_inputElement.value)
                if (_value) {
                  if (min && min > _value) {
                    _value = min
                  }
                  if (max && _value > max) {
                    _value = max
                  }
                }
                onChange(_value)
              }
              setIsEditing(!isEditing)
            }}
            onWheel={e => e.currentTarget.blur()}
            {...inputProps}
          />
        ) : (
          <input
            required={required}
            autoComplete="none"
            id={name}
            name={name}
            defaultValue={defaultValue}
            type="text"
            className={`${
              error ? classess.error : classess.default
            } block h-20 w-full rounded pr-10 !text-3xl shadow-sm sm:text-lg`}
            onChange={e => onChange(e.target.value)}
            value={value ? toCurrency(value).split('$')[1] : ''}
            aria-describedby={name}
            onFocus={() => {
              setIsEditing(!isEditing)
            }}
            onWheel={e => e.currentTarget.blur()}
            {...inputProps}
            disabled={disabled}
          />
        )}
      </div>

      {error ? (
        <p className="mt-2 uppercase text-red-600" id={`${name}-description`}>
          {error}
        </p>
      ) : description ? (
        <p className="mt-2 uppercase text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      ) : null}
    </div>
  )
}

export const CurrencyInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  description,
  required,
  error,
  inputProps,
  min,
  max,
  inputWidth,
  disabled,
  tooltip,
}: CurrencyInputProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const numberInputRef = useRef<HTMLInputElement>(null)
  const toCurrency = (value: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })

    return formatter.format(value)
  }

  const classess = {
    default: 'focus:ring-primary focus:border-primary border-gray-300 ',
    error:
      'focus:ring-red-500 focus:border-red-500 focus:outline-none border-red-300 pr-10 text-red-900 placeholder-red-300',
  }

  return (
    <div>
      <div className="relative flex items-center">
        <label
          htmlFor={name}
          className="block text-base font-medium text-themeBlack">
          {label}
          {required && <sup className="text-red-500">*</sup>}
        </label>

        {tooltip && (
          <Tooltip position="top" message={tooltip}>
            <div className="mb-0 ml-1 flex cursor-pointer items-center">
              <ImageKit
                src="/images/question.svg"
                alt="Tooltip image"
                height={16}
                width={16}
              />
            </div>
          </Tooltip>
        )}
      </div>

      <div className={`relative mt-1 rounded shadow-sm ${inputWidth}`}>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        {isEditing ? (
          <input
            ref={numberInputRef}
            autoComplete="none"
            id={name}
            name={name}
            defaultValue={defaultValue}
            type="number"
            min={min}
            max={max}
            className={`${
              error ? classess.error : classess.default
            } block w-full rounded pl-6 shadow-sm sm:text-sm`}
            onChange={e => {
              onChange(e.target.value)
            }}
            value={value || ''}
            required={required}
            aria-describedby={name}
            onBlur={() => {
              if (min || max) {
                let _inputElement = numberInputRef?.current
                let _value = _inputElement && parseInt(_inputElement.value)
                if (_value) {
                  if (min && min > _value) {
                    _value = min
                  }
                  if (max && _value > max) {
                    _value = max
                  }
                }
                onChange(_value)
              }
              setIsEditing(!isEditing)
            }}
            onWheel={e => e.currentTarget.blur()}
            {...inputProps}
          />
        ) : (
          <input
            required={required}
            autoComplete="none"
            id={name}
            name={name}
            defaultValue={defaultValue}
            type="text"
            className={`${
              error ? classess.error : classess.default
            } block w-full rounded pl-6 shadow-sm sm:text-sm`}
            onChange={e => onChange(e.target.value)}
            value={value ? toCurrency(value).split('$')[1] : ''}
            aria-describedby={name}
            onFocus={() => {
              setIsEditing(!isEditing)
            }}
            onWheel={e => e.currentTarget.blur()}
            {...inputProps}
            disabled={disabled}
          />
        )}
      </div>

      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
