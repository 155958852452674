import PhoneInput from 'react-phone-number-input'
import { useRef, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import Styles from './PhoneNumberInput.module.css'
interface PhoneNumberInputProps {
  label: string
  name: string
  onChange: Function
  value?: string
  placeholder?: string
  required?: boolean
}

export const PhoneNumberInput = ({
  label,
  name,
  onChange,
  value,
  placeholder,
  required,
}: PhoneNumberInputProps) => {
  const inputRef = useRef<any>(null)
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.required = required
    }
  }, [inputRef, required])

  return (
    <>
      <label
        htmlFor={name}
        className="mb-1 block text-base font-medium text-gray-700">
        {label}
        {required && label && <sup className="text-red-500">*</sup>}
      </label>
      <div className={Styles.phoneComponent}>
        <PhoneInput
          ref={inputRef}
          className={`block w-full rounded border border-[#D0D0D0] text-themeBlack shadow-sm focus:border-primary focus:ring-primary `}
          placeholder={placeholder}
          international
          value={value}
          onChange={(value: any) => onChange(value)}
          defaultCountry="US"
        />
      </div>
    </>
  )
}
