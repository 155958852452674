import Link from 'next/link'
import { useState } from 'react'

export const DisclosureContent = ({
  abbreviated = false,
}: {
  abbreviated?: boolean
}) => {
  return (
    <div className="text-sm">
      <p className="pt-4">
        <b>Marketxventures.com</b> is a website owned and operated by{' '}
        <b>MarketX, Inc.</b> (&quot;MarketX&quot;).
      </p>{' '}
      <p className="pt-4">
        Testimonials contained in this site are not indicative of future
        results. Information concerning the firm or any investment professionals
        of the firm can be found on FINRA’s BrokerCheck website.
        {abbreviated && (
          <>
            {' '}
            <Link
              href="/disclosure"
              className="text-white cursor-pointer pt-4 underline">
              Show full disclosure.
            </Link>
          </>
        )}
      </p>
      {!abbreviated && (
        <>
          <p className="pt-4">
            MarketX, MarketX Ventures, and MarketX Securities referred to herein
            collectedly as the &quot;MarketX Group&quot;).
          </p>
          <p className="pt-4">
            MarketX Securities LLC (&quot;MarketX Securities&quot;) and MarketX
            Ventures LLC are subsidiaries of MarketX Investment Management
            Limited. MarketX Securities LLC is an exempt reporting advisor
            (ERA). Exempt Reporting Advisers (&quot;ERA&quot;) are investment
            advisers that are not required to register as investment advisers
            because they rely on certain exemptions from registration under
            sections 203(l) and 203(m) of the Investment Advisers Act of 1940
            and related rules.
          </p>
          <p className="pt-4">
            MarketX Securities, LLC provides investment management services. The
            Company offers portfolio management, financial planning, and
            investment advisory services. MarketX Securities serves customers
            globally.
          </p>
          <p className="pt-4">
            Private placements and other alternative investments involve a high
            degree of risk. Many of these offerings are illiquid in nature and
            may require holding periods in excess of your need for liquidity.
            These investments may result in the complete loss of your principal.
            Investors must conduct their own investigation of the merits and
            risks of each opportunity. Companies seeking private placement
            investments tend to be in earlier stages of development and thus
            have not yet been fully tested in the public marketplace. The value
            of investments and the income from them can fall as well as rise.
            Past performance is not a guarantee of future performance.
            Investment products on this site are not FDIC insured, may lose
            value, and there is no bank guarantee. Please read all offering
            documents closely.
          </p>
          <p className="pt-4">
            Investments on{' '}
            <a
              className="text-link"
              rel="noreferrer"
              href="https://marketxventures.com/"
              target="_blank">
              Marketxventures.com{' '}
            </a>{' '}
            are intended only for accredited investors (for persons residing in
            the U.S.), and for persons residing abroad in jurisdictions only
            where securities registration exemptions apply.
          </p>
          <p className="pt-4">
            By accessing this website and any pages therein, you agree to be
            bound by our Terms of Use and{' '}
            <a
              className="text-link"
              rel="noreferrer"
              href="https://docs.google.com/document/u/1/d/e/2PACX-1vRn7Fe07NFLLy6eo84S0Y7N0eBbgg3n8DPnzFaRlDfwuHmZriSSj0kThBEiEz8UWL_7yMvlPH248TMf/pub"
              target="_blank">
              Privacy Policy
            </a>
            . The MarketX Group does not provide investment advice or make
            investment recommendations. No communication, through this website
            or in any other medium, should be construed as a recommendation for
            any security offering by the MarketX Group on or off this website.
          </p>
          <p className="pt-4">
            All investments posted on this website are &quot;private
            placements&quot; of securities and are not publicly traded, subject
            to significant holding period requirements, and intended for
            investors who do not need a liquidity for the entire length of the
            investment period. Investing in private companies may be considered
            highly speculative and involves a high degree of risk, including the
            risk of substantial loss of investment. Investors must be able to
            afford the loss of their entire investment. Private placement
            investments are not bank deposits (and thus not insured by the FDIC
            or by any other federal governmental agency), are not guaranteed by
            the MarketX Group or any affiliates, and may lose all or a portion
            of the investment.
          </p>
          <p className="pt-4">
            Neither the MarketX Group, the Securities and Exchange Commission,
            nor any federal or state securities commission or regulatory
            authority has recommended or approved any investment or the accuracy
            or completeness of any of the information or materials provided by
            or through the website.
          </p>
          <p className="pt-4">
            Any financial projections or returns shown on the website are
            illustrative examples only, and there can be no assurance that any
            valuations provided are accurate or in agreement with market or
            industry valuations.
          </p>
          <p className="pt-4">
            Any investment information contained herein has been secured from
            sources The MarketX Group believes are reliable, but neither the
            MarketX Group nor any affiliates make any representations or
            warranties as to the accuracy of such information and accept no
            liability therefore.
          </p>
          <p className="pt-4">
            Investors should conduct their own due diligence, not rely on the
            financial assumptions or estimates displayed on this website, and
            are encouraged to consult with a financial advisor, attorney,
            accountant, and any other professional that can help you to
            understand and assess the risks associated with any investment
            opportunity.
          </p>
          <p className="pt-4">
            To help the government fight the funding of terrorism and money
            laundering activities, Federal law requires all financial
            institutions to obtain, verify, and record information that
            identifies each person who opens an account. Therefore, when you use{' '}
            <a
              className="text-link"
              rel="noreferrer"
              href="https://marketxventures.com/"
              target="_blank">
              Marketxventures.com{' '}
            </a>{' '}
            we will ask for your name, address, date of birth, and other
            information that will allow us to identify you. We will also ask to
            see your driver&apos;s license, passport or other identifying
            documents and verification of your accredited investor status.
          </p>

          <p className="pt-4">
            MarketX and logo are trademarks of MarketX, Inc. Other trademarks
            are property of their respective owners including MarketX Ventures
            and MarketX Securities.
          </p>
        </>
      )}
      <p className="pt-4">
        © {new Date().getFullYear()} MarketX, Inc. All rights reserved.
      </p>
    </div>
  )
}
