import { Button } from '@components'
import { Mixpanel } from '@utils'

export const ManagedFundHero = () => {
  return (
    <div className="relative mt-10 md:-mt-4">
      <div className="h-full w-full overflow-y-hidden lg:h-[36rem]">
        <video
          className="hidden w-screen lg:block"
          height="100%"
          width="100%"
          autoPlay
          muted
          loop>
          <source
            src="https://uploads-ssl.webflow.com/5d9502a027228e1c5b244c82/620c28a69eb8cc45539b8a7b_san-francisco-skyline-transcode.mp4"
            type="video/mp4"
          />
          <source
            src="https://uploads-ssl.webflow.com/5d9502a027228e1c5b244c82/620c28a69eb8cc45539b8a7b_san-francisco-skyline-transcode.webm"
            type="video/ogg"
          />
        </video>
        <div className="block min-h-[600px] bg-[url('/images/managefund-mb.jpg')] bg-cover bg-center bg-no-repeat lg:hidden "></div>
      </div>
      <div className="absolute inset-0 bg-[#00000040]">
        <div className="container mx-auto px-5 py-10 text-center md:px-24 md:py-40 lg:px-[201px] lg:py-32">
          <h1 className="text-2xl font-semibold leading-8 text-white lg:text-[38px] lg:leading-[50px]">
            Invest in a Global Portfolio of Growth-Stage and Pre-IPO Companies
            with a Diversified Approach
          </h1>
          <p className="mx-auto w-full pt-10 text-lg font-light leading-8 text-white md:w-3/4 lg:w-[62%] lg:text-2xl">
            We’re pioneering a global portfolio that offers investors the option
            to diversify their approach while investing in innovative private
            companies.
          </p>
          <a
            rel="noreferrer"
            href={`${
              process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                ? process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                : 'https://calendly.com/mx-jan'
            }`}
            target="_blank"
            onClick={() => Mixpanel.track('fund.request-access')}>
            <Button className="mt-16">Request access</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
