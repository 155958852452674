import { useState, useEffect, useContext } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Logo, PartialContainer, Button } from 'components'
import classNames from 'classnames'
import { Transition, Disclosure } from '@headlessui/react'
import { routes, Route } from 'routes'
import { AuthContext } from '../../context/auth'
import { Mixpanel, AwsCognito } from '@utils'

const DesktopNav = ({
  routes,
  authenticated,
  userFirstName,
  logOutUser,
}: {
  routes: Route[]
  authenticated: boolean
  userFirstName: string
  logOutUser: Function
}) => {
  const [openSection, setOpenSection] = useState<string>()

  return (
    <div className="hidden items-center justify-end gap-4 font-body xl:flex">
      {routes?.map((link, idx) => {
        if (link.nestedRoutes) {
          const isActive: boolean = openSection === link.name

          return (
            <button
              key={idx}
              onFocus={() => {
                setOpenSection(link.name)
              }}
              onBlur={() => {
                setOpenSection(undefined)
              }}
              onMouseEnter={() => {
                setOpenSection(link.name)
              }}
              onMouseLeave={() => {
                setOpenSection(undefined)
              }}
              className="flex items-center gap-2 px-3 py-1.5">
              {link.name}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={classNames(
                  'h-5 w-5 transition-transform',
                  isActive && '-rotate-180',
                )}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
              <Transition
                show={isActive}
                className="absolute left-0 right-0 top-14 w-full bg-transparent pt-7 shadow-lg shadow-mx-purple-dark/5"
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-75 delay-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0">
                <div className="bg-gray-50">
                  <PartialContainer className="-z-10 flex w-full items-center justify-between  px-4 py-6">
                    <div className="ml-auto grid w-2/3 grid-cols-2 gap-2">
                      {link?.nestedRoutes?.map((child: any) => {
                        return (
                          <Link
                            onClick={() => {
                              setOpenSection(undefined)
                            }}
                            key={child.name}
                            href={child.path}
                            className="flex flex-col p-2 text-left transition-colors hover:bg-gray-100">
                            <span className="mb-2 font-medium text-mx-purple-dark">
                              {child.name}
                            </span>
                            <span className="text-sm">{child.detail}</span>
                          </Link>
                        )
                      })}
                    </div>
                  </PartialContainer>
                </div>
              </Transition>
            </button>
          )
        }

        if (!link.nestedRoutes) {
          return (
            <Link
              href={link.path}
              key={idx}
              className="px-3 py-1.5 hover:underline">
              {link.name}
            </Link>
          )
        }
      })}

      {authenticated && (
        <div className="flex items-center">
          <div className="flex justify-between text-themeGray-dark2">
            {userFirstName && (
              <Link href="/profiles">
                <p className="ml-2 flex items-center transition-colors hover:text-gray-700 hover:underline">
                  Hi,
                  <span className="ml-1 border-r-2 border-themeGray pr-2">
                    {userFirstName}
                  </span>
                </p>
              </Link>
            )}
            <button
              onClick={() => logOutUser()}
              className={classNames(
                'flex items-center hover:text-gray-700',
                'ml-2 transition-colors',
              )}>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

const MobileNav = ({
  routes,
  authenticated,
  logOutUser,
}: {
  routes: Route[]
  authenticated: boolean
  logOutUser: Function
}) => {
  return (
    <div className="flex items-center justify-center xl:hidden">
      <Disclosure>
        {({ open, close }) => (
          <>
            <Disclosure.Button
              className={classNames('flex items-center justify-center p-1')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </Disclosure.Button>
            <Transition
              show={open}
              className="fixed inset-0 z-50 overflow-y-auto bg-white"
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-75 delay-100"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0">
              <Disclosure.Panel>
                <PartialContainer className="flex items-center justify-between px-4 py-6">
                  <Link
                    onClick={() => close()}
                    href="/"
                    className="text-2xl font-bold text-mx-purple-dark">
                    <Logo />
                  </Link>
                  <Disclosure.Button
                    className={classNames(
                      'flex items-center justify-center p-1',
                    )}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </Disclosure.Button>
                </PartialContainer>
                <PartialContainer className="flex flex-col gap-4 pb-10">
                  {routes.map(link => {
                    if (link.nestedRoutes) {
                      return (
                        <Disclosure key={link.name}>
                          {({ open, close }) => (
                            <>
                              <Disclosure.Button className="flex w-full items-center justify-between px-3 py-1.5">
                                {link.name}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={classNames(
                                    'h-5 w-5 transition-transform',
                                    open && '-rotate-180',
                                  )}>
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-3">
                                {link?.nestedRoutes?.map((child: any) => {
                                  return (
                                    <Link
                                      onClick={() => {
                                        close
                                      }}
                                      key={child.name}
                                      href={child.path}
                                      className="flex flex-col p-2 text-left transition-colors hover:bg-gray-100">
                                      <span className="mb-2 font-medium text-mx-purple-dark">
                                        {child.name}
                                      </span>
                                      <span className="text-sm">
                                        {child.detail}
                                      </span>
                                    </Link>
                                  )
                                })}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    }

                    if (!link.nestedRoutes) {
                      return (
                        <Link
                          onClick={() => close()}
                          href={link.path}
                          key={link.name}
                          className="px-3 py-1.5 hover:underline">
                          {link.name}
                        </Link>
                      )
                    }
                  })}

                  {authenticated ? (
                    <div>
                      <Button
                        newButton
                        className="w-full"
                        newButtonSize="xsmall"
                        variant="primary"
                        onClick={() => logOutUser()}>
                        Logout
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        newButton
                        className="w-full"
                        newButtonSize="xsmall"
                        variant="primary"
                        href="/signup">
                        Get started
                      </Button>
                    </div>
                  )}
                </PartialContainer>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export const Header = ({
  showNavigation = true,
}: {
  showNavigation?: boolean
}) => {
  const authContext = useContext(AuthContext)
  const { session, user, authenticated } = authContext
  const [currentRoutes, setCurrentRoutes] = useState<Route[]>([])
  const router = useRouter()

  const token = session?.getIdToken()

  const setRoutes = () => {
    let _routes: Route[] = []
    let userRoutes = routes(user)
    if (token && token?.payload['cognito:groups']?.length) {
      token.payload['cognito:groups'].forEach((group: string) => {
        userRoutes.forEach((route: any) => {
          if (
            route?.permission?.includes(group) &&
            _routes.indexOf(route) === -1
          ) {
            _routes.push(route)
          }
        })
      })
    } else {
      _routes = userRoutes.filter(
        (item: Route) => item?.permission?.includes('Default'),
      )
    }
    _routes = _routes.filter(item => !item.hidden)
    setCurrentRoutes(_routes)
  }
  useEffect(() => {
    setRoutes()
  }, [token, user])

  const logOutUser = () => {
    AwsCognito.signOut()
      .then(() => {
        authContext.setUserSession(null)
        authContext.removeUser()
        router.push('/')
      })
      .catch((err: any) => console.log(err))
  }

  return (
    <header className="relative z-50 w-full bg-white shadow-lg shadow-mx-purple-dark/5">
      <PartialContainer className="flex items-center justify-between px-4 py-6">
        <Link href="/" className="text-2xl font-bold text-mx-purple-dark">
          <Logo />
        </Link>

        {showNavigation && (
          <DesktopNav
            routes={currentRoutes}
            authenticated={authenticated}
            userFirstName={user?.firstName ? user?.firstName : '--'}
            logOutUser={logOutUser}
          />
        )}
        {showNavigation && (
          <MobileNav
            routes={currentRoutes}
            authenticated={authenticated}
            logOutUser={logOutUser}
          />
        )}
      </PartialContainer>
    </header>
  )
}
