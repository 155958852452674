import { ImageKit, DiscountBadge, Button, BreadCrumbs } from '@components'
import { ICompany, IDeal } from '@types'
import { createHubspotDeal } from '@utils'
import { AuthContext, AuthObject } from 'context/auth'
import Link from 'next/link'
import { useContext } from 'react'
interface CompanyHeaderProps {
  company?: ICompany
  mainDeal?: IDeal | null
}

export const CompanyHeader = ({ company, mainDeal }: CompanyHeaderProps) => {
  const { user } = useContext(AuthContext)
  return company ? (
    <div className="relative">
      <BreadCrumbs
        className="mb-8 ml-4"
        pages={[
          { name: 'Direct Investments', href: '/invest/companies' },
          { name: company.name },
        ]}
      />
      <div className="flex w-full border-b pb-8 pt-4 md:pt-0">
        {company.logoUrl && (
          <div className="mr-4 h-20 w-20 min-w-[5rem] overflow-hidden rounded-lg border border-gray-200">
            <ImageKit
              alt={company.name}
              src={company.logoUrl.url}
              width={80}
              height={80}
              style={{ objectFit: 'contain' }}
              className="bg-white"
            />
          </div>
        )}
        <div className="flex w-full flex-col items-start">
          <h1
            className="-mt-1 mb-2 text-xl font-bold text-themeBlack md:text-3xl"
            id={`name-${company._id}`}>
            {company.name}
          </h1>
          <p className="mb-1 text-sm text-gray-500" id={`hook-${company._id}`}>
            {company.hook}
          </p>
          <div className="mb-1.5 flex flex-col md:flex-row">
            {company.website && (
              <div className="mr-6 pr-6 text-sm text-gray-500 md:border-r-2 md:border-gray-400">
                <a
                  className="text-primary"
                  href={company.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {company.website}
                </a>
              </div>
            )}
            {company.location && (
              <p className="pr-6 text-sm text-gray-500">{company.location}</p>
            )}
          </div>
          <div className="whitespace-wrap md:whitespace-nowrap">
            {user &&
              company.tags &&
              company.tags.map((tag, ind) => (
                <span
                  key={ind}
                  className="inline-block; mr-1 rounded-[4px] border border-primary bg-primary/5  px-1 py-0.5 text-[8px] text-primary md:text-xs">
                  {tag.name}
                </span>
              ))}
          </div>
        </div>
        {user && company.discountPercent && (
          <div>
            <div className="absolute bottom-16 left-0 w-[4.5rem] shadow-black drop-shadow-md md:relative md:bottom-4">
              <DiscountBadge discountPercent={company.discountPercent} />
            </div>
          </div>
        )}
      </div>
      <div className="xl:hidden">
        {user && mainDeal && (
          <Link
            href={`/companies/${company.slug}/deals/${mainDeal?.id}/subscribe`}>
            <Button size="xl" className="mb-4 w-full">
              Invest in Deal
            </Button>
          </Link>
        )}
        <Link
          href={process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL!}
          target="_blank"
          onClick={() => user && createHubspotDeal(user, company)}>
          <Button dark size="xl" className="mb-3 w-full">
            Discuss with Advisor
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <></>
  )
}
