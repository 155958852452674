import { ImageKit, Tooltip } from '@components'
import { IDocument } from '@types'

export interface IMyInvestmentDocumentsProps {
  investmentDocuments: IDocument[]
  taxDocuments: IDocument[]
  openDocument?: Function
}

export const MyInvestmentDocuments = ({
  investmentDocuments,
  taxDocuments,
  openDocument,
}: IMyInvestmentDocumentsProps) => {
  return (
    <div className="w-full">
      <div className="mb-3 mt-3.5 flex items-center">
        <ImageKit
          alt="Company Name"
          src="/images/documents.svg"
          width={24}
          height={24}
        />

        <p className="ml-3.5 text-base font-bold text-themeBlack">Documents</p>
      </div>
      <div className="border border-gray-200 px-10 py-5">
        <div className="relative mb-10">
          <div className="flex items-center">
            <ImageKit
              alt="Company Name"
              src="/images/doc-detail.svg"
              width={24}
              height={24}
            />
            <p className="ml-4 text-sm font-semibold text-[#222222]">
              Investment Documents
            </p>
            <Tooltip
              position="right"
              message="Each of these standard-named document are clickable to open the document for viewing and printout">
              <div className="mb-0 ml-3 flex cursor-pointer">
                <ImageKit
                  src="/images/question.svg"
                  alt="Tooltip image"
                  width={16}
                  height={16}
                />
              </div>
            </Tooltip>
          </div>
          {investmentDocuments?.map((doc, docIndex) => {
            return (
              <div className="relative flex" key={docIndex}>
                <div className="ml-3 flex border-l-[1.5px] border-primary pl-[26px] pt-2.5 before:absolute before:bottom-0 before:left-[12px] before:h-[1.5px] before:w-2 before:bg-primary">
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    {' '}
                    <p className="translate-y-2.5 pr-2 text-sm text-primary">
                      {doc.name}{' '}
                    </p>
                  </a>
                  {doc?.userSignatureId && !doc?.userSignedAt ? (
                    <p
                      className={`translate-y-2.5 border-l pl-2 text-sm font-normal text-primary ${
                        openDocument ? 'cursor-pointer' : 'cursor-not-allowed'
                      }`}
                      onClick={() => {
                        openDocument &&
                          openDocument(
                            doc?.userSignatureId ? doc?.userSignatureId : '',
                          )
                      }}>
                      Sign
                    </p>
                  ) : doc?.userSignatureId && doc?.userSignedAt ? (
                    <div className="flex translate-y-2.5 text-sm font-normal">
                      <p className="border-l px-2 text-[#3DA94E]">Signed</p>
                      <a
                        rel="noopener noreferrer"
                        className="border-l pl-2 text-primary"
                        href={
                          process.env.NEXT_PUBLIC_API_URL +
                          `/hello-sign/download-agreement/${doc?.signedDocumentDownloadId}`
                        }>
                        Download
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className="relative mb-3">
          <div className="flex items-center">
            <ImageKit
              alt="Company Name"
              src="/images/doc-detail.svg"
              width={24}
              height={24}
            />
            <p className="ml-4 text-sm font-semibold text-[#222222]">
              Tax Documents
            </p>
          </div>
          {taxDocuments?.map((doc, docIndex) => {
            return (
              <div className="relative flex" key={docIndex}>
                <div className="ml-3 border-l-[1.5px] border-primary pl-[26px] pt-2.5 before:absolute before:bottom-0 before:left-[12px] before:h-[1.5px] before:w-2 before:bg-primary">
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    {' '}
                    <p className="translate-y-2.5 text-sm text-primary">
                      {doc.name}{' '}
                    </p>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
