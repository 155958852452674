import { Tooltip } from '@components/Tooltip'
import { ITable } from '@types'
import Link from 'next/link'
import { getImageUrl } from 'utils/getImageUrl'
import { abbreviateNumber } from '../../utils'
import classNames from 'classnames'

export const Table: React.FC<ITable> = ({
  data,
  columns,
  className,
  grayTableHead,
  rowLink,
  slug,
}) => {
  return (
    <div className={classNames('', className)}>
      <table className="min-w-full divide-y divide-gray-300">
        <thead
          className={`${
            grayTableHead
              ? 'bg-[#F3F4F6] text-themeBlack'
              : 'bg-[#374151] text-white'
          }`}>
          <tr>
            {columns.map((column, idx) => (
              <th
                key={idx}
                scope="col"
                className={classNames(
                  'px-4 py-3.5 text-sm font-semibold lg:px-6',
                  column.className,
                  column.align ? `text-${column.align}` : 'text-left',
                )}>
                {column.tooltip ? (
                  <div
                    className={`relative flex items-center justify-${
                      column.align === 'right' ? 'end' : column.align
                    }`}>
                    {column.name}
                    <Tooltip
                      className="w-max max-w-xs bg-primary"
                      message={column.tooltip}
                      position="bottom">
                      <img
                        src={getImageUrl({
                          src: '/images/question-image-gray.svg',
                        })}
                        className="mb-0 ml-2 cursor-pointer"
                        alt="question"
                      />
                    </Tooltip>
                  </div>
                ) : (
                  column.name
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.length > 0 ? (
            data.map((row, idx) => (
              <tr key={idx}>
                {columns.map((column, colIdx) => {
                  const text = row[column.accessor]
                  const cell = column.render?.(text, row) ?? text
                  typeof text === 'number' ? abbreviateNumber(text) : text
                  if (!rowLink) {
                    return (
                      <td
                        style={{
                          width: column.width || 'auto',
                        }}
                        key={colIdx}
                        className={classNames(
                          'whitespace-nowrap px-4 py-5 text-sm font-medium text-themeBlack lg:px-6',
                          column.className,
                          column.align ? `text-${column.align}` : 'text-left',
                          idx === data.length - 1
                            ? 'rounded-bl-xl rounded-br-xl'
                            : '',
                        )}>
                        {cell ? cell : '--'}
                      </td>
                    )
                  } else {
                    let currentLink = rowLink.includes('id')
                      ? rowLink.replace('id', row[slug as string].toString())
                      : rowLink
                    return (
                      <td
                        key={colIdx}
                        style={{
                          width: column.width || 'auto',
                        }}>
                        <Link legacyBehavior href={currentLink}>
                          <a
                            className={classNames(
                              'inline-block w-full whitespace-nowrap px-4 py-4 text-sm font-medium text-themeBlack lg:px-6',
                              column.className,
                              column.align
                                ? `text-${column.align}`
                                : 'text-left',
                            )}>
                            {cell}
                          </a>
                        </Link>
                      </td>
                    )
                  }
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="flex w-full flex-col items-center justify-center p-6 text-xs text-gray-400">
                  No Data Found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
