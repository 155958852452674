import { ImageKit } from '@components'

const fundSector = [
  {
    id: 1,
    image: 'transportation.png',
    title: 'Next-Gen Transportation',
    desc: 'The convergence of sensors, computing, 5G networks, and battery technology is transforming all modes of transportation: on-the-ground, in-the-air, and in-the-sea.',
  },
  {
    id: 2,
    image: 'industry-cloud.png',
    title: 'Industry Cloud',
    desc: 'A focus on UI/UX design, machine learning, and wide adoption of cloud software is changing workplace tools in all verticals, ranging from real estate, legal, to financial services.',
  },
  {
    id: 3,
    image: 'financial-services.png',
    title: 'New Financial Services',
    desc: 'Simpler, faster, and more accessible products and services in a traditionally slow-moving industry; billions gain access to financial services through mobile applications.',
  },
  {
    id: 4,
    image: 'creative-living.png',
    title: 'Creative Living',
    desc: 'Higher property utilization, data driven optimization, and robotics are challenging traditional players in the real estate industry, from construction, financing, to leasing.',
  },
  {
    id: 5,
    image: 'metaverse.png',
    title: 'Metaverse/Blockchain',
    desc: 'The COVID-19 pandemic accelerated ongoing trends in how we choose to spend our leisure time. Now more than ever, consumers have turned to new means of interaction, utilizing online worlds and new technologies to express themselves and their interests.',
  },
  {
    id: 6,
    image: 'lifestyle-education.png',
    title: 'Lifestyle & Education',
    desc: 'Internet and mobile technologies have changed how goods, services, and information reach billions of people. We are more connected now than ever before, with new resources being shared and learned worlwide every day.',
  },
]

export const SectorOverview = () => {
  return (
    <div className="bg-white px-5 py-20 lg:px-0">
      <div className="container mx-auto">
        <div className="mx-auto w-full md:w-9/12 lg:w-[43%] 2xl:w-[37%]">
          <h1 className="leading-12 text-center text-[21px] font-semibold text-[#333] lg:text-[38px]">
            MarketX Managed Fund Sector Overview
          </h1>
          <p className="pt-5 text-center text-base font-light leading-[29px] text-[#333] md:text-xl">
            Our Managed Fund Focuses On Investments In <br /> Six Sectors Where
            Technology Innovations Are Making Long-Lasting Impacts On The Global
            Economy
          </p>
        </div>
        <div className="flex flex-col flex-wrap items-start justify-between px-0 md:flex-row lg:flex-row lg:px-48">
          {fundSector?.map(item => (
            <div key={item?.id} className="w-11/12 pt-12 md:w-[45%]">
              <div className="flex items-center justify-center">
                <ImageKit
                  src={`/images/${item?.image}`}
                  alt={item?.title}
                  width={40}
                  height={40}
                />
              </div>
              <h4 className="pt-5 text-center text-lg font-bold text-[#333]">
                {item?.title}
              </h4>
              <p className="pb-4 pt-6 text-left text-[15px] font-normal text-[#333]">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
