import {
  Header,
  Footer,
  InvitationBanner,
  GlobalBanner,
  ConsentCookie,
} from '@components'
import { IInvestmentInvitation, IGlobal } from '@types'
import classNames from 'classnames'
import { useState } from 'react'

export const AppLayout = ({
  children,
  className,
  showNavigation = true,
  showBanner = true,
  showFooter = true,
}: {
  children?: any
  className?: string
  showNavigation?: boolean
  showBanner?: boolean
  showFooter?: boolean
}) => {
  const [currentInvitation, setCurrentInvitation] =
    useState<IInvestmentInvitation>({})
  const [invitationLoading, setInvitationLoading] = useState(true)
  const [globalBanner, setGlobalBanner] = useState<IGlobal>({})

  return (
    <>
      <div className="sticky top-0 z-[100] w-full">
        {showBanner && (
          <InvitationBanner
            currentInvitation={currentInvitation}
            setCurrentInvitation={setCurrentInvitation}
            setInvitationLoading={setInvitationLoading}
          />
        )}

        {!currentInvitation.id && !invitationLoading && (
          <GlobalBanner
            globalBanner={globalBanner}
            setGlobalBanner={setGlobalBanner}
          />
        )}

        <Header showNavigation={showNavigation} />
      </div>
      <div
        className={classNames(`${!showFooter && 'pb-12 md:pb-32'}`, className)}>
        {children}
      </div>
      {showFooter && <Footer />}
    </>
  )
}
