import { ImageKit, Tooltip } from '@components'
import classNames from 'classnames'
import { abbreviateNumber, percentageRoundTo } from '@utils'
export const InvestmentTerms = ({
  text,
  value,
  tooltip,
  bordered,
  notCurrency,
  percentage,
  carryFeeValue,
  simple,
  valueBold,
}: {
  text?: string
  value: string | number
  tooltip?: string
  bordered?: boolean
  notCurrency?: boolean
  percentage?: boolean
  carryFeeValue?: string
  simple?: boolean
  valueBold?: boolean
}) =>
  text ? (
    <>
      {notCurrency ? (
        <tr className={classNames(bordered && 'divide-x divide-gray-200')}>
          <div className="flex items-center">
            <td className="py-3 pl-6 pr-2 font-bold">{text}</td>
            {tooltip && (
              <Tooltip message={tooltip} position="right">
                <div className="mb-0 flex cursor-pointer">
                  <ImageKit
                    src="/images/question.svg"
                    alt="Tooltip image"
                    width={16}
                    height={16}
                  />
                </div>
              </Tooltip>
            )}
          </div>
          <td className="px-6 py-3">
            <span
              className={classNames(
                'rounded-sm border px-1 py-0.5 text-xs uppercase',
                value === 'common'
                  ? 'border-sky-500 bg-sky-100'
                  : 'border-purple-500 bg-purple-100',
              )}>
              {value}
            </span>
          </td>
        </tr>
      ) : (
        <tr className={classNames(bordered && 'divide-x divide-gray-200')}>
          <div className="flex items-center">
            <td className="py-3 pl-6 pr-2 font-bold">{text}</td>
            {tooltip && (
              <Tooltip message={tooltip} position="right">
                <div className="mb-0 flex cursor-pointer">
                  <ImageKit
                    src="/images/question.svg"
                    alt="Tooltip image"
                    width={16}
                    height={16}
                  />
                </div>
              </Tooltip>
            )}
          </div>
          <td className={`px-6 py-3 ${valueBold && 'font-bold'}`}>
            {percentage
              ? percentageRoundTo(value) +
                ' per year, ' +
                percentageRoundTo(carryFeeValue!) +
                ' Carry'
              : simple
              ? `${value}`
              : abbreviateNumber(value)}
          </td>
        </tr>
      )}
    </>
  ) : (
    <></>
  )
