import { Button } from '@components'
import { useRouter } from 'next/router'
import { Mixpanel } from '@utils'
import { useLocalStorage } from '@hooks'
export interface IOnBoardingPopup {
  setOnBoardingPopup: Function
  profileId?: Number
}

export const OnBoardingPopup = ({ profileId }: IOnBoardingPopup) => {
  const router = useRouter()
  const [localStorageRedirectAfterLogin, setLocalStorageRedirectAfterLogin] =
    useLocalStorage<string>('mx-redirect-after-login', '')

  const onAuthenticationComplete = () => {
    const redirectPath = localStorageRedirectAfterLogin || '/invest/companies'
    setLocalStorageRedirectAfterLogin('')
    router.push(redirectPath)
  }

  const proceedClickHandler = () => {
    Mixpanel.track('Onboarding-Completed-onboarding')
    onAuthenticationComplete()
  }

  return (
    <div
      className="relative z-[60]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed top-[25%] z-10 mt-0 overflow-y-auto md:inset-0 md:top-0 md:mt-20">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
            <div className="flex flex-col items-center justify-center bg-white px-16 py-8">
              <p className="pb-4 text-lg font-semibold text-themeBlack">
                All done! Welcome to the MarketX community!
              </p>
              <p className="pb-4 text-sm leading-5 text-themeBlack">
                Thank you for signing up! You can now fully access our exclusive
                deals, updates, events, and world-class insights. Go for it.
                Explore, learn, and invest.
              </p>
              <p className="pb-4 text-sm leading-5 text-themeBlack">
                Know, you are never alone. Here at MarketX Ventures, we offer
                personal support and guidance from our experienced Investor
                Relations team. Revel in your first-class status. Click here to
                schedule a{' '}
                <a
                  rel="noreferrer"
                  href={
                    profileId &&
                    `${
                      process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                        ? process.env.NEXT_PUBLIC_DEFAULT_CALENDLY_URL
                        : 'https://calendly.com/mx-jan'
                    }`
                  }
                  target="_blank"
                  className="text-link underline">
                  Call
                </a>
                .
              </p>

              <div className="mt-8 flex items-center">
                <Button onClick={proceedClickHandler}>Proceed</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
