import { ImageKit, Tooltip } from '@components'
export interface PageHeaderProps {
  title: string
  extra?: React.ReactNode
  center?: boolean
  tooltip?: string
}

export const PageHeader = ({
  title,
  extra,
  center,
  tooltip,
}: PageHeaderProps) => {
  return (
    <div
      className={`my-6 items-center ${
        center ? 'justify-center' : 'justify-between'
      }`}>
      <div>
        <h1 className="font-heading text-lg font-semibold text-themeBlack md:text-3xl">
          {title}
        </h1>
        {tooltip && (
          <Tooltip position="top" message={tooltip}>
            <div className="mb-0 ml-3 flex cursor-pointer">
              <ImageKit
                src="/images/question.svg"
                alt="Tooltip image"
                width={16}
                height={16}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {extra && <div className="mt-4 ">{extra}</div>}
    </div>
  )
}
