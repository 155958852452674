import { ImageKit } from '@components'
import Image from 'next/image'

export const Loader = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <ImageKit
        alt="Loader"
        src="/images/main-loader.svg"
        height={80}
        width={80}
      />
    </div>
  )
}
