import { Button } from '@components/Button'
import {
  CurrencyInput,
  SelectInput,
  TextInput,
  PercentageInput,
  DateInput,
  NumberInput,
  AccountNumberInput,
  CheckboxInput,
} from '@components/inputs'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { PuffLoader } from 'react-spinners'

interface ICompany {
  id: number
  name: string
  slug: string
  datoCmsId: string
}

export interface IDeal {
  company?: number
  sharePrice: number | null
  shareClass?: 'common' | 'preferred' | 'convertible_note'
  valuation: number | null
  minimumInvestment: number | null
  allocation: number | null
  mxFundName: string
  managementFee: number | null
  carryFee: number | null
  brokerFee?: number | null
  impliedValuation: number | null
  closingAt?: Date | null
  yearsOfManagementFeeDueUpfront?: number | null
  shareSource?: 'primary' | 'secondary'
  accountNumber: string
  isDocumentSigningActivated?: boolean
}

interface IDealFormProps {
  onSubmit: (values: IDeal) => void
  submitting?: boolean
  defaultValues?: IDeal
}

const DealForm = ({ defaultValues, onSubmit, submitting }: IDealFormProps) => {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [dealData, setDealData] = useState<IDeal>(
    () =>
      defaultValues || {
        company: undefined,
        shareClass: undefined,
        sharePrice: null,
        valuation: null,
        minimumInvestment: null,
        allocation: null,
        mxFundName: '',
        managementFee: null,
        carryFee: null,
        brokerFee: null,
        impliedValuation: null,
        closingAt: null,
        yearsOfManagementFeeDueUpfront: null,
        shareSource: undefined,
        accountNumber: '',
        isDocumentSigningActivated: true,
      },
  )
  const router = useRouter()

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_API_URL + '/admin/companies')
      .then(res => {
        setCompanies(res.data)
        setLoading(false)
      })
      .catch(err => {
        setCompanies([])
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (companies.length) {
      setDealData(d => ({
        ...d,
        company: d.company === undefined ? companies[0].id : d.company,
      }))
    }
  }, [companies])

  if (loading) {
    return <PuffLoader size={20} color={'black'} />
  }

  const handleChange = (value: any, key: string) => {
    setDealData(d => ({ ...d, [key]: value }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit({
      ...dealData,
      sharePrice: dealData?.sharePrice || 0,
      valuation: dealData?.valuation || 0,
      minimumInvestment: dealData?.minimumInvestment || 0,
      allocation: dealData?.allocation || 0,
      managementFee: dealData?.managementFee || 0,
      carryFee: dealData?.carryFee || 0,
      impliedValuation: dealData?.impliedValuation || 0,
      yearsOfManagementFeeDueUpfront:
        dealData?.yearsOfManagementFeeDueUpfront || 0,
      brokerFee: dealData?.brokerFee || 0,
      isDocumentSigningActivated: dealData?.isDocumentSigningActivated,
    })
  }

  return (
    <div className="mx-auto w-full rounded-md border border-themeGray px-10 py-8 md:w-2/3">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <SelectInput
              required
              options={companies?.map(company => ({
                value: company.id,
                text: company.name,
              }))}
              name="company"
              label="Company"
              defaultValue={dealData.company}
              onChange={(e: any) => {
                handleChange(e.target.value, 'company')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <CurrencyInput
              name="sharePrice"
              label="Share Price"
              value={dealData.sharePrice}
              onChange={(value: any) => {
                handleChange(parseFloat(value), 'sharePrice')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <SelectInput
              required
              options={[
                {
                  value: '',
                  label: 'Select',
                },
                {
                  value: 'common',
                  label: 'Common',
                },
                {
                  value: 'preferred',
                  label: 'Preferred',
                },
                {
                  value: 'convertible_note',
                  label: 'Convertible Note',
                },
              ]}
              name="shareClass"
              label="Share Class"
              defaultValue={dealData.shareClass}
              onChange={(e: any) => {
                handleChange(e.target.value, 'shareClass')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <SelectInput
              required
              options={[
                {
                  value: '',
                  label: 'Select',
                },
                {
                  value: 'primary',
                  label: 'Primary',
                },
                {
                  value: 'secondary',
                  label: 'Secondary',
                },
              ]}
              name="shareSource"
              label="Share Source"
              defaultValue={dealData.shareSource}
              onChange={(e: any) => {
                handleChange(e.target.value, 'shareSource')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <CurrencyInput
              name="valuation"
              label="Valuation"
              value={dealData.valuation}
              onChange={(value: any) => {
                handleChange(parseInt(value), 'valuation')
              }}
            />
          </div>
          <div className="mb-6">
            <CurrencyInput
              name="impliedValuation"
              label="Implied Valuation:"
              value={dealData.impliedValuation}
              onChange={(value: any) => {
                handleChange(parseInt(value), 'impliedValuation')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <CurrencyInput
              name="minimumInvestment"
              label="Minimum Investment"
              value={dealData.minimumInvestment}
              onChange={(value: any) => {
                handleChange(parseInt(value), 'minimumInvestment')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <CurrencyInput
              name="allocation"
              label="Allocation"
              value={dealData.allocation}
              onChange={(value: any) => {
                handleChange(parseInt(value), 'allocation')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <AccountNumberInput
              name="accountNumber"
              label="Account Number"
              value={dealData?.accountNumber}
              onChange={(e: any) => {
                handleChange(e, 'accountNumber')
              }}
              maxLength={20}
              pattern="^[0-9]{6,20}$"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <TextInput
              name="mxFundName"
              label="MarketX Ventures Fund Name"
              value={dealData.mxFundName}
              onChange={(e: any) => {
                handleChange(e.target.value, 'mxFundName')
              }}
            />
          </div>
          <div className="mb-6">
            <PercentageInput
              name="managementFee"
              label="Management Fee"
              value={dealData?.managementFee}
              onChange={(e: any) => {
                handleChange(parseFloat(e.target.value), 'managementFee')
              }}
            />
          </div>
          <div className="mb-6">
            <NumberInput
              name="yearsOfManagementFeeDueUpfront"
              label="# Years of Management Fees Due Upfront"
              min={0}
              value={dealData?.yearsOfManagementFeeDueUpfront!}
              onChange={(e: any) => {
                handleChange(
                  parseInt(e.target.value),
                  'yearsOfManagementFeeDueUpfront',
                )
              }}
            />
          </div>
          <div className="mb-6">
            <PercentageInput
              name="carryFee"
              label="Carry Fee"
              value={dealData?.carryFee}
              onChange={(e: any) => {
                handleChange(parseFloat(e.target.value), 'carryFee')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <PercentageInput
              name="brokerFee"
              label="Broker Fee (If Applicable)"
              value={dealData?.brokerFee ? dealData?.brokerFee : 0}
              onChange={(e: any) => {
                handleChange(parseFloat(e.target.value), 'brokerFee')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-2">
          <div className="mb-6">
            <DateInput
              isPastDate
              label="Closing At"
              value={dealData?.closingAt || null}
              onChange={(value: Date) => {
                handleChange(value, 'closingAt')
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 text-lg md:grid-cols-2">
          <div className="mb-6 flex items-start justify-between">
            <div className="flex flex-col">
              <dt className="block text-base font-medium text-gray-700">
                Document Signing Activated
              </dt>
              <span className="pr-2 pt-1 text-sm text-gray-500">
                If document signing is inactive, the user will be contacted by
                sales to finalize the investment process.
              </span>
            </div>
            <CheckboxInput
              label=""
              name="isDocumentSigningActivated"
              value={dealData?.isDocumentSigningActivated || false}
              onChange={(e: any) => {
                handleChange(e.target.checked, 'isDocumentSigningActivated')
              }}
            />
          </div>
        </div>

        <div className="flex items-center">
          <Button
            secondary
            className="mr-2"
            disabled={submitting}
            onClick={router.back}>
            Cancel
          </Button>
          <Button submit disabled={submitting}>
            {submitting ? <PuffLoader size={20} color={'white'} /> : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default DealForm
