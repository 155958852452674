import ReactPlayer from 'react-player/lazy'
import { getImageUrl } from 'utils/getImageUrl'
export const VideoPopup = ({
  videoUrl,
  closeModal,
}: {
  videoUrl: string
  closeModal: Function
}) => {
  return (
    <div
      className="relative z-[110] cursor-pointer overflow-hidden"
      onClick={e => {
        closeModal()
      }}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-[110] overflow-hidden">
        <div className="mx-auto flex h-screen  w-10/12 flex-col justify-center pt-20 lg:w-11/12 xl:w-[1100px]">
          <div className="relative">
            <div
              className="absolute right-0 top-0 -mr-10 mt-52 h-8 w-8 sm:mt-24 md:mt-12 lg:-mr-2 lg:-mt-4"
              onClick={e => {
                closeModal()
              }}>
              <img
                src={getImageUrl({
                  src: '/images/close-invitation.png',
                })}
              />
            </div>
            <ReactPlayer
              loop
              className="video-player-popup"
              playing={true}
              url={videoUrl}
              width="100%"
              height="70vh"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
