import { ImageKit } from '@components'
import { Mixpanel } from '@utils'

const SocialMediaData = [
  {
    href: 'https://api.whatsapp.com/send?phone=13057474927',
    src: '/images/social/icon-whatsapp.svg',
    alt: 'whatsapp',
    width: 20,
    height: 20,
    name: 'Whatsapp',
  },
  {
    href: 'https://t.me/+zAcN-iAYAtxkMzgx',
    src: '/images/social/icon-telegram.svg',
    alt: 'telegram',
    width: 20,
    height: 20,
    name: 'Telegram',
  },
  {
    href: '/social/wechat',
    src: '/images/social/icon-wechat.svg',
    alt: 'wechat',
    width: 20,
    height: 20,
    name: 'WeChat',
  },
  {
    href: 'https://www.instagram.com/marketxventures',
    src: '/images/social/icon-instagram.svg',
    alt: 'instagram',
    width: 20,
    height: 20,
    name: 'Instagram',
  },
  {
    id: 4,
    href: 'https://www.linkedin.com/company/marketx-inc-',
    src: '/images/social/icon-linkedin.svg',
    alt: 'Linkedin',
    width: 20,
    height: 20,
    className: 'mb-0.5',
    name: 'LinkedIn',
  },
]
export const SocialMedia = () => {
  const trackSocialMediaEvent = (iconName: string) => {
    Mixpanel.track('Click-Social-Media-Header', {
      socialMediaIcon: iconName,
    })
  }
  return (
    <div className="mr-6 flex items-center justify-center">
      {SocialMediaData.map(item => (
        <div
          className={`mr-4 ${item.className ? item.className : ''}`}
          onClick={() => trackSocialMediaEvent(item.name)}
          key={item.alt}>
          <a
            rel="noreferrer"
            href={item.href}
            target="_blank"
            className="hover:opacity-70">
            <ImageKit
              src={item.src}
              alt={item.alt}
              width={item.width}
              height={item.height}
            />
          </a>
        </div>
      ))}
    </div>
  )
}
