import { Table, Button } from '@components'
export const InvitationDetailPopup = ({ setPopUp }: { setPopUp: Function }) => {
  const data = [
    {
      reward: '20,000',
      fee: '$200',
    },
    {
      reward: '50,000',
      fee: '$500',
    },
    {
      reward: '100,000',
      fee: '$1,000',
    },
  ]
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0 md:min-h-full">
          <div className="relative w-7/12 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex flex-col px-16 py-8">
              <h5 className="text-center text-lg font-semibold text-themeBlack">
                How MarketX Ventures Reward Points work
              </h5>
              <Table
                className="mb-4 mt-8 w-full rounded-xl border"
                data={data}
                columns={[
                  {
                    name: 'MarketX Ventures Reward Point Tiers',
                    accessor: 'reward',
                    className: 'rounded-tl-xl',
                    width: '50%',
                  },
                  {
                    name: 'Next Deal Management Fee Credit',
                    accessor: 'fee',
                    className: 'rounded-tr-xl',
                    width: '50%',
                  },
                ]}
              />
              <p className="flex items-start text-sm font-normal text-themeBlack">
                <span className="-mt-1.5 mr-1 text-lg text-primary">
                  &#x2022;
                </span>{' '}
                Points may expire in 12 months if not used towards an investment
                opportunity
              </p>
              <div className="mt-8 flex justify-center">
                <Button
                  onClick={() => {
                    setPopUp(false)
                  }}
                  light>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
