import Image, { ImageProps } from 'next/image'
import React from 'react'
import { getImageUrl } from 'utils/getImageUrl'

export const ImageKit: React.FC<ImageProps> = ({ ...props }) => {
  props.alt || (props.alt = '')
  return (
    <>
      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost' ? (
        <Image {...props} />
      ) : (
        <Image loader={getImageUrl} {...props} />
      )}
    </>
  )
}
