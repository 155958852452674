export const Logo = ({ className = 'h-5 w-auto fill-mx-purple-dark' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 147 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1851_2910)">
        <path d="M60.2047 1.09961H56.4047C56.338 1.09961 56.3047 1.13294 56.3047 1.19961L50.3047 13.8996L44.3047 1.19961C44.3047 1.09961 44.2047 1.09961 44.2047 1.09961H40.4047C40.3047 1.09961 40.3047 1.19961 40.3047 1.19961L38.8047 21.2996V21.3996H43.0047C43.1047 21.3996 43.1047 21.2996 43.1047 21.2996L43.8047 9.39961L48.0047 17.9996C48.0047 18.0996 48.1047 18.0996 48.1047 18.0996H52.4047C52.4714 18.0996 52.5047 18.0663 52.5047 17.9996L56.7047 9.39961L57.4047 21.2996C57.4047 21.3996 57.5047 21.3996 57.5047 21.3996H61.7047V21.2996L60.4047 1.19961C60.4047 1.19961 60.3047 1.09961 60.2047 1.09961Z" />
        <path d="M69.9031 6.2002C68.8031 6.2002 67.8031 6.3002 66.9031 6.6002C66.0031 6.8002 65.1031 7.2002 64.3031 7.6002C64.2031 7.6002 64.2031 7.7002 64.2031 7.8002L65.0031 10.7002C65.0031 10.7002 65.0031 10.8002 65.1031 10.8002H65.2031C65.7031 10.5002 66.3031 10.3002 67.0031 10.1002C67.7031 9.9002 68.4031 9.8002 69.1031 9.8002C70.0031 9.8002 70.7031 10.0002 71.2031 10.5002C71.6031 11.0002 71.9031 11.7002 71.9031 12.6002V12.9002C71.4031 12.9002 70.9031 12.9002 70.3031 13.0002C69.6031 13.0002 69.0031 13.1002 68.4031 13.1002C66.8031 13.2002 65.5031 13.6002 64.6031 14.3002C63.7031 15.0002 63.2031 16.1002 63.2031 17.5002C63.2031 18.1002 63.3031 18.7002 63.5031 19.2002C63.7031 19.7002 64.0031 20.2002 64.4031 20.6002C64.8031 21.0002 65.2031 21.3002 65.8031 21.5002C66.3031 21.7002 66.9031 21.8002 67.6031 21.8002C68.6031 21.8002 69.5031 21.6002 70.3031 21.2002C71.0031 20.9002 71.5031 20.5002 72.0031 20.1002V21.3002C72.0031 21.4002 72.1031 21.4002 72.1031 21.4002H76.1031C76.2031 21.4002 76.2031 21.3002 76.2031 21.3002V12.7002C76.2031 10.6002 75.6031 9.0002 74.5031 7.9002C73.5031 6.7002 71.9031 6.2002 69.9031 6.2002ZM71.9031 15.6002V17.7002C71.5031 18.0002 71.1031 18.2002 70.7031 18.4002C70.2031 18.6002 69.7031 18.7002 69.1031 18.7002C68.6031 18.7002 68.2031 18.6002 67.9031 18.4002C67.6031 18.2002 67.5031 17.9002 67.5031 17.4002C67.5031 16.9002 67.7031 16.6002 68.0031 16.4002C68.3031 16.2002 68.8031 16.0002 69.4031 15.9002C69.8031 15.8002 70.2031 15.8002 70.7031 15.8002C71.2031 15.7002 71.6031 15.6002 71.9031 15.6002Z" />
        <path d="M88.1078 6.4998C87.7078 6.3998 87.3078 6.2998 86.8078 6.2998C85.7078 6.2998 84.9078 6.5998 84.3078 7.0998C83.8078 7.4998 83.5078 7.8998 83.2078 8.4998V6.5998C83.2078 6.4998 83.1078 6.4998 83.1078 6.4998H79.1078C79.0078 6.4998 79.0078 6.5998 79.0078 6.5998V21.2998C79.0078 21.3998 79.1078 21.3998 79.1078 21.3998H83.2078C83.3078 21.3998 83.3078 21.2998 83.3078 21.2998V12.0998C83.7078 11.4998 84.2078 11.0998 84.8078 10.7998C85.5078 10.4998 86.3078 10.2998 87.3078 10.2998H88.0078C88.1078 10.2998 88.1078 10.1998 88.1078 10.1998V6.5998C88.2078 6.5998 88.2078 6.4998 88.1078 6.4998Z" />
        <path d="M98.1062 13.9L103.206 6.8V6.6C103.206 6.5 103.106 6.5 103.106 6.5H98.5062C98.5062 6.5 98.4062 6.5 98.4062 6.6L93.8063 13.1V0.1C93.8063 0 93.7062 0 93.7062 0H89.5062C89.4062 0 89.4062 0.1 89.4062 0.1V21.4C89.4062 21.5 89.5062 21.5 89.5062 21.5H93.6062C93.7062 21.5 93.7062 21.4 93.7062 21.4V14.7L98.7062 21.5L98.8063 21.6H103.706C103.773 21.6 103.806 21.5667 103.806 21.5V21.3L98.1062 13.9Z" />
        <path d="M115.205 8.0002C114.705 7.4002 114.005 7.0002 113.305 6.7002C112.505 6.4002 111.605 6.2002 110.705 6.2002C109.605 6.2002 108.505 6.4002 107.605 6.8002C106.705 7.2002 105.905 7.7002 105.305 8.4002C104.705 9.1002 104.205 9.9002 103.805 10.9002C103.505 11.9002 103.305 12.9002 103.305 14.1002C103.305 15.2002 103.505 16.3002 103.805 17.2002C104.105 18.2002 104.705 19.0002 105.305 19.6002C105.905 20.2002 106.805 20.8002 107.705 21.2002C108.705 21.6002 109.805 21.8002 111.005 21.8002C112.005 21.8002 113.005 21.7002 113.905 21.5002C114.805 21.3002 115.705 21.0002 116.405 20.7002C116.505 20.7002 116.505 20.6002 116.505 20.5002L116.005 17.7002C116.005 17.7002 116.005 17.6002 115.905 17.6002H115.805C115.205 17.9002 114.605 18.1002 113.805 18.2002C113.105 18.3002 112.305 18.4002 111.505 18.4002C110.205 18.4002 109.205 18.1002 108.605 17.5002C108.005 16.9002 107.705 16.1002 107.705 15.2002H116.205C116.271 15.2002 116.305 15.1669 116.305 15.1002C116.405 14.8002 116.505 14.4002 116.505 13.9002C116.605 13.5002 116.605 13.1002 116.605 12.7002C116.605 11.7002 116.505 10.8002 116.205 10.0002C116.105 9.2002 115.705 8.5002 115.205 8.0002ZM110.405 9.3002C111.205 9.3002 111.805 9.6002 112.105 10.1002C112.405 10.6002 112.605 11.3002 112.505 12.1002H107.805C107.905 11.3002 108.105 10.6002 108.605 10.1002C109.005 9.5002 109.605 9.3002 110.405 9.3002Z" />
        <path d="M128.002 6.4998H123.802V2.8998C123.802 2.7998 123.702 2.7998 123.702 2.7998H119.502C119.402 2.7998 119.402 2.8998 119.402 2.8998V6.4998H117.202C117.102 6.4998 117.102 6.5998 117.102 6.5998V9.8998C117.102 9.9998 117.202 9.9998 117.202 9.9998H119.402V16.2998C119.402 17.0998 119.502 17.7998 119.702 18.3998C119.902 19.0998 120.202 19.5998 120.602 20.0998C121.002 20.5998 121.502 20.9998 122.202 21.2998C122.802 21.5998 123.602 21.6998 124.502 21.6998C125.202 21.6998 125.802 21.5998 126.502 21.4998C127.202 21.3998 127.702 21.1998 128.202 20.9998C128.302 20.9998 128.302 20.8998 128.302 20.7998L128.002 17.8998C128.002 17.8998 128.002 17.7998 127.902 17.7998H127.802C127.102 17.9998 126.402 18.0998 125.602 18.0998C124.902 18.0998 124.502 17.8998 124.202 17.5998C124.002 17.1998 123.802 16.6998 123.802 16.0998V9.9998H128.002C128.102 9.9998 128.102 9.8998 128.102 9.8998V6.5998C128.202 6.5998 128.102 6.4998 128.002 6.4998Z" />
        <path d="M146.606 21.2L139.906 11.2L146.406 1.3V1.1L146.306 1H141.706C141.64 1 141.606 1.03333 141.606 1.1L137.506 7.7L133.406 1.1L133.306 1H128.706C128.64 1 128.606 1.03333 128.606 1.1V1.3L135.106 11.2L128.406 21.2V21.4L128.506 21.5H133.106C133.173 21.5 133.206 21.4667 133.206 21.4L137.406 14.8L141.606 21.4L141.706 21.5H146.306C146.373 21.5 146.406 21.4667 146.406 21.4C146.54 21.3333 146.606 21.2667 146.606 21.2Z" />
        <path d="M22.0078 0.399805L21.9078 0.299805C21.8411 0.299805 21.8078 0.333138 21.8078 0.399805L17.3078 7.9998L12.8078 0.399805L12.7078 0.299805C12.6411 0.299805 12.6078 0.333138 12.6078 0.399805L0.507812 21.1998V21.2998L0.607813 21.3998H6.00781C6.07448 21.3998 6.10781 21.3665 6.10781 21.2998L12.6078 9.9998L14.3078 12.9998L9.70781 21.1998V21.2998L9.80781 21.3998H15.1078C15.1745 21.3998 15.2078 21.3665 15.2078 21.2998L17.0078 17.9998L19.0078 21.2998L19.1078 21.3998H24.5078C24.5745 21.3998 24.6078 21.3665 24.6078 21.2998V21.1998L19.9078 12.9998L21.6078 10.0998L28.1078 21.2998L28.2078 21.3998H33.6078C33.6745 21.3998 33.7078 21.3665 33.7078 21.2998V21.1998L22.0078 0.399805Z" />
      </g>
      <defs>
        <clipPath id="clip0_1851_2910">
          <rect
            width="146.1"
            height="21.8"
            fill="white"
            transform="translate(0.507812)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LogoIcon = ({ className = 'h-5 w-auto fill-mx-purple-dark' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 109 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M70.4635 0.922568L70.1377 0.601562C69.9205 0.601562 69.8119 0.708564 69.8119 0.922568L55.1504 25.319L40.4889 0.922568L40.1631 0.601562C39.9459 0.601562 39.8372 0.708564 39.8372 0.922568L0.414062 67.6918V68.0128L0.739874 68.3338H18.3337C18.5509 68.3338 18.6595 68.2268 18.6595 68.0128L39.8372 31.7391L45.376 41.3693L30.3887 67.6918V68.0128L30.7145 68.3338H47.9825C48.1997 68.3338 48.3083 68.2268 48.3083 68.0128L54.173 57.4196L60.6892 68.0128L61.015 68.3338H78.6088C78.826 68.3338 78.9346 68.2268 78.9346 68.0128V67.6918L63.6215 41.3693L69.1603 32.0601L90.338 68.0128L90.6638 68.3338H108.258C108.475 68.3338 108.583 68.2268 108.583 68.0128V67.6918L70.4635 0.922568Z" />
    </svg>
  )
}
