import { useState } from 'react'
import { ImageKit } from '@components'
import Styles from './RadioInput.module.css'
interface iRadioButtonProps {
  id?: string
  label: string
  value: string
  tooltip?: string
  disabled?: boolean
}

export interface iRadioInputProps {
  onChange: Function
  value: string
  name: string
  options: iRadioButtonProps[]
  horizontal?: boolean
  required?: boolean
  tooltip?: string
  label?: string
  grid?: boolean
}

export const RadioInput = ({
  onChange,
  value,
  name,
  options,
  horizontal,
  required,
  tooltip,
  label,
  grid,
}: iRadioInputProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const optionClasses = {
    container: grid
      ? `grid grid-cols-3 gap-y-3.5`
      : `flex ${horizontal ? 'flex-row' : 'flex-col'} flex-wrap`,
    option: grid
      ? `flex items-center ${horizontal ? 'mr-4' : 'mb-4'}`
      : `flex items-center ${horizontal ? 'mr-4' : 'mb-4'}`,
  }

  return (
    <div>
      {label && (
        <div className="relative flex items-start">
          <label
            htmlFor={name}
            className="mb-4 block text-base font-medium text-gray-700">
            {label}
            {required && label && <sup className="text-red-500">*</sup>}
          </label>
          {tooltip && (
            <div
              className="ml-2 cursor-pointer"
              onMouseLeave={() => setShowTooltip(false)}
              onMouseEnter={() => setShowTooltip(true)}>
              <ImageKit
                src="/images/info.svg"
                alt="tooltip"
                width={20}
                height={20}
              />
              <div
                role="tooltip"
                className={`${
                  !showTooltip ? 'tooltip invisible opacity-0' : 'opacity-100'
                } absolute left-0 right-0 z-10 inline-block w-auto rounded bg-gray-900 px-3 py-2 text-center text-xs font-normal leading-normal text-white shadow-sm transition-opacity duration-300 dark:bg-gray-700`}>
                {tooltip}
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={optionClasses.container}>
        {options?.map((radioItem, radioItemIndex) => {
          return (
            <div className={optionClasses.option} key={radioItemIndex}>
              <input
                disabled={radioItem.disabled}
                required={required}
                onChange={e => onChange(radioItem.value)}
                value={radioItem.value}
                id={radioItem.id}
                name={name}
                checked={value === radioItem.value}
                type="radio"
                className={`${Styles.radioInput} peer h-4 w-4 cursor-pointer text-primary`}
              />
              <label
                onClick={() => onChange && onChange(radioItem.value)}
                className="ml-3 block cursor-pointer text-sm font-medium text-[#7D7D7D] peer-checked:text-[#6D45B5]">
                {radioItem.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}
