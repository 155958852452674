import { ImageKit } from '@components'
import { PdfIcon } from '@svgs'
import Link from 'next/link'

export const CompanyDocument = () => (
  <div className="mt-10 px-4 pb-16">
    <p className="mb-4 border-b pb-2 text-lg font-semibold">Document Preview</p>
    <ul className="space-y-2 text-sm">
      <li>
        <Link href="https://hubs.ly/Q01Zwk4S0" target="_blank" rel="noreferrer">
          <PdfIcon />
          Private Placement Memorandum
        </Link>
      </li>
      <li>
        <Link href="https://hubs.li/Q01ZwkF10" target="_blank" rel="noreferrer">
          <PdfIcon />
          Subscription Agreement
        </Link>
      </li>
      <li>
        <Link href="https://hubs.li/Q01Zwl100" target="_blank" rel="noreferrer">
          <PdfIcon />
          Operating Agreement
        </Link>
      </li>
    </ul>
  </div>
)
