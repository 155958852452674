import { AppLayout, ImageKit } from '@components'
export interface OnBoardingLayoutProps {
  children?: any
  imgPath?: string
}
export const OnBoardingLayout = ({
  children,
  imgPath,
}: OnBoardingLayoutProps) => {
  return (
    <AppLayout showNavigation={false} showBanner={false}>
      <div className="container mx-auto">
        <div className="mx-auto my-8 w-full md:my-24 md:w-[90%]">
          <div className="flex flex-col justify-between md:flex-row">
            <div
              className="h-[290px] w-full bg-cover bg-center bg-no-repeat md:h-[389px] md:w-[33%]"
              style={{
                backgroundImage: `url(${
                  imgPath
                    ? imgPath
                    : 'https://mx-documents.s3.amazonaws.com/welcome-marketx.png'
                })`,
              }}
            />

            {children}

            <div className="absolute right-0 top-[307px] hidden md:block">
              <ImageKit
                src="https://mx-documents.s3.amazonaws.com/signup-right.png"
                alt="onboarding"
                height={320}
                width={100}
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
