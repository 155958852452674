import { useRef, useState } from 'react'
import { TextInput } from '@components'

import PuffLoader from 'react-spinners/PuffLoader'
import { getImageUrl } from 'utils/getImageUrl'

export const NameForm = ({ onSubmit }: { onSubmit: Function }) => {
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined,
  )

  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined,
  )

  const onNameSubmit = () => {
    if (!firstName) {
      setFirstNameError('This field is required')
      return
    }

    if (!lastName) {
      setLastNameError('This field is required')
      return
    }

    onSubmit(firstName, lastName)
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={getImageUrl({
            src: '/images/marketx-logo-purple.webp',
          })}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Update Your Name
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <div>
            <div className="mt-2">
              <TextInput
                label="First Name"
                name="firstName"
                value={firstName || ''}
                onChange={(e: any) => {
                  if (e.target.value === '')
                    setFirstNameError('This field is required')
                  else if (e.target.value) {
                    setFirstNameError(undefined)
                  }
                  setFirstName(e.target.value)
                }}
                error={firstNameError !== undefined}
                description={firstNameError}
                required
              />
            </div>

            <div className="mt-2">
              <TextInput
                label="Last Name"
                name="lastName"
                value={lastName || ''}
                onChange={(e: any) => {
                  if (e.target.value === '')
                    setLastNameError('This field is required')
                  else if (e.target.value) {
                    setLastNameError(undefined)
                  }
                  setLastName(e.target.value)
                }}
                error={lastNameError !== undefined}
                description={lastNameError}
                required
              />
            </div>
          </div>

          <button
            type="button"
            className="mt-4 flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onClick={() => {
              onNameSubmit()
            }}>
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
