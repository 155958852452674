import { Button } from '@components'
import Link from 'next/link'

export const InvitationConfirm = () => {
  return (
    <div
      className="relative z-[200]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0 md:min-h-full">
          <div className="relative w-4/12 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
            <div className="flex flex-col px-16 py-8">
              <p className="text-center text-base font-semibold text-themeBlack">
                Your invitations were successfully sent!
              </p>
              <div className="mt-8 flex justify-center">
                <Link legacyBehavior href="/">
                  <a>
                    <Button light>Close</Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
