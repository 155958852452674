import { Timer } from '@components'
import { standardDate } from '@utils'
export const CountDownTimer = ({
  closingAt,
  timeOnly,
  backgroundTransparent,
}: {
  closingAt: Date
  timeOnly?: boolean
  backgroundTransparent?: boolean
}) => {
  return (
    <div className="mb-2 flex w-full flex-col items-center pt-4">
      <p className={`text-xs text-inherit ${timeOnly ? 'hidden' : 'block'}`}>
        Remaining Time Left To Invest
      </p>
      <div className="my-2 w-full">
        <Timer
          expiryTimestamp={closingAt}
          backgroundTransparent={backgroundTransparent}
        />
      </div>
      <p
        className={`mt-2 flex flex-col items-center justify-center text-sm text-inherit ${
          timeOnly ? 'hidden' : 'block'
        }`}>
        Investment Deadline:{' '}
        <span className="text-xs font-bold">
          {closingAt ? standardDate(closingAt) : '-'}
        </span>
      </p>
    </div>
  )
}
