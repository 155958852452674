import { Tooltip, ImageKit } from '@components'
import { IInvestment, IUser } from '@types'
import {
  abbreviateNumber,
  investmentStatusHandler,
  percentageRoundTo,
  formatCurrency,
} from '@utils'

export interface IMyInvestmentDetailsProps {
  investment: IInvestment
  user: IUser | null
}

export const MyInvestmentDetails = ({
  investment,
  user,
}: IMyInvestmentDetailsProps) => {
  const investmentAmountStatusHandler = (investmentStatus: string) => {
    switch (investmentStatus) {
      case 'closing':
        return 'Investment Amount'

      case 'closed':
        return 'Closed Investment Amount'

      case 'finalized':
        return 'Finalized Investment Amount'

      default:
        return 'Committed Investment Amount'
    }
  }

  return (
    <>
      <div className="mb-3.5 flex items-center text-2xl font-bold text-themeBlack">
        <ImageKit
          alt="Company Name"
          src={investment?.deal?.company?.logo_url || ''}
          width={42}
          height={42}
        />
        <p className="ml-3.5 text-2xl font-bold text-themeBlack">
          {investment?.deal?.company?.name} -{' '}
          <span className="text-[#6C7588]">My Investment Details</span>
        </p>
      </div>
      <div className="border border-gray-200 px-10 py-5">
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-center">
            <p className="text-sm leading-6">MarketX Ventures Fund Name</p>
          </div>

          <p className="text-base font-bold text-themeBlack">
            {investment?.deal?.mxFundName ? investment?.deal?.mxFundName : '--'}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-center">
            <p className="text-sm leading-6">Share Price</p>
            <Tooltip
              position="right"
              message="Share price offered by MarketX determined based on publicly available information, investor demand, secondary transaction price history, and other sources.">
              <div className="mb-0 ml-3 flex cursor-pointer">
                <ImageKit
                  src="/images/question.svg"
                  alt="Tooltip image"
                  width={16}
                  height={16}
                />
              </div>
            </Tooltip>
          </div>

          <p className="text-base font-bold text-themeBlack">
            {abbreviateNumber(investment?.sharePrice || 0)}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-between">
            <p className="text-sm leading-6">Last Round Valuation</p>
          </div>

          <p className="text-base font-bold text-themeBlack">
            {abbreviateNumber(investment?.deal?.valuation || 0)}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-between">
            <p className="text-sm leading-6">Implied Valuation</p>
            <Tooltip
              position="right"
              message="The implied valuation is the market capitalization of the Company at the offering price per share. It can be calculated by multiplying the offering price by the Fully Diluted Shares Outstanding. For instance, if a given Company's current share offering is $100 with 100M Fully Diluted Shares Outstanding the Implied Valuation is $10B.">
              <div className="mb-0 ml-3 flex cursor-pointer">
                <ImageKit
                  src="/images/question.svg"
                  alt="Tooltip image"
                  width={16}
                  height={16}
                />
              </div>
            </Tooltip>
          </div>
          <p className="text-base font-bold text-themeBlack">
            {abbreviateNumber(investment?.deal?.impliedValuation || 0)}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-center">
            <p className="text-sm leading-6">Management Fee</p>
            <Tooltip
              position="right"
              message="Management fees are charged by the Fund Manager to the Fund to cover expenses. The  annual Management Fee may be charged upfront at closing, or annually, or a combination thereof.  For the annual Management Fee, future Capital Calls may be made from Investors to pay this fee.">
              <div className="mb-0 ml-3 flex cursor-pointer">
                <ImageKit
                  src="/images/question.svg"
                  alt="Tooltip image"
                  width={16}
                  height={16}
                />
              </div>
            </Tooltip>
          </div>

          <p className="text-base font-bold text-themeBlack">
            {percentageRoundTo(investment?.managementFee || 0)}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-center">
            <p className="text-sm leading-6">Carry Fee</p>
            <Tooltip
              position="right"
              message="The Carry fee (%) is a share of the profits that the Fund Manager is entitled to. Carry fees are only charged on future investment profit or gain upon a liquidation event, and not on the initial investment principle.">
              <div className="mb-0 ml-3 flex cursor-pointer">
                <ImageKit
                  src="/images/question.svg"
                  alt="Tooltip image"
                  width={16}
                  height={16}
                />
              </div>
            </Tooltip>
          </div>

          <p className="text-base font-bold text-themeBlack">
            {percentageRoundTo(investment?.carryFee || 0)}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-between">
            <p className="text-sm leading-6">Investment Status</p>
          </div>
          <p className="text-base font-bold text-themeBlack">
            {user && investmentStatusHandler(investment, user)?.text}
          </p>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex items-center justify-between">
            <p className="text-sm leading-6">
              {investmentAmountStatusHandler(investment.status)}
            </p>
          </div>
          <p className="text-base font-bold text-themeBlack">
            {formatCurrency(investment.investmentAmount)}
          </p>
        </div>
      </div>
    </>
  )
}
