import { getImageUrl } from 'utils/getImageUrl'

export const TeamHero = () => {
  return (
    <div
      className="flex h-full w-full items-center justify-center bg-cover bg-right bg-no-repeat
         md:-mt-4 md:bg-center"
      style={{
        backgroundImage: `url(${getImageUrl({
          src: '/images/leadership-bg.png',
        })})`,
      }}>
      <div className="mx-auto w-full bg-black px-8 py-20 opacity-60 md:w-10/12 md:bg-transparent md:py-28 md:opacity-100 lg:px-0">
        <div className="relative pb-12">
          <div className="w-full px-5 md:w-[90%] lg:w-[60%] lg:px-0 2xl:w-[61%]">
            <div className="relative w-full ">
              <h2 className="inverted-commas-start inverted-commas-end  mb-6 text-[28px] font-bold text-white 2xl:text-2xl 2xl:leading-[40px]">
                With a diverse team of experienced professionals spanning 5
                continents, MarketX Ventures remains committed to supporting a
                global network of world-changing companies.
              </h2>
            </div>
          </div>
        </div>
        <div className="flex items-center px-5 pb-9 lg:px-0">
          <p className="pr-5 text-xl font-bold text-white 2xl:text-3xl">
            Cathryn Chen
          </p>
          <p className="border-l pl-5 text-lg font-normal text-white 2xl:text-2xl">
            Founder & CEO
          </p>
        </div>
      </div>
    </div>
  )
}
