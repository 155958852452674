import classNames from 'classnames'

export const BackArrow = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 30L15 22.5L22.5 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 22.5L15 22.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export const NextArrow = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 15L30 22.5L22.5 30"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 22.5H30"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 1L1 12.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L12.25 12.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PdfIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={classNames('mr-1 inline fill-mx-purple-dark', className)}
      fill="none"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M13.8938 4.16381H12.578C11.8391 4.16381 11.2381 3.56269 11.2381 2.82398V1.5083H11.9383V2.82415C11.9383 3.17688 12.2254 3.46378 12.578 3.46378H13.8938V4.16381Z" />
        <path d="M11.9383 1.50808H11.2381C11.2381 1.13015 10.9307 0.822759 10.5528 0.822759V0.122559C11.3167 0.122559 11.9383 0.744161 11.9383 1.50808Z" />
        <path d="M15.2791 4.84907H14.5789C14.5789 4.47114 14.2715 4.16358 13.8936 4.16358V3.46338C14.6575 3.46338 15.2791 4.08498 15.2791 4.84907Z" />
        <path d="M8.32775 4.32563H2.01265C1.29967 4.32563 0.719727 3.74569 0.719727 3.03271H1.41993C1.41993 3.35953 1.68583 3.62543 2.01265 3.62543H8.32775V4.32563Z" />
        <path d="M1.41993 3.08882H0.719727C0.719727 2.37584 1.29967 1.7959 2.01265 1.7959H3.23607V2.4961H2.01265C1.68583 2.4961 1.41993 2.762 1.41993 3.08882Z" />
        <path d="M9.75161 9.17907H2.01265C1.29967 9.17907 0.719727 8.59913 0.719727 7.88615V3.03271H1.41993V7.88615C1.41993 8.21297 1.68583 8.47887 2.01265 8.47887H9.75143C10.0782 8.47887 10.3443 8.21279 10.3443 7.88615V4.91835C10.3443 4.59153 10.0782 4.32563 9.75143 4.32563H4.15928V3.62543H9.75161C10.4646 3.62543 11.0447 4.20537 11.0447 4.91835V7.88615C11.0447 8.59913 10.4646 9.17907 9.75161 9.17907Z" />
        <path d="M14.0398 15.8771H4.14389C3.46049 15.8771 2.90436 15.3209 2.90436 14.6375V8.82885H3.60456V14.6375C3.60456 14.9349 3.84648 15.1769 4.14389 15.1769H14.0398C14.3372 15.1769 14.5791 14.9349 14.5791 14.6375V3.73472C14.5791 3.35223 14.43 2.9925 14.1597 2.72188L12.68 1.242C12.4094 0.971726 12.0497 0.822759 11.667 0.822759H4.14389C3.84648 0.822759 3.60456 1.06468 3.60456 1.36209V3.97541H2.90436V1.36209C2.90436 0.678517 3.46032 0.122559 4.14389 0.122559H11.6672C12.237 0.122559 12.7723 0.344347 13.1751 0.747137L14.6548 2.22701C15.0576 2.6298 15.2793 3.16528 15.2793 3.73489V14.6377C15.2793 15.3209 14.7232 15.8771 14.0398 15.8771Z" />
        <path d="M3.92228 4.70557H2.96143C2.76817 4.70557 2.61133 4.86241 2.61133 5.05567V6.64862V7.73936C2.61133 7.93261 2.76817 8.08946 2.96143 8.08946C3.15468 8.08946 3.31153 7.93261 3.31153 7.73936V6.99872H3.92228C4.36831 6.99872 4.73136 6.63584 4.73136 6.18982V5.51447C4.73118 5.06845 4.36831 4.70557 3.92228 4.70557ZM4.03098 6.18982C4.03098 6.24968 3.98215 6.29852 3.9221 6.29852H3.31135V5.40577H3.9221C3.98215 5.40577 4.03098 5.45461 4.03098 5.51447V6.18982Z" />
        <path d="M6.34601 4.70557H5.43225C5.239 4.70557 5.08215 4.86241 5.08215 5.05567V7.73953C5.08215 7.93279 5.239 8.08963 5.43225 8.08963H6.34601C6.81813 8.08963 7.20218 7.70557 7.20218 7.23346V5.56174C7.20218 5.08963 6.81813 4.70557 6.34601 4.70557ZM6.50198 7.23346C6.50198 7.31941 6.43196 7.38943 6.34601 7.38943H5.78235V5.40577H6.34601C6.43196 5.40577 6.50198 5.47561 6.50198 5.56174V7.23346Z" />
        <path d="M9.37211 4.70557H7.95246C7.7592 4.70557 7.60236 4.86241 7.60236 5.05567V7.73953C7.60236 7.93279 7.7592 8.08963 7.95246 8.08963C8.14571 8.08963 8.30256 7.93279 8.30256 7.73953V6.7477H8.88232C9.07558 6.7477 9.23242 6.59086 9.23242 6.3976C9.23242 6.20434 9.07558 6.0475 8.88232 6.0475H8.30238V5.40577H9.37194C9.56519 5.40577 9.72204 5.24892 9.72204 5.05567C9.72204 4.86241 9.56537 4.70557 9.37211 4.70557Z" />
        <path d="M8.26058 12.1411C8.12386 12.2778 7.90207 12.2778 7.76536 12.1411L7.02717 11.412V13.8532C7.02717 14.0465 6.87033 14.2033 6.67707 14.2033C6.48382 14.2033 6.32697 14.0465 6.32697 13.8532V11.4029L5.58879 12.1411C5.45207 12.2778 5.23029 12.2778 5.09375 12.1411C4.95703 12.0044 4.95703 11.7828 5.09375 11.646L6.17538 10.5644C6.29704 10.4427 6.45248 10.3778 6.61178 10.3631C6.63296 10.3591 6.65467 10.3564 6.67707 10.3564C6.70053 10.3564 6.72329 10.3589 6.74534 10.3633C6.90359 10.3785 7.05781 10.4434 7.17877 10.5642L8.2604 11.6459C8.39711 11.7828 8.39711 12.0044 8.26058 12.1411Z" />
      </g>
    </svg>
  )
}
