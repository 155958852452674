export interface AccountNumberInputProps {
  // required
  label: string
  onChange: Function
  name: string

  // optional
  defaultValue?: string
  value?: string
  placeholder?: string
  description?: string
  required?: boolean
  error?: boolean
  pattern?: string
  disabled?: boolean
  maxLength?: number
}

export const AccountNumberInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  placeholder,
  description,
  required,
  error,
  pattern,
  disabled,
  maxLength,
}: AccountNumberInputProps) => {
  const classes = {
    default:
      'shadow-sm focus:ring-primary focus:border-primary text-themeBlack block w-full sm:text-sm border-[#D0D0D0] rounded',
    disabled:
      'bg-gray-100 shadow-sm text-themeBlack block w-full sm:text-sm border-[#D0D0D0] rounded',
    error:
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded',
  }

  return (
    <div>
      <div className="relative flex items-center">
        <label
          htmlFor={name}
          className="block text-base font-medium text-gray-700">
          {label}
          {required && label && <sup className="text-red-500">*</sup>}
        </label>
      </div>
      <div className="mt-1">
        <input
          disabled={disabled || false}
          autoComplete="none"
          id={name}
          name={name}
          pattern={pattern}
          defaultValue={defaultValue}
          type="text"
          className={
            error
              ? classes.error
              : disabled
              ? classes.disabled
              : classes.default
          }
          onChange={e => onChange(e.target.value.replace(/\D/g, ''))}
          {...(value !== undefined ? { value } : {})}
          required={required}
          placeholder={placeholder}
          aria-describedby={name}
          maxLength={maxLength}
          title="Account number should be greater or equal to 6 digits, and only contain numbers."
        />
      </div>

      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
