import React from 'react'
import { ImageKit } from '@components/ImageKit'
import Link from 'next/link'
import { IBlogPost } from '@types'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'

export const BlogCard = ({ blogPosts }: { blogPosts: IBlogPost[] }) => {
  return (
    <>
      <div className="mb-20 grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-8 2xl:grid-cols-3">
        {blogPosts?.map((post: any, idx: number) => (
          <Link legacyBehavior href={`/news/${post.slug}`} key={idx}>
            <a className="block h-full w-full max-w-full">
              <div className="group flex h-full w-full max-w-full flex-col overflow-hidden rounded border-gray-100">
                <div className="relative aspect-19/10 w-full">
                  {post.bgUrl ? (
                    <ImageKit
                      alt={post.name}
                      src={post.bgUrl?.url}
                      fill
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      className="scale-100 rounded-t duration-1000 ease-out group-hover:scale-[1.03] group-hover:duration-1000"
                      placeholder="blur"
                      blurDataURL={post.bgUrl?.blurUpThumb}
                    />
                  ) : (
                    <div className="h-28 w-full rounded-t bg-gradient-to-br from-primary to-primary-dark" />
                  )}
                </div>

                <div className="mx-6 my-8 flex-1">
                  <div className="py-2 font-light uppercase text-primary">
                    {post.category}
                  </div>
                  <div className="text-xl font-bold">{post.heading}</div>
                  <div className="font-weight-300 my-2">{post.subHeading}</div>
                  <div className="font-weight-300 my-2 font-bold text-primary hover:text-primary-light">
                    read more
                    <ArrowLongRightIcon className="ml-2 inline h-5 w-5" />
                  </div>
                </div>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </>
  )
}
