import { Button, ImageKit, Tooltip } from '@components'
import { IDeal } from '@types'
import { formatCurrency } from '@utils'
import { ReactNode } from 'react'
import { InvestmentTerms } from '../InvestmentTerms'
import classNames from 'classnames'

const DealDetails = ({
  deal,
  extraRow,
  bordered = true,
  totalInvestmentAmountDue,
  yearsOfManagementFeeDueUpfront,
  investmentCommitmentReview,
  investmentCommitmentReviewEditHandler,
}: {
  deal: IDeal
  extraRow?: { label: string; value: ReactNode }
  bordered?: boolean
  setActiveStep?: Function
  activeStep?: number
  totalInvestmentAmountDue?: number
  yearsOfManagementFeeDueUpfront?: number
  investmentCommitmentReview?: boolean
  investmentCommitmentReviewEditHandler?: Function
}) => {
  return (
    <div
      className={classNames(
        'my-8 w-full max-w-2xl overflow-hidden rounded-xl md:overflow-visible',
        bordered ? 'border bg-white' : 'bg-slate-100 py-2',
      )}>
      {investmentCommitmentReview && (
        <div className="mt-2 flex w-[97%] justify-end">
          <Button
            light
            onClick={() => {
              investmentCommitmentReviewEditHandler?.()
            }}>
            Edit
          </Button>
        </div>
      )}
      <table className="mx-auto min-w-[86%]">
        <tbody
          className={classNames(
            'whitespace-wrap text-xs font-medium text-gray-900 sm:text-sm',
            bordered ? 'divide-y divide-gray-200' : '',
          )}>
          {extraRow && (
            <tr className={classNames(bordered && 'divide-x divide-gray-200')}>
              <div className="flex items-center">
                <td className="py-3 pl-6 pr-2 font-bold">{extraRow.label}</td>
                <Tooltip
                  position="right"
                  message="This is the minimum investment amount per investor for a specific deal, set by MarketX. If you have questions about this, please contact a MarketX Ventures specialist.">
                  <div className="mb-0 flex cursor-pointer">
                    <ImageKit
                      src="/images/question.svg"
                      alt="Tooltip image"
                      width={16}
                      height={16}
                    />
                  </div>
                </Tooltip>
              </div>
              <td className="px-6 py-3 ">{extraRow.value}</td>
            </tr>
          )}

          <InvestmentTerms
            text="Share Price"
            value={deal.sharePrice}
            tooltip="Share price offered by MarketX Ventures determined based on publicly available information, investor demand, secondary transaction price history, and other sources."
          />
          <InvestmentTerms
            text="Share Class"
            notCurrency
            value={deal.shareClass}
            tooltip="Share Class refers to the type of asset owned by the fund you are purchasing your interest in. Common stock is the standard unit of ownership created when a company is first formed. Some founders may choose to reserve a certain percentage of common stock to issue at a later date in conjunction with the exercise of employee stock options or for future equity offerings. Any dividend payments authorized by the company’s board of directors are made to common stockholders after debt investors are paid interest owed and preferred stockholders have received their full payout. In the event of bankruptcy or liquidation, common stockholders have the least claim over company assets."
          />
          <InvestmentTerms
            text="Last Round Valuation"
            value={deal.valuation}
            tooltip="The implied valuation is the market capitalization of the Company at the offering price per share. It can be calculated by multiplying the offering price by the Fully Diluted Shares Outstanding. For instance, if a given Company's current share offering is $100 with 100M Fully Diluted Shares Outstanding the Implied Valuation is $10B."
          />
          <InvestmentTerms
            text="Implied Valuation"
            value={deal.impliedValuation}
            tooltip="The implied valuation is the market capitalization of the Company at the offering price per share. It can be calculated by multiplying the offering price by the Fully Diluted Shares Outstanding. For instance, if a given Company's current share offering is $100 with 100M Fully Diluted Shares Outstanding the Implied Valuation is $10B."
          />
          <InvestmentTerms
            text="Min. Investment"
            value={deal.minimumInvestment}
            tooltip="This is the minimum investment amount per investor for a specific deal, set by MarketX Ventures. If you have questions about this, please contact a MarketX Ventures specialist."
          />
          <InvestmentTerms
            text="Allocation"
            value={deal.allocation}
            tooltip="Allocation represents the investment amount MarketX Ventures is offering to Investors in this offering and represents the maximum offering amount that all investors on the MarketX Ventures platform can invest in."
          />
          {yearsOfManagementFeeDueUpfront ? (
            <InvestmentTerms
              simple
              notCurrency
              text="Number of Years of Management Fees Due Upfront"
              value={yearsOfManagementFeeDueUpfront.toString()}
            />
          ) : (
            <></>
          )}
          <InvestmentTerms
            text="Fees"
            percentage
            value={deal.managementFee.toString()}
            carryFeeValue={deal.carryFee.toString()}
            tooltip="Management fees are charged by the Fund Manager to the Fund. The first % listed is the annual Management Fee which may be charged upfront at closing, or annually, or a combination thereof.  For the annual Management Fee, future Capital Calls may be made from Investors to pay this fee.  The second % listed is the Carry fee (%) which is a share of the profits that the Fund Manager is entitled to. Carry fees are only charged on future investment profit or gain upon a liquidation event, and not on the initial investment principle."
          />

          {totalInvestmentAmountDue ? (
            <InvestmentTerms
              simple
              text="Total Investment Amount Due"
              value={formatCurrency(totalInvestmentAmountDue).toString()}
              valueBold
            />
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default DealDetails
