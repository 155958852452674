import { Button, SelectInput, ImageKit } from '@components'
interface FormValues {
  name: string
  documentType: string
  uploadedFile: any
}

interface IDocumentsProps {
  text: string
  value: string
}

export const DocumentUploadPopup = ({
  submitDocumentForm,
  setDocumentValue,
  documentFormValues,
  submitting,
  closeDocumentUploadPopup,
  InvestmentDocuments,
  TaxDocuments,
  InvestorUpdatesDocuments,
}: {
  submitDocumentForm: React.FormEventHandler<HTMLFormElement>
  setDocumentValue: Function
  documentFormValues: FormValues
  submitting: boolean
  closeDocumentUploadPopup: Function
  InvestmentDocuments: IDocumentsProps[]
  TaxDocuments: IDocumentsProps[]
  InvestorUpdatesDocuments?: IDocumentsProps[]
}) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0 md:min-h-full">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl lg:mt-24">
            <div className="flex flex-col items-center justify-center bg-white px-16 py-8 text-center">
              <p className="pb-4 text-base font-semibold text-themeBlack">
                Upload New Document
              </p>
              <form className="w-full" onSubmit={submitDocumentForm}>
                <div className="mx-auto mb-4 w-3/5">
                  <SelectInput
                    required
                    options={
                      InvestorUpdatesDocuments
                        ? [
                            {
                              text: 'Select',
                              value: '',
                            },
                            {
                              text: 'Investment Documents',
                              value: 'investmentDocument',
                            },
                            {
                              text: 'Tax Documents',
                              value: 'taxDocument',
                            },
                            {
                              text: 'Investor Updates',
                              value: 'investorUpdatesDocument',
                            },
                          ]
                        : [
                            {
                              text: 'Select',
                              value: '',
                            },
                            {
                              text: 'Investment Documents',
                              value: 'investmentDocument',
                            },
                            {
                              text: 'Tax Documents',
                              value: 'taxDocument',
                            },
                          ]
                    }
                    defaultValue={documentFormValues.documentType}
                    name="documentType"
                    label="Document Type"
                    onChange={(e: any) => {
                      setDocumentValue(e.target.value, 'documentType')
                    }}
                  />
                </div>
                <div className="mx-auto mb-4 w-3/5">
                  <SelectInput
                    required
                    disabled={!documentFormValues.documentType}
                    options={
                      !documentFormValues.documentType
                        ? []
                        : documentFormValues.documentType ===
                          'investmentDocument'
                        ? InvestmentDocuments
                        : InvestorUpdatesDocuments &&
                          documentFormValues.documentType ===
                            'investorUpdatesDocument'
                        ? InvestorUpdatesDocuments
                        : TaxDocuments
                    }
                    defaultValue={documentFormValues.name}
                    name="name"
                    label="Document Name"
                    onChange={(e: any) => {
                      setDocumentValue(e.target.value, 'name')
                    }}
                  />
                </div>
                <div className="mx-auto mb-4 w-3/5">
                  <div>
                    <p className="block text-left text-base font-medium text-themeBlack">
                      Browse Document File To Upload
                    </p>
                    <label
                      htmlFor="files"
                      className="flex w-full cursor-pointer justify-between rounded border border-gray-300 p-2 text-left text-sm">
                      {documentFormValues.uploadedFile
                        ? documentFormValues.uploadedFile.name
                        : 'Select Document'}
                      <ImageKit
                        src="/images/file_upload_icon.svg"
                        alt="file upload icon"
                        width={18}
                        height={18}
                      />
                    </label>
                    <input
                      accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      required
                      type="file"
                      id="files"
                      name="uploadedFile"
                      className="absolute bottom-[25%] left-[30%] w-1/4 cursor-pointer opacity-0"
                      onChange={(e: any) =>
                        setDocumentValue(e.target.files[0], 'uploadedFile')
                      }
                    />
                  </div>
                </div>
                <div className="mx-auto mt-8 flex w-2/4 justify-between">
                  <Button
                    disabled={submitting}
                    dark
                    onClick={() => {
                      closeDocumentUploadPopup()
                    }}>
                    Close
                  </Button>
                  <Button submit disabled={submitting} loading={submitting}>
                    Upload
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
