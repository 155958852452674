import classNames from 'classnames'
import React from 'react'

export const PartialContainer: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => (
  <div
    className={classNames(
      'mx-auto max-w-[1440px] px-4 md:px-8 xl:px-20',
      className,
    )}>
    {children}
  </div>
)
