import classNames from 'classnames'
import React from 'react'

export const Social: React.FC<{
  size?: string
  color?: 'white' | 'purple-dark'
  type:
    | 'facebook'
    | 'twitter'
    | 'instagram'
    | 'linkedin'
    | 'whatsapp'
    | 'telegram'
}> = ({ size = 'h-6 w-6', color = 'dark-purple', type }) => {
  const fill = classNames({
    'fill-white': color === 'white',
    'fill-mx-purple-dark': color === 'purple-dark',
  })

  const antiFill = classNames({
    'fill-mx-purple-dark': color === 'white',
    'fill-white': color === 'purple-dark',
  })

  switch (type) {
    case 'facebook':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            className={fill}
            d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
          />
        </svg>
      )

    case 'twitter':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.55016 21.7507C16.6045 21.7507 21.5583 14.2474 21.5583 7.74259C21.5583 7.53166 21.5536 7.31603 21.5442 7.10509C22.5079 6.40819 23.3395 5.54499 24 4.55603C23.1025 4.95533 22.1496 5.21611 21.1739 5.32947C22.2013 4.71364 22.9705 3.7462 23.3391 2.6065C22.3726 3.17929 21.3156 3.58334 20.2134 3.80134C19.4708 3.01229 18.489 2.48985 17.4197 2.31478C16.3504 2.13972 15.2532 2.32178 14.2977 2.83283C13.3423 3.34387 12.5818 4.15544 12.1338 5.14204C11.6859 6.12865 11.5754 7.23535 11.8195 8.29103C9.86249 8.19282 7.94794 7.68444 6.19998 6.79883C4.45203 5.91323 2.90969 4.67017 1.67297 3.15025C1.0444 4.23398 0.852057 5.51638 1.13503 6.73682C1.418 7.95727 2.15506 9.02418 3.19641 9.72072C2.41463 9.6959 1.64998 9.48541 0.965625 9.10666V9.16759C0.964925 10.3049 1.3581 11.4073 2.07831 12.2875C2.79852 13.1677 3.80132 13.7713 4.91625 13.9957C4.19206 14.1939 3.43198 14.2227 2.69484 14.0801C3.00945 15.0582 3.62157 15.9136 4.44577 16.5271C5.26997 17.1405 6.26512 17.4813 7.29234 17.502C5.54842 18.8718 3.39417 19.6149 1.17656 19.6113C0.783287 19.6107 0.390399 19.5866 0 19.5392C2.25286 20.9845 4.87353 21.7521 7.55016 21.7507Z"
            className={fill}
          />
        </svg>
      )

    case 'instagram':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
            className={fill}
          />
        </svg>
      )

    case 'linkedin':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
            className={fill}
          />
        </svg>
      )

    case 'whatsapp':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.510625 11.8563C0.510062 13.8728 1.04106 15.8417 2.05075 17.5771L0.414062 23.5066L6.52956 21.9156C8.22103 22.8292 10.1162 23.308 12.0421 23.3081H12.0471C18.4048 23.3081 23.5801 18.1748 23.5828 11.8653C23.584 8.80792 22.3851 5.93295 20.2069 3.76997C18.0291 1.60718 15.1327 0.415458 12.0467 0.414062C5.68825 0.414062 0.513344 5.54709 0.510719 11.8563"
            className={fill}
          />
          <path
            d="M0.100313 11.8527C0.0996563 13.9417 0.649687 15.981 1.69537 17.7786L0 23.9207L6.33478 22.2726C8.08022 23.2168 10.0454 23.7147 12.0451 23.7154H12.0502C18.636 23.7154 23.9972 18.3975 24 11.8621C24.0011 8.69488 22.7591 5.71656 20.5031 3.47609C18.2468 1.23591 15.2468 0.00130233 12.0502 0C5.46337 0 0.103031 5.31721 0.100406 11.8527H0.100313ZM3.87281 17.469L3.63628 17.0965C2.64197 15.5277 2.11716 13.7149 2.11791 11.8534C2.12006 6.4213 6.57544 2.00186 12.054 2.00186C14.7071 2.00298 17.2005 3.02921 19.0759 4.89116C20.9512 6.7533 21.9831 9.22865 21.9824 11.8614C21.98 17.2935 17.5245 21.7135 12.0502 21.7135H12.0463C10.2638 21.7126 8.51569 21.2376 6.99113 20.34L6.62831 20.1265L2.86912 21.1045L3.87281 17.4689V17.469Z"
            className={fill}
          />
          <path
            d="M9.06187 6.89771C8.83819 6.4044 8.60278 6.39445 8.39006 6.3858C8.21587 6.37836 8.01675 6.37892 7.81781 6.37892C7.61869 6.37892 7.29516 6.45324 7.02169 6.74952C6.74794 7.04608 5.97656 7.76273 5.97656 9.22031C5.97656 10.678 7.04653 12.0866 7.19569 12.2845C7.34503 12.482 9.26128 15.5689 12.2962 16.7564C14.8184 17.7433 15.3317 17.547 15.8791 17.4975C16.4266 17.4482 17.6457 16.7811 17.8944 16.0892C18.1433 15.3975 18.1433 14.8046 18.0687 14.6807C17.9941 14.5572 17.795 14.4831 17.4964 14.335C17.1977 14.1868 15.7297 13.4701 15.4561 13.3712C15.1823 13.2724 14.9833 13.2231 14.7842 13.5198C14.5851 13.8159 14.0133 14.4831 13.839 14.6807C13.6649 14.8787 13.4906 14.9034 13.1921 14.7552C12.8933 14.6065 11.9317 14.2941 10.7909 13.2849C9.90328 12.4996 9.30403 11.5298 9.12984 11.2331C8.95566 10.937 9.11119 10.7764 9.26091 10.6288C9.39506 10.496 9.55959 10.2828 9.70903 10.1099C9.85791 9.93687 9.90759 9.81343 10.0072 9.61585C10.1068 9.41808 10.0569 9.24506 9.98241 9.09687C9.90759 8.94868 9.32737 7.48357 9.06187 6.8978"
            className={antiFill}
          />
        </svg>
      )

    case 'telegram':
      return (
        <svg
          className={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.64963 10.5628C8.0933 7.75609 12.3891 5.90552 14.537 5.01149C20.6767 2.45854 21.951 2.01515 22.7832 2.00013C22.9662 1.9972 23.3737 2.04243 23.6396 2.25739C23.8606 2.43869 23.9228 2.68386 23.9538 2.85601C23.9815 3.02797 24.0194 3.41991 23.9884 3.72587C23.6569 7.2205 22.2169 15.7009 21.4848 19.6151C21.1775 21.2712 20.5662 21.8265 19.9757 21.8807C18.6912 21.9988 17.7174 21.0326 16.4742 20.218C14.5301 18.9427 13.4319 18.1492 11.543 16.905C9.36062 15.4671 10.7764 14.6766 12.0196 13.3851C12.3442 13.0471 18.0005 7.90354 18.1076 7.43702C18.1214 7.37866 18.1352 7.16111 18.004 7.04646C17.8762 6.93147 17.6863 6.97083 17.5482 7.00191C17.3513 7.04611 14.2469 9.10008 8.22452 13.1635C7.34396 13.7691 6.54627 14.0644 5.828 14.0489C5.04067 14.0319 3.52127 13.6027 2.39207 13.236C1.01079 12.786 -0.0907762 12.5481 0.00591329 11.7839C0.054258 11.3861 0.603316 10.979 1.64963 10.5628Z"
            className={fill}
          />
        </svg>
      )

    default:
      return null
  }
}
