import { IInvestment, IUser } from '@types'
import { Table } from 'components/Table'
import {
  formatCurrency,
  investmentStatusHandler,
  standardDate,
  calculateTotalInvestmentsHandler,
} from '@utils'
import Link from 'next/link'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

export interface IPortfolioInvestmentDetailProps {
  investments: IInvestment[]
  user: IUser | null
  userInvestmentDetail?: boolean
}

export const PortfolioInvestmentDetail = ({
  investments,
  user,
  userInvestmentDetail = false,
}: IPortfolioInvestmentDetailProps) => {
  const investmentsLive = investments.filter(
    (inv: any) =>
      inv.status === 'committed' ||
      inv.status === 'express_interest' ||
      inv.status === 'closing',
  )

  const desSortByDate = (array: IInvestment[], keyName: keyof IInvestment) => {
    return array.sort(
      (a: IInvestment, b: IInvestment) =>
        new Date(b[keyName] as keyof IInvestment).valueOf() -
        new Date(a[keyName] as keyof IInvestment).valueOf(),
    )
  }

  const investmentsClosed = investments.filter(
    inv => inv.status === 'closed' || inv.status === 'finalized',
  )

  return (
    <>
      {investmentsLive.length ? (
        <>
          {/* Investment in progress */}
          <h2 className="mt-6 text-xl font-semibold">Investment In-Progress</h2>

          <Table
            className="my-8 w-full overflow-x-auto md:rounded-xl md:border"
            data={desSortByDate(investmentsLive, 'createdAt')}
            columns={[
              {
                name: 'Name',
                accessor: 'id',
                tooltip: 'Name of the target company',
                className: 'rounded-tl-xl',
                width: 276,
                render(value, row) {
                  return `${row?.deal?.company?.name || 'DEAL'}-${row.id}`
                },
              },
              {
                name: 'Mx Fund name',
                accessor: 'mxFundName',
                width: 276,
                render(value, row) {
                  return `${row?.deal?.mxFundName}`
                },
              },
              {
                name: 'Amount',
                accessor: 'investmentAmount',
                width: 276,
                tooltip:
                  'The Amount here refers to either the desired investment size for expressed interest company/deal, or the committed to invest amount.',
                render(value) {
                  return `${formatCurrency(value)}`
                },
              },
              {
                name: 'Status',
                accessor: 'status',
                width: 448,
                tooltip:
                  'Shows the status of the investment deal from Expressed Interest, Committed to Invest, Closing -  Pending KYC, Closing - Pending Documents Signing, Closing - Pending Payment Receipt, Closing - Pending MX Counter-signatures etc.',
                render(value, row) {
                  return (
                    <span className={'capitalize text-green-500'}>
                      {user && investmentStatusHandler(row, user)?.text}
                    </span>
                  )
                },
              },
              {
                name: 'Commitment Date',
                accessor: 'createdAt',
                width: 448,
                render(value) {
                  return `${standardDate(value)}`
                },
              },
              {
                name: 'PPS',
                accessor: 'investmentAmount',
                align: 'center',
                width: 216,
                tooltip:
                  'Price Per Share refers to the offered share price of a deal.',
                render(value, row) {
                  return `${formatCurrency(row?.sharePrice)}`
                },
              },
              {
                name: '',
                accessor: '',
                align: 'center',
                className: 'rounded-tr-xl',
                render(value, row) {
                  return (
                    <Link
                      legacyBehavior
                      href={
                        userInvestmentDetail
                          ? `/admin/users/${user?.id}/investment/${row.id}`
                          : `/investment/${row.alphaId}`
                      }>
                      <a>
                        <p className="text-sm text-link">
                          <u className="mr-2"> Details</u>
                          &rsaquo;&rsaquo;
                        </p>
                      </a>
                    </Link>
                  )
                },
              },
            ]}
          />
          <p className="text-right font-semibold">
            Total Investment In-Progress: &nbsp;
            {`${
              investmentsLive?.length &&
              calculateTotalInvestmentsHandler(investmentsLive)
            }`}
          </p>
        </>
      ) : (
        ''
      )}

      {investmentsClosed.length ? (
        <>
          <h2 className="mt-6 text-xl font-semibold">
            My Investment Portfolio
          </h2>
          <Table
            className="my-8 w-full overflow-x-auto md:rounded-xl md:border"
            data={desSortByDate(investmentsClosed, 'finalizedAt')}
            columns={[
              {
                name: 'Name',
                accessor: 'id',
                tooltip: 'Name of the target company',
                className: 'rounded-tl-xl',
                width: 276,
                render(value, row) {
                  return `${row?.deal?.company?.name || 'DEAL'}-${row.id}`
                },
              },
              {
                name: 'Mx Fund name',
                accessor: 'mxFundName',
                width: 276,
                render(value, row) {
                  return `${row?.deal?.mxFundName}`
                },
              },
              {
                name: 'Invested',
                accessor: 'investmentAmount',
                tooltip: 'Amount invested less fees',
                width: 276,
                render(value) {
                  return `${formatCurrency(value)}`
                },
              },
              {
                name: 'Status',
                accessor: 'status',
                width: 448,
                tooltip:
                  'Shows the status of the investment deal from Expressed Interest, Committed to Invest, Closing -  Pending KYC, Closing - Pending Documents Signing, Closing - Pending Payment Receipt, Closing - Pending MX Counter-signatures etc.',

                render(value, row) {
                  return (
                    <Link
                      legacyBehavior
                      href={`/companies/${row?.deal?.company?.slug}/deals/${
                        row?.deal?.id
                      }/subscribe/${row.alphaId}/${
                        value === 'awaiting_payment' ? 'wire' : 'sign'
                      }`}>
                      <a
                        className={classNames(
                          'flex items-baseline',
                          !row?.deal && 'pointer-events-none',
                        )}>
                        <span
                          className={classNames('capitalize text-themeBlack')}>
                          {value.split('_').join(' ')}
                        </span>
                        {row?.Deal && (
                          <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-themeBlack" />
                        )}
                      </a>
                    </Link>
                  )
                },
              },
              {
                name: 'Date',
                accessor: 'finalizedAt',
                width: 276,
                render(value) {
                  return `${value ? standardDate(value) : '--'}`
                },
              },
              {
                name: 'PPS',
                accessor: 'investmentAmount',
                align: 'center',
                width: 216,
                tooltip:
                  'Refers to the final price per share according to the signed investment documents',
                render(value, row) {
                  return `${formatCurrency(row?.sharePrice)}`
                },
              },
              {
                name: '',
                accessor: '',
                align: 'center',
                className: 'rounded-tr-xl',
                render(value, row) {
                  return (
                    <Link
                      legacyBehavior
                      href={
                        userInvestmentDetail
                          ? `/admin/users/${user?.id}/investment/${row.id}`
                          : `/investment/${row.alphaId}`
                      }>
                      <a>
                        <p className="text-sm text-link">
                          <u className="mr-2"> Details</u>
                          &rsaquo;&rsaquo;
                        </p>
                      </a>
                    </Link>
                  )
                },
              },
            ]}
          />
          <p className="text-right font-semibold">
            Total invested amount: &nbsp;
            {`${
              investmentsClosed?.length &&
              calculateTotalInvestmentsHandler(investmentsClosed)
            }`}
          </p>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
