import { Button, ButtonProps } from '@components/Button'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'
import { PuffLoader } from 'react-spinners'

export interface IConfirmDialogProps {
  // required
  open: boolean
  title: React.ReactNode | string
  subtitle: React.ReactNode | string
  onConfirm: () => void
  onCancel: () => void
  // optional
  confirmText?: string
  cancelText?: string
  confirmBtnProps?: Partial<ButtonProps>
  cancelBtnProps?: Partial<ButtonProps>
  loading?: boolean
  icon?: React.ReactNode
}

const ConfirmDialog = ({
  open,
  icon,
  title,
  subtitle,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  confirmBtnProps = {},
  cancelBtnProps = { secondary: true },
  loading,
}: IConfirmDialogProps) => {
  const handleConfirm = () => {
    onConfirm()
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-50"
        onClose={handleCancel}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="pointer-events-none fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the theme contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="my-8 inline-block w-full max-w-xs transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              {icon && (
                <div className="mt-8 flex items-center justify-center">
                  {icon}
                </div>
              )}
              {title && (
                <Dialog.Title
                  as="h3"
                  className="mt-8 text-center text-xl font-medium leading-6 text-gray-900">
                  {title}
                </Dialog.Title>
              )}
              {subtitle && (
                <p className="mt-4 text-center text-sm text-gray-500">
                  {subtitle}
                </p>
              )}
              <div className="mt-8 grid w-full grid-cols-2 gap-4">
                <Button
                  {...cancelBtnProps}
                  disabled={loading}
                  onClick={handleCancel}>
                  {cancelText}
                </Button>

                <Button
                  {...confirmBtnProps}
                  disabled={loading}
                  onClick={handleConfirm}>
                  {loading ? (
                    <PuffLoader size={20} color={'white'} />
                  ) : (
                    confirmText
                  )}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ConfirmDialog
