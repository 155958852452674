import clsx from 'clsx'

export const Container = ({
  className,
  children,
}: {
  className?: string
  children: any
}) => {
  return (
    <>
      <main
        className={clsx('mx-auto min-h-screen flex-1 lg:container', className)}>
        <div className="py-6">
          <div className="px-4 sm:px-6 md:px-8">{children}</div>
        </div>
      </main>
    </>
  )
}
