import { ImageKit } from '@components'
import { IInvestmentInvitation } from '@types'
import axios from 'axios'
import { AuthContext } from 'context/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

export const InvitationBanner = ({
  currentInvitation,
  setCurrentInvitation,
  setInvitationLoading,
}: {
  currentInvitation: IInvestmentInvitation
  setCurrentInvitation: Function
  setInvitationLoading: Function
}) => {
  const router = useRouter()
  const authContext = useContext(AuthContext)
  const loadInvitations = async () => {
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL + '/me/pendingGroupInvitations',
    )
    return response.data
  }

  useEffect(() => {
    ;(async function () {
      if (authContext.authenticated && !currentInvitation.id) {
        const data = await loadInvitations()
        if (data.length) {
          setCurrentInvitation(data[0])
        }
      }
      setInvitationLoading(false)
    })()
  }, [authContext, currentInvitation.id])

  const markAsViewed = async () => {
    setCurrentInvitation({})
    axios
      .patch(
        process.env.NEXT_PUBLIC_API_URL +
          `/me/investmentGroupMemberships/${currentInvitation.investmentGroup?.id}/markAsViewed`,
      )
      .then(async response => {
        const data = await loadInvitations()
        if (data.length) {
          setCurrentInvitation(data[0])
        }
        setInvitationLoading(false)
      })
  }
  return (
    <div
      className={`${
        currentInvitation.id ? 'h-20 lg:h-12' : 'h-0 overflow-hidden'
      }  flex w-full flex-col items-center justify-center bg-primary transition-all lg:flex-row`}>
      {currentInvitation?.id ? (
        <div className="relative mx-auto w-11/12 lg:w-10/12">
          <p className="mb-0 text-center text-xs font-semibold text-white md:text-base">
            You have been invited by{' '}
            {currentInvitation.investmentGroup?.ownerUserProfile.user
              ?.firstName +
              ' ' +
              currentInvitation.investmentGroup?.ownerUserProfile.user
                ?.lastName}{' '}
            to review the {currentInvitation.investmentGroup?.company.name}{' '}
            Opportunity
          </p>
          <div className="relative bottom-0 right-0 top-0 flex cursor-pointer justify-center lg:absolute">
            {router.query.slug ===
            currentInvitation.investmentGroup?.company.slug ? (
              <button onClick={() => markAsViewed()}>
                <ImageKit
                  alt="Close popup"
                  src="/images/close-invitation.png"
                  width={9}
                  height={9}
                  style={{ objectFit: 'contain' }}
                />
              </button>
            ) : (
              <Link
                href={`/invest/company/${currentInvitation.investmentGroup?.company.slug}`}>
                <button className="text-xs text-white md:text-sm">
                  See Opportunity &#8594;
                </button>
              </Link>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
