import Link from 'next/link'
const profileRoutes = [
  {
    id: 1,
    route_name: 'Basic Info',
    url: '/profiles',
  },
  {
    id: 2,
    route_name: 'Investment Entities',
    url: '/profiles/profile-entities',
  },
]
export const ProfileNavs = ({ activeTab }: { activeTab?: string }) => {
  return (
    <div className="mb-8 w-full border-b pb-2">
      <ul className="grid w-full grid-cols-2 items-center px-4 md:w-3/5 md:gap-4 md:px-8 xl:w-5/11">
        {profileRoutes.map(route => (
          <li key={route.id} className="h-7 text-themeBlack">
            <Link
              href={route.url}
              className={`group hover:font-semibold hover:text-primary ${
                activeTab === route.route_name && 'font-semibold text-primary'
              }`}>
              <div className="w-fit">
                <span>{route.route_name}</span>
                <div
                  className={`mx-auto mt-2.5 group-hover:rounded group-hover:border-b-4 group-hover:border-primary ${
                    activeTab === route.route_name &&
                    'rounded border-b-4 border-primary'
                  }`}
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
