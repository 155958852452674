import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const API_TOKEN = process.env.NEXT_PUBLIC_DATO_CMS_API_KEY
const API_URI =
  process.env.NEXT_PUBLIC_DATO_CMS_API_URI || 'https://graphql.datocms.com/'

const httpLink = createHttpLink({
  uri: API_URI,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: Object.assign(headers || {}, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${API_TOKEN}`,
    }),
  }
})
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})

export const getGraphQLData = <T>(query: string): Promise<T> =>
  apolloClient
    .query({
      query: gql(query),
    })
    .then(response => response.data)
    .catch((err: any) => console.log('::: ERR', err))
