import { ImageKit, Tooltip } from '@components'

export const CompanyStat = ({
  title,
  subtitle,
  tooltip,
}: {
  title?: string
  subtitle: string
  tooltip?: string
}) =>
  title ? (
    <div
      className={`mb-6 flex w-full flex-col pb-2 ${
        subtitle === 'Share Price' ? ' border-b border-primary' : 'border-b'
      }`}>
      <div className="flex items-start justify-between">
        <p
          className={`text-xl font-bold capitalize ${
            subtitle === 'Share Price' ? 'text-primary' : 'text-gray-900'
          }`}>
          {title}
        </p>
        {tooltip && (
          <Tooltip message={tooltip}>
            <div className="relative">
              <ImageKit
                alt="Tooltip image"
                src="/images/info-square.svg"
                height={20}
                width={20}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <p
        className={`text-xs text-primary ${
          subtitle === 'Share Price' ? 'text-primary' : 'text-gray-400'
        }`}>
        {subtitle}
      </p>
    </div>
  ) : (
    <div className="mb-6 w-full cursor-pointer px-4">
      <div className="flex w-full items-center justify-between">
        <p className="text-sm font-bold text-gray-900">{subtitle}</p>
        <p className="text-xs text-gray-400">Minimum Investment</p>
        {tooltip && (
          <Tooltip message={tooltip}>
            <div className="relative flex items-center ">
              <ImageKit
                alt="Tooltip image"
                src="/images/info-square.svg"
                height={20}
                width={20}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
