import classNames from 'classnames'
import router from 'next/router'

export const TabPills = ({
  tabs,
}: {
  tabs: Array<{ name: string; href: string; current?: boolean }>
}) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={event => {
            router.push(event.target.value)
          }}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find(tab => tab.current)?.href}>
          {tabs.map(tab => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map(tab => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'bg-themeGray-light text-gray-700'
                  : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium',
              )}
              aria-current={tab.current ? 'page' : undefined}>
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
