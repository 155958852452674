import React from 'react'
export interface TextAreaInputProps {
  // required
  label: string
  onChange: Function
  value: string
  name: string

  // optional
  defaultValue?: string
  placeholder?: string
  description?: string
  required?: boolean
  error?: boolean
  areaHeight?: number
}

export const TextAreaInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  placeholder,
  description,
  required,
  error,
  areaHeight,
}: TextAreaInputProps) => {
  const classess = {
    default:
      'shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded resize-none',
    error:
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded',
  }

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-base font-medium text-themeBlack">
        {label}
        {required && <sup className="text-red-500">*</sup>}
      </label>

      <div className="mt-2">
        <textarea
          id={name}
          name={name}
          defaultValue={defaultValue}
          rows={areaHeight ? areaHeight : 8}
          className={error ? classess.error : classess.default}
          onChange={e => onChange(e)}
          value={value}
          required={required}
          placeholder={placeholder}
          aria-describedby={name}
        />
      </div>

      {description && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
