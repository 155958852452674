import { useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  FilterFn,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { Button, TextInput } from '@components'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const ReactTable: React.FC<any> = ({ columns, data, disableSearch }) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState('')

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    addMeta({
      itemRank,
    })

    return itemRank.passed
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 200,
    },
  })

  return (
    <>
      {!disableSearch && (
        <div className="mb-4 w-full md:w-1/4">
          <TextInput
            label=""
            name="search"
            value={globalFilter ?? ''}
            onChange={(e: any) => setGlobalFilter(String(e.target.value))}
            placeholder="Search"
          />
        </div>
      )}
      <div className="overflow-y-hidden overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table
          style={{ width: table.getCenterTotalSize() }}
          className="min-w-full">
          <thead className="sticky top-0 z-10 bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    style={{ width: header.getSize() }}
                    key={header.id}
                    className="max-w-max bg-primary px-3 py-4 text-left text-sm font-semibold text-white">
                    {header.isPlaceholder ? null : (
                      <span
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted() as string] ?? ' ↑↓'}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className="max-w-max border-b border-gray-200 bg-white p-3 text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-2 flex items-center justify-end">
        <div className="mr-4 text-sm">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
        </div>
        <div>
          <Button
            className="mr-1.5"
            size="xs"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            {'<'}
          </Button>
          <Button
            size="xs"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
            {'>'}
          </Button>
        </div>
      </div>
    </>
  )
}
