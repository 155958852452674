import classNames from 'classnames'
import { ReactNode } from 'react'
const POSITION_CLASS = {
  top: '-translate-y-full -translate-x-1/2 left-1/2',
  bottom: 'top-5 -mb-4 -translate-x-1/2 left-1/2',
  left: 'left-0 -translate-x-full -mt-1',
  right: 'right-0 translate-x-full -mt-1',
}

export const Tooltip = ({
  children,
  message,
  className = 'max-w-xs lg:w-max',
  position = 'left',
}: {
  children: ReactNode
  message: string
  className?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
}) => {
  return (
    <div className="group relative">
      <span
        className={classNames(
          'invisible absolute rounded-lg bg-gray-700 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:visible group-hover:z-50 group-hover:opacity-100',
          className,
          POSITION_CLASS[position],
        )}>
        {message}
      </span>
      {children}
    </div>
  )
}

export default Tooltip
