import { Button } from '@components/Button'
import SumsubWebSdk from '@sumsub/websdk-react'
import axios from 'axios'
import { AuthContext } from 'context/auth'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

interface ApplicantStatusMsg {
  reviewResult: {
    reviewAnswer: 'GREEN' | 'RED' | 'YELLOW'
    reviewRejectType: 'RETRY' | 'FINAL'
  }
  reviewStatus:
    | 'init'
    | 'pending'
    | 'prechecked'
    | 'queued'
    | 'completed'
    | 'onHold'
}

export const KycSDK = ({
  setRejected,
  setDisplayNextBtn,
}: {
  setRejected: (rejected: true) => void
  setDisplayNextBtn: (display: boolean) => void
}) => {
  const router = useRouter()
  const [accessToken, setAccessToken] = useState<string | null>()
  const { getUser } = useContext(AuthContext)

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_API_URL + `/kyc/sumsub-access-token`)
      .then(({ data }) => setAccessToken(data.token))
      .catch(console.log)
  }, [])

  const messageHandler = (type: string, payload: any) => {
    if (type === 'idCheck.onApplicantSubmitted') getUser()
    if (
      type === 'idCheck.applicantStatus' &&
      payload.reviewStatus === 'completed'
    ) {
      const { reviewResult } = payload as ApplicantStatusMsg
      if (reviewResult.reviewAnswer === 'GREEN') {
        setDisplayNextBtn(true)
      } else if (
        reviewResult.reviewAnswer === 'RED' &&
        reviewResult.reviewRejectType === 'FINAL'
      ) {
        setTimeout(() => setRejected(true), 2000)
      }
    }
  }

  return (
    <>
      {accessToken && (
        <SumsubWebSdk
          accessToken={accessToken}
          expirationHandler={async () => {
            return 'Exception'
          }}
          onMessage={messageHandler}
          config={{ lang: 'en' }}
        />
      )}
    </>
  )
}
