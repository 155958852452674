import CookieConsent from 'react-cookie-consent'

export const ConsentCookie = () => {
  return (
    <div>
      <CookieConsent
        buttonText="I Accept"
        declineButtonText="I Decline"
        cookieName="userConsent"
        disableButtonStyles
        buttonClasses="text-white bg-primary hover:bg-primary-dark py-1.5 px-2.5 m-4 rounded"
        enableDeclineButton
        declineButtonClasses="text-themeBlack bg-themeGray hover:bg-themeGray-light py-1.5 px-2.5 m-4 rounded"
        flipButtons>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}
