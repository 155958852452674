import { ImageKit } from '@components'
import { IDatoCmsInvestor } from '@types'
import { useInView } from 'react-intersection-observer'

const GRAYSCALE_BLUR_HASH_DATA_URL =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoTCAgLCgoSGh0VDg0NDh0VFhEVFxUdGBYVFhgfKysmGh0oHSEWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLBQUFEAUFEC8cFhwvLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIAAYAGAMBIgACEQEDEQH/xAAXAAEAAwAAAAAAAAAAAAAAAAAAAgQH/8QAIBAAAQMCBwAAAAAAAAAAAAAAAAECAwSRBREUISIxcf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDWJ6uZjeCNQp6+uV2zo8vAAJLiFY3tY7AAD//Z'

export const CompanyInvestors = ({
  investors,
  setActive,
}: {
  investors?: IDatoCmsInvestor[]
  setActive?: Function
}) => {
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 1,
    onChange: inView => {
      if (inView && setActive) {
        setActive('investors')
      }
    },
  })
  return investors && investors.length ? (
    <div ref={ref} className="my-4 w-full py-6" id="investors">
      <p className="mb-8 font-extrabold text-primary md:text-xl">
        Leading Investors
      </p>
      <div className="flex w-full flex-wrap items-center justify-center gap-6">
        {investors.map((investor, idx) => {
          return (
            <div
              key={idx}
              className="flex max-w-[7rem] cursor-default items-center">
              {investor.logo ? (
                <div className="relative h-10 w-28">
                  <ImageKit
                    src={investor.logo.url}
                    alt={investor.name}
                    title={investor.name}
                    fill
                    style={{ objectFit: 'contain' }}
                    className="grayscale transition hover:grayscale-0"
                    placeholder="blur"
                    blurDataURL={GRAYSCALE_BLUR_HASH_DATA_URL}
                  />
                </div>
              ) : (
                <p className="w-full text-center font-extrabold leading-5 text-themeBlack-dark">
                  {investor.name}
                </p>
              )}
            </div>
          )
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}
