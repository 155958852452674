import React, { useRef, useEffect } from 'react'
import { Tooltip, ImageKit } from '@components'
import Select, { components } from 'react-select'
export interface SelectOption {
  value: string | number
  text?: string
  label?: string
  disabled?: boolean
}

export interface SelectInputProps {
  // required
  label: string
  onChange: Function
  name: string

  // optional
  defaultValue?: string | number
  description?: string
  required?: boolean
  error?: boolean
  options: SelectOption[]
  disabled?: boolean
  placeholder?: string
  tooltip?: string
  multiple?: boolean
  value?: SelectOption[]
  note?: string
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export const SelectInput = ({
  label,
  onChange,
  name,
  defaultValue,
  description,
  required,
  error,
  options,
  disabled,
  placeholder,
  tooltip,
  multiple,
  value,
  note,
}: SelectInputProps) => {
  const classess = {
    default: 'focus:ring-primary focus:border-primary border-gray-300 ',
    error:
      'focus:ring-red-500 focus:border-red-500 focus:outline-none border-red-300 pr-10 text-red-900 placeholder-red-300',
  }
  const multipleInputRef = useRef<any>(null)
  useEffect(() => {
    multiple &&
    required &&
    multipleInputRef.current !== null &&
    multipleInputRef.current.inputRef &&
    !value?.length
      ? (multipleInputRef.current.inputRef.required = true)
      : multipleInputRef.current &&
        (multipleInputRef.current.inputRef.required = false)
  }, [required, value, multiple])
  return (
    <div>
      <div className="relative flex items-center">
        <label
          htmlFor={name}
          className="block text-base font-medium text-themeBlack">
          {label}
          {required && <sup className="text-red-500">*</sup>}
          {note && <span className="text-themeGray-dark2"> {note}</span>}
        </label>
        {tooltip && (
          <Tooltip position="top" message={tooltip}>
            <div className="mb-0 ml-1 flex cursor-pointer items-center">
              <ImageKit
                src="/images/question.svg"
                alt="Tooltip image"
                height={16}
                width={16}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {multiple ? (
        <div className="mt-1">
          {' '}
          <Select
            closeMenuOnSelect={false}
            ref={multipleInputRef}
            isDisabled={disabled}
            value={value}
            options={options}
            onChange={(value: any) => {
              onChange(value)
            }}
            isMulti
            components={{
              Option,
            }}
          />
        </div>
      ) : (
        <div className="mt-1">
          <select
            disabled={disabled}
            autoComplete="none"
            value={defaultValue}
            id={name}
            name={name}
            className={`${
              error ? classess.error : classess.default
            } block w-full rounded shadow-sm sm:text-sm ${
              disabled ? 'bg-gray-100' : ''
            }`}
            onChange={e => onChange(e)}
            required={required}
            aria-describedby={name}>
            {placeholder && (
              <option value="" disabled selected hidden>
                {placeholder}
              </option>
            )}
            {options?.map((option: SelectOption) => (
              <option
                disabled={option.disabled || false}
                key={option.value}
                value={option.value}>
                {option.text || option.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
