import { IEvent } from '@types'
import { ImageKit } from '@components'
import moment from 'moment'

export const PastEventsListing = ({
  event,
  activeEventId,
  setActiveEventId,
  setEventGalleryImages,
}: {
  event: IEvent
  activeEventId: string
  setActiveEventId: Function
  setEventGalleryImages: Function
}) => {
  return (
    <>
      <div
        className={`${
          event.id === activeEventId
            ? 'bg-primary-dark text-white'
            : 'bg-transparent text-black hover:bg-themeGray-light'
        } rounded-tb-xl mb-2 cursor-pointer rounded-r-xl px-12 py-6`}
        onClick={() => {
          setActiveEventId(event.id)
          setEventGalleryImages(event.id)
        }}>
        <h4 className={`text-3xl`}>{event.title}</h4>
        <div className="flex items-start justify-between pt-4">
          <p className={`w-4/6 text-xl leading-8`}>
            {!event.dateEnd
              ? moment(event.date).format('DD MMM, YYYY')
              : `${moment(event.date).format('DD')}-${moment(
                  event.dateEnd,
                ).format('DD MMM, YYYY')}`}
            <br />
            {event.location}
          </p>
          {event.id === activeEventId && (
            <ImageKit
              src="/images/Vector-right.png"
              alt="vector"
              width={10}
              height={10}
            />
          )}
        </div>
      </div>
    </>
  )
}
