import React, { useState } from 'react'
import { cva } from 'class-variance-authority'
import { PuffLoader } from 'react-spinners'
import classNames from 'classnames'
import Link from 'next/link'

export interface ButtonProps {
  children: any
  secondary?: boolean
  dark?: boolean
  light?: boolean
  danger?: boolean
  submit?: boolean
  primaryBorder?: boolean
  whiteBorder?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onClick?: Function
  disabled?: boolean
  className?: string
  loading?: boolean
  showLoadingSpinnerOnClick?: boolean
  outline?: boolean
  newButton?: boolean
  newButtonSize?: 'small' | 'large' | 'xsmall'
  variant?: 'primary' | 'secondary' | 'link' | 'subtle'
  href?: string
  fullWidth?: boolean
}

export const Button = ({
  children,
  secondary,
  dark,
  light,
  danger,
  submit,
  primaryBorder,
  whiteBorder,
  size = 'md',
  onClick,
  disabled,
  className,
  loading,
  showLoadingSpinnerOnClick,
  outline,
  newButton,
  href,
  fullWidth,
  newButtonSize,
  variant,
  ...props
}: ButtonProps) => {
  const sizeClasses = {
    xs: 'px-2.5 py-1.5 text-xs rounded',
    sm: 'px-3 py-2 text-sm leading-4 rounded-md',
    md: 'px-4 py-2 text-sm rounded-md',
    lg: 'px-4 py-2 text-base rounded-md',
    xl: 'px-6 py-3 text-xl font-semibold rounded-md',
  }

  const [isClicked, setIsClicked] = useState(false)

  const colorClasses = {
    DEFAULT:
      'text-white focus:ring-primary hover:opacity-90 transition-opacity bg-cta-background',
    danger: 'text-white bg-red-700 hover:bg-red-600 focus:ring-red-500',
    secondarydanger:
      'text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500',
    secondary:
      'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500',
    disabled: 'text-gray-700 bg-gray-300',
    dark: 'text-white bg-themeBlack hover:bg-themeBlack-light focus:ring-themeBlack',
    darkOutline:
      'text-themeBlack border border-themeBlack focus:ring-themeBlack hover:bg-themeGray-light2',
    light:
      'text-themeBlack bg-themeGray hover:bg-themeGray-light focus:ring-themeGray',
    primaryBorder:
      'text-primary hover:bg-primary hover:text-white focus:ring-[#6D45B5] border-primary text-primary border-rounded-md',
    whiteBorder: 'text-white bg-themeBlack border border-[#FFFFFF] px-8 py-1',
  }

  const getColor = () => {
    if (disabled) return colorClasses.disabled
    else if (secondary && danger) return colorClasses.secondarydanger
    else if (whiteBorder) return colorClasses.whiteBorder
    else if (secondary) return colorClasses.secondary
    else if (dark) return outline ? colorClasses.darkOutline : colorClasses.dark
    else if (light) return colorClasses.light
    else if (danger) return colorClasses.danger
    else if (primaryBorder) return colorClasses.primaryBorder
    return colorClasses.DEFAULT
  }

  const button = cva(
    'flex cursor-pointer rounded-md items-center justify-center text-center transition-colors font-body',
    {
      variants: {
        newButtonSize: {
          xsmall: 'py-1.5 px-3',
          small: 'py-2 px-4 text-lg',
          large: 'py-3 px-6 text-xl',
        },
        variant: {
          primary:
            'bg-mx-purple-dark text-white hover:bg-mx-purple-neon border border-mx-purple-dark hover:border-mx-purple-neon',
          secondary:
            'bg-white text-mx-purple-dark hover:text-mx-purple-neon border border-mx-purple-dark hover:border-mx-purple-neon',
          subtle:
            'bg-transparent text-mx-purple-dark hover:text-mx-purple-neon border border-transparent hover:border-mx-purple-neon',
          link: 'text-mx-blue hover:text-mx-light-blue',
        },
      },
      defaultVariants: {
        newButtonSize: 'large',
        variant: 'primary',
      },
    },
  )

  if (newButton) {
    if (href) {
      if (href.startsWith('/')) {
        return (
          <Link
            href={href}
            className={classNames(
              button({
                newButtonSize,
                variant,
              }),
              className,
            )}
            {...props}>
            {children}
          </Link>
        )
      }
      return (
        <a
          href={href}
          className={classNames(
            button({
              newButtonSize,
              variant,
            }),
            className,
          )}
          {...props}>
          {children}
        </a>
      )
    }

    return (
      <button
        className={classNames(
          button({
            newButtonSize,
            variant,
          }),
          className,
        )}
        {...props}>
        {children}
      </button>
    )
  } else {
    return (
      <button
        type={submit ? 'submit' : 'button'}
        className={classNames(
          sizeClasses[size],
          getColor(),
          'inline-flex items-center justify-center border border-transparent px-8 font-normal shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
          className,
        )}
        onClick={props => {
          setIsClicked(true), onClick && onClick(props)
        }}
        disabled={disabled}>
        {loading || (isClicked && showLoadingSpinnerOnClick) ? (
          <PuffLoader size={22} color={'white'} />
        ) : (
          <>{children}</>
        )}
      </button>
    )
  }
}
