import { useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/24/outline'

export const AccordionItem = ({
  children,
  title,
}: {
  children: any
  title: string
}) => {
  const [currentExpanded, setCurrentExpanded] = useState(false)

  const handleToggle = () => {
    setCurrentExpanded(!currentExpanded)
  }

  return (
    <li className="mb-4 rounded bg-themeBlack">
      <button
        className="flex w-full items-center justify-between py-3.5 pl-6 text-left text-white"
        onClick={() => {
          handleToggle()
        }}>
        <span className="w-4/5 md:w-11/12">{title}</span>
        <span className="pr-6 text-lg">
          <ChevronUpIcon
            className={`ml-2 h-4 w-4 ${
              currentExpanded
                ? 'rotate-180 duration-700 ease-in-out'
                : 'rotate-0 duration-700 ease-in-out'
            }`}
          />
        </span>
      </button>

      <div
        className={`max-h-0 overflow-hidden bg-[#f7f7f7] ${
          currentExpanded
            ? 'max-h-[50rem] rounded-bl rounded-br border border-themeGray duration-1000 ease-in-out'
            : 'duration-700 ease-in-out'
        }`}>
        {children}
      </div>
    </li>
  )
}
