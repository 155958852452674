export const WhatToDoNext = () => (
  <div className="my-8 text-center">
    <p className="mb-1 text-lg font-bold leading-6 text-themeBlack">
      Start Your Private Investment Journey Here
    </p>
    <p className="leading-7 text-themeGray-dark2">
      Browse, search or filter the following opportunities, then click on any
      one to learn and to invest.
    </p>
  </div>
)
