import { ICompany } from '@types'
import { Link } from 'react-scroll'
import { IDatoCmsModularDescription } from '@types'
import { AuthObject } from 'context/auth'
import { useWindowOffset } from '@hooks'
import { useEffect, useState } from 'react'

export const VerticalNavigationScroll = ({
  company,
  active,
  authContext,
  hideFAQ,
  hideGallery,
  scrollTop,
}: {
  company?: ICompany
  active: string
  authContext: AuthObject
  hideFAQ?: boolean
  hideGallery?: boolean
  scrollTop?: boolean
}) => {
  const menuItems: any = []
  const windowOffset = useWindowOffset()
  const [activeTop, setActiveTop] = useState(scrollTop)
  company?.modularDescription?.forEach((item: IDatoCmsModularDescription) => {
    if (item.__typename === 'SectionHeaderRecord') {
      menuItems.push({
        id: item.id,
        sectionTitle: item.sectionTitle,
        contains: [],
      })
    } else if (menuItems.length) {
      menuItems[menuItems.length - 1]?.contains?.push(`description-${item.id}`)
    }
  })
  useEffect(() => {
    if (scrollTop && windowOffset.yOffset && windowOffset.yOffset > 0) {
      setActiveTop(false)
    } else if (scrollTop) {
      setActiveTop(true)
    }
  }, [windowOffset, scrollTop])

  return (
    <div className="sticky top-[30%] mb-12">
      <div className="relative left-8 flex hidden justify-end pr-12 xl:flex">
        <ul>
          {!hideGallery &&
          company?.mediaGallery &&
          company?.mediaGallery?.length ? (
            <li
              className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                active === 'gallery'
                  ? 'font-bold text-primary'
                  : 'text-slate-600'
              } hover:text-primary`}>
              <Link smooth offset={-150} spy to="gallery">
                Gallery
              </Link>
            </li>
          ) : (
            <></>
          )}
          {scrollTop ? (
            <li
              className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                activeTop || active === 'gallery'
                  ? 'font-bold text-primary'
                  : 'text-slate-600'
              } hover:text-primary`}>
              <button
                onClick={() => {
                  typeof window !== 'undefined' &&
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}>
                Top
              </button>
            </li>
          ) : (
            <></>
          )}
          {company?.investors?.length ? (
            <li
              className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                active === 'investors'
                  ? 'font-bold text-primary'
                  : 'text-slate-600'
              } hover:text-primary`}>
              <Link smooth offset={-100} spy to="investors">
                Leading Investors
              </Link>
            </li>
          ) : (
            <></>
          )}
          {menuItems?.map((item: any) => {
            return (
              <li
                key={item.id}
                className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                  active === `description-${item.id}` ||
                  item.contains.includes(active)
                    ? 'font-bold text-primary'
                    : 'text-slate-600'
                } hover:text-primary`}>
                <Link smooth offset={-100} spy to={`description-${item.id}`}>
                  {item.sectionTitle}
                </Link>
              </li>
            )
          })}
          {company?.fundingRounds?.length ? (
            <li
              className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                active === 'fundingRounds'
                  ? 'font-bold text-primary'
                  : 'text-slate-600'
              } hover:text-primary`}>
              <Link smooth offset={-150} spy to="fundingRounds">
                Funding Rounds
              </Link>
            </li>
          ) : (
            <></>
          )}

          {authContext.authenticated && !hideFAQ && (
            <li
              className={`mb-4 min-w-[175px] cursor-pointer text-[14px] ${
                active === 'faq' ? 'font-bold text-primary' : 'text-slate-600'
              } hover:text-primary`}>
              <Link smooth offset={-150} spy to="faq">
                FAQ&apos;s
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
