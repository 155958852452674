import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'
import clsx from 'clsx'

export const BreadCrumbs = ({
  pages,
  className,
}: {
  pages: { name: string; href?: string; current?: boolean }[]
  className?: string
}) => (
  <nav className={clsx('flex', className)} aria-label="Breadcrumb">
    <ol role="list" className="flex items-center space-x-4">
      <li>
        <div>
          <Link href="/" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      {pages.map(page => (
        <li key={page.name}>
          <div className="flex items-center">
            <ChevronRightIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {page.href ? (
              <Link
                href={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}>
                {page.name}
              </Link>
            ) : (
              <span className="ml-4 text-sm font-medium text-gray-700 cursor-default">
                {page.name}
              </span>
            )}
          </div>
        </li>
      ))}
    </ol>
  </nav>
)
