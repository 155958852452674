import { ImageKit } from '@components'

export const DiscountBadge = ({
  discountPercent,
}: {
  discountPercent?: number | null
}) => {
  return (
    <div>
      <ImageKit
        width={80}
        height={100}
        alt={'discount-image'}
        src={'/images/discount-badge.svg'}
      />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center p-3 text-white">
        <span className="mb-1 text-3xl font-bold">{discountPercent}%</span>
        <span className="text-xs">DISCOUNT</span>
      </div>
    </div>
  )
}
