import React, { ReactElement } from 'react'
export interface iCheckboxInputProps {
  // required
  label: string | ReactElement
  onChange: Function
  value: boolean
  name: string

  // optional
  required?: boolean
  disabled?: boolean
}

export const CheckboxInput = ({
  label,
  onChange,
  value,
  name,
  required,
  disabled,
}: iCheckboxInputProps) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          checked={value}
          onChange={e => onChange(e)}
          required={required}
          id={name}
          name={name}
          disabled={disabled}
          aria-describedby={name}
          type="checkbox"
          className={`h-4 w-4 rounded border-gray-300 text-primary focus:ring-0 ${
            disabled ? 'opacity-50' : 'cursor-pointer'
          }`}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={name}
          className="cursor-pointer font-medium text-gray-700">
          {label}
          {required && label && <sup className="text-red-500">*</sup>}
        </label>
      </div>
    </div>
  )
}
