import { Button } from '@components/Button'
import { Table } from '@components/Table'
import { ICompany, IDeal } from '@types'
import { abbreviateNumber } from '@utils'
import Link from 'next/link'
import classNames from 'classnames'

export const CompanyDealsTable = ({
  company,
  deals,
}: {
  company: ICompany
  deals: IDeal[]
}) => (
  <div className="mb-4 w-full border-t border-gray-200 pt-4 md:pt-8">
    <p className="mb-8 font-extrabold text-primary md:text-lg">Deals</p>
    <Table
      className="mt-8 w-full overflow-x-auto rounded-xl border"
      data={deals}
      columns={[
        {
          name: 'Share Price',
          accessor: 'sharePrice',
          render(value) {
            return (
              <span className="text-xs">{`${abbreviateNumber(value)}`}</span>
            )
          },
        },
        {
          name: 'Share Class',
          accessor: 'shareClass',
          render(value) {
            return (
              <span
                className={classNames(
                  'rounded-sm border px-0.5 py-0.5 text-[9.5px] uppercase',
                  value === 'common'
                    ? 'border-sky-500 bg-sky-100'
                    : 'border-purple-500 bg-purple-100',
                )}>
                {value}
              </span>
            )
          },
        },
        {
          name: 'Valuation',
          accessor: 'valuation',
          render(value) {
            return (
              <span className="text-xs">{`${abbreviateNumber(value)}`}</span>
            )
          },
          align: 'right',
        },
        {
          name: 'Min. Investment',
          accessor: 'minimumInvestment',
          render(value) {
            return (
              <span className="text-xs">{`${abbreviateNumber(value)}`}</span>
            )
          },
          align: 'right',
        },
        {
          name: 'Allocation',
          accessor: 'allocation',
          render(value) {
            return (
              <span className="text-xs">{`${abbreviateNumber(value)}`}</span>
            )
          },
          align: 'right',
        },
        {
          name: 'Invest',
          accessor: 'Invest',
          render(value, row) {
            return (
              <>
                <Link
                  legacyBehavior
                  href={`/companies/${company.slug}/deals/${row.id}/subscribe`}>
                  <a>
                    <Button size="xs">Invest</Button>
                  </a>
                </Link>
              </>
            )
          },
          align: 'right',
        },
      ]}
    />
  </div>
)
