import { IProfile } from '@types'
import { Country } from 'country-state-city'
import { Button } from '@components'
import Link from 'next/link'
import { standardDate } from '@utils'

export const ProfileDetail = ({
  profileDetail,
  userFirstName,
  userLastName,
  userEmail,
}: {
  profileDetail: IProfile
  userFirstName: string
  userLastName: string
  userEmail: string
}) => {
  const countries: any[] = Country.getAllCountries()

  return (
    <div className="rounded-lg border px-2 md:px-8">
      <>
        <p className="pt-4 text-lg font-semibold">Basic Info</p>
        <div className="py-4">
          <div className="flex items-center justify-between pb-4">
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">
                {profileDetail.profileType !== 'family'
                  ? 'First name'
                  : 'Entity Name'}
              </p>
              <p className="text-base font-normal">
                {profileDetail.profileType !== 'family'
                  ? userFirstName
                  : profileDetail?.entityName}
              </p>
            </div>
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">
                {profileDetail.profileType !== 'family'
                  ? 'Last name'
                  : 'Created On'}
              </p>
              <p className="text-base font-normal">
                {profileDetail.profileType !== 'family'
                  ? userLastName
                  : standardDate(profileDetail?.createdAt)}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between pb-4">
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">Address</p>
              <p className="text-base font-normal">
                {profileDetail?.addressLine1
                  ? profileDetail?.addressLine1
                  : '--'}
              </p>
            </div>
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">City</p>
              <p className="text-base font-normal">
                {profileDetail?.city ? profileDetail?.city : '--'}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between pb-4">
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">Zip/Postal code</p>
              <p className="text-base font-normal">
                {profileDetail?.postalCode ? profileDetail?.postalCode : '--'}
              </p>
            </div>
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">State/Province</p>
              <p className="text-base font-normal">
                {profileDetail?.state ? profileDetail?.state : '--'}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between pb-4">
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">Country</p>
              <p className="text-base font-normal">
                {profileDetail?.country
                  ? countries.find(
                      item => item?.isoCode === profileDetail?.country,
                    ).name
                  : '--'}
              </p>
            </div>
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">Phone Number</p>
              <p className="text-base font-normal">
                {profileDetail?.phoneNumber ? profileDetail?.phoneNumber : '--'}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between pb-4">
            <div className="flex w-5/11 flex-col justify-between md:flex-row md:items-center">
              <p className="text-base font-semibold">Email Address</p>
              <p className="text-base font-normal">
                {userEmail ? userEmail : '--'}{' '}
              </p>
            </div>
          </div>
        </div>
      </>
      <div className="py-4">
        <Link
          href={`/profiles/edit?profileId=${profileDetail?.id}`}
          className="text-white">
          <Button dark>Edit</Button>
        </Link>
      </div>
    </div>
  )
}
