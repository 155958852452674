import { CompanyCard } from '@components/companies'
import { SelectInput, TextInput } from '@components/inputs'
import React, { useEffect } from 'react'
import Link from 'next/link'
import { useState } from 'react'
import { FunnelIcon } from '@heroicons/react/24/outline'
import { ICompanyFilters, ICompany } from '@types'
import { encodeQueryParams, Mixpanel } from '@utils'
import { useRouter } from 'next/router'
import { Button } from '@components/Button'
import { useWindowSize } from 'hooks'

const isFilterApplied = (filter: any) => {
  return !Object.keys(filter).every(k => filter[k] === '')
}

export const CompanySearch = ({
  filters,
  allCompanies,
}: {
  filters: ICompanyFilters
  allCompanies: ICompany[]
}) => {
  const router = useRouter()
  const windowSize = useWindowSize()

  const [companies, setCompanies] = useState<ICompany[]>(allCompanies)
  const [filteredCompanies, setFilteredCompanies] =
    useState<ICompany[]>(allCompanies)

  const [onSmallScreen, setOnSmallScreen] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const initFilters = {
    search: '',
    sector: '',
    region: '',
    lastFundingRound: '',
    location: '',
    stage: '',
  }

  // Filters
  const [filter, setFilter] = useState(initFilters)
  const [searchFromQueryParam, setSearchFromQueryParam] = useState(true)

  useEffect(() => {
    if (
      Object.keys(router.query).length > 0 &&
      companies &&
      companies.length > 0 &&
      searchFromQueryParam
    ) {
      const _filter = { ...filter }
      for (let param in router.query) {
        if (param !== '_gl') (_filter as any)[param] = router.query[param]
      }

      setFilter(_filter)
      onFilterChange(_filter)
      setSearchFromQueryParam(false)
    }

    if (companies && isFilterApplied(filter)) {
      onFilterChange(filter)
    }
  }, [companies, filter])

  useEffect(() => {
    if (windowSize.width && windowSize.width < 640) {
      setOnSmallScreen(true)
    } else {
      setOnSmallScreen(false)
    }
  }, [windowSize])

  useEffect(() => {
    setCompanies([...allCompanies])
    setFilteredCompanies([...allCompanies])
  }, [allCompanies])

  const onFilterChange = (
    currentFilter: any,
    filterName?: string,
    filterValue?: string,
  ) => {
    filterName &&
      filterValue &&
      Mixpanel.track(`Click-${filterName}-Filter-Event`, {
        filterOption: filterValue,
      })
    if (companies && companies.length > 0) {
      const _filtered: any = companies.filter((company: any) => {
        const conditions = []

        const nameOrHookMatched =
          (company.name &&
            company.name
              .toLowerCase()
              .includes(currentFilter.search.toLowerCase())) ||
          (company.hook &&
            company.hook
              .toLowerCase()
              .includes(currentFilter.search.toLowerCase()))
        if (currentFilter.search !== '') conditions.push(nameOrHookMatched)

        const sectorsMatched =
          company.sectors &&
          company.sectors?.find((s: any) => s.name === currentFilter.sector) !==
            undefined

        if (currentFilter.sector !== '') conditions.push(sectorsMatched)

        const regionMatched =
          company.region && company.region.name === currentFilter.region
        if (currentFilter.region !== '') conditions.push(regionMatched)

        const stageMatched =
          company.stage && company.stage.name === currentFilter.lastFundingRound
        if (currentFilter.lastFundingRound !== '') conditions.push(stageMatched)

        const stage2Matched =
          company.stage2 && company.stage2 === currentFilter.stage
        if (currentFilter.stage !== '') conditions.push(stage2Matched)

        const locationMatched =
          company.location &&
          company.location
            .toLowerCase()
            .includes(currentFilter.location.toLowerCase())
        if (currentFilter.location !== '') conditions.push(locationMatched)

        if (conditions.every(condition => condition === true)) {
          return company
        }
      })

      const queryString = encodeQueryParams(currentFilter)

      if (queryString !== '' && typeof window !== 'undefined') {
        window.history.replaceState(
          { path: `?${queryString}` },
          ``,
          `?${queryString}`,
        )
      } else if (typeof window !== 'undefined') {
        window.history.replaceState({ path: `/` }, '', '/invest/companies')
      }

      setFilteredCompanies(_filtered)
    }
  }

  const featuredDeals = filteredCompanies.filter(company => company.isFeatured)
  const nonFeaturedDeals = filteredCompanies.filter(
    company => !company.isFeatured,
  )

  return (
    <>
      <div className="mb-2 grid gap-4 sm:grid-cols-3 md:grid-cols-7">
        <div className="md:col-span-2">
          <div className="flex items-center justify-center">
            <div className="mr-4 flex-grow sm:mr-0">
              <TextInput
                placeholder="Type To Search..."
                name="search"
                value={filter.search}
                label=""
                onChange={(e: any) => {
                  const currentFilter = { ...filter, search: e.target.value }
                  setFilter(currentFilter)
                  onFilterChange(currentFilter)
                }}
              />
            </div>
            <div className="sm:hidden">
              <Button
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
                size="lg">
                <FunnelIcon className="mr-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
        {(!onSmallScreen || (onSmallScreen && showFilters)) && (
          <>
            <SelectInput
              name="sector"
              options={filters.sectorOptions}
              label=""
              defaultValue={filter.sector}
              onChange={(e: any) => {
                const currentFilter = { ...filter, sector: e.target.value }
                setFilter(currentFilter)
                onFilterChange(currentFilter, e.target.name, e.target.value)
              }}
              placeholder="Sector"
            />
            <SelectInput
              name="region"
              options={filters.regionOptions}
              label=""
              defaultValue={filter.region}
              onChange={(e: any) => {
                const currentFilter = { ...filter, region: e.target.value }
                setFilter(currentFilter)
                onFilterChange(currentFilter, e.target.name, e.target.value)
              }}
              placeholder="Region"
            />
            <SelectInput
              name="stage"
              options={filters.stages}
              label=""
              defaultValue={filter.stage}
              onChange={(e: any) => {
                const currentFilter = { ...filter, stage: e.target.value }
                setFilter(currentFilter)
                onFilterChange(currentFilter, e.target.name, e.target.value)
              }}
              placeholder="Stage"
            />
            <SelectInput
              name="series"
              options={filters.stageOptions}
              label=""
              defaultValue={filter.lastFundingRound}
              onChange={(e: any) => {
                const currentFilter = {
                  ...filter,
                  lastFundingRound: e.target.value,
                }
                setFilter(currentFilter)
                onFilterChange(currentFilter, e.target.name, e.target.value)
              }}
              placeholder="Last Funding Round"
            />
            <TextInput
              placeholder="Location"
              name="location"
              value={filter.location}
              label=""
              onChange={(e: any) => {
                const currentFilter = {
                  ...filter,
                  location: e.target.value,
                }
                setFilter(currentFilter)
                onFilterChange(currentFilter)
              }}
            />
          </>
        )}
      </div>

      <div className="mb-4 flex flex-wrap">
        {Object.keys(filter).map((k: string, i: number) => {
          if ((filter as any)[k] === '')
            return <React.Fragment key={i}></React.Fragment>
          else if (k in initFilters) {
            return (
              <div
                key={i}
                className="mr-4 flex items-center rounded-sm bg-purple-100 px-2 text-purple-500 shadow-sm">
                <span className="text-xs capitalize">{k}</span>:{' '}
                <span className="ml-1 text-sm font-medium">
                  {(filter as any)[k]}
                </span>
                <span
                  className="ml-4 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-purple-300 text-sm"
                  onClick={() => {
                    const currentFilter = { ...filter, [k]: '' }
                    setFilter(currentFilter)
                    onFilterChange(currentFilter)
                  }}>
                  ✖
                </span>
              </div>
            )
          }
        })}
      </div>

      {featuredDeals.length > 0 && (
        <>
          <h2 className="h2 mt-6">In Focus</h2>
          <div className="mb-20 grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-8 2xl:grid-cols-3">
            {featuredDeals?.map((company: any, idx: number) => (
              <Link
                href={`/invest/company/${company.slug}`}
                key={idx}
                className="block h-full w-full max-w-full">
                <CompanyCard company={company} />
              </Link>
            ))}
          </div>
        </>
      )}
      <h2 className="h2 mt-6">Opportunities</h2>
      <div className="mb-20 grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-8 2xl:grid-cols-3">
        {nonFeaturedDeals?.map((company: any, idx: number) => (
          <Link
            href={`/invest/company/${company.slug}`}
            key={idx}
            className="block h-full w-full max-w-full">
            <CompanyCard company={company} />
          </Link>
        ))}
      </div>
    </>
  )
}
