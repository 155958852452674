import { useEffect, useState } from 'react'

interface windowOffset {
  yOffset: undefined | number
}

// Hook
export const useWindowOffset = () => {
  // Initialize state with undefined offset so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowOffset, setWindowOffset] = useState<windowOffset>({
    yOffset: undefined,
  })

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window Offset
      function handleOffset() {
        // Set window offset to state
        setWindowOffset({
          yOffset: window.pageYOffset,
        })
      }

      // Add event listener
      window.addEventListener('scroll', handleOffset)

      // Call handler right away so state gets updated with initial window offset
      handleOffset()

      // Remove event listener on cleanup
      return () => window.removeEventListener('scroll', handleOffset)
    }
  }, []) // Empty array ensures that effect is only run on mount

  return windowOffset
}
