import { useLocalStorage } from '@hooks'
import { AuthContext } from 'context/auth'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

export default function useRequireLogin(): void {
  const router = useRouter()
  const { authenticated } = useContext(AuthContext)
  const [_, setLocalStorageRedirectAfterLogin] = useLocalStorage<string>(
    'mx-redirect-after-login',
    '',
  )

  useEffect(() => {
    if (router.isReady) {
      if (!authenticated) {
        setLocalStorageRedirectAfterLogin(router.asPath)
        router.push('/signup')
      }
    }
  }, [router])
}
