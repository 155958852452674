import React from 'react'
import { IDatoCmsModularDescription } from '@types'
import { useInView } from 'react-intersection-observer'
import { StructuredText } from 'react-datocms'
import { CompanySectionTitle, ImageKit } from '@components'
import { getYoutubeId } from '@utils'
export const CompanyDescriptionDetail = ({
  item,
  setActive,
}: {
  item: IDatoCmsModularDescription
  setActive?: Function
  currentUser?: any
  mainDealsId?: number
  title?: string
  companySlug?: string
  textRecord: number
}) => {
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 0.3,
    onChange: inView => {
      if (inView && setActive) {
        setActive(`description-${item.id}`)
      }
    },
  })

  return (
    <div id={`description-${item.id}`}>
      {item.__typename === 'SectionHeaderRecord' && (
        <CompanySectionTitle
          title={item.title}
          sectionTitle={item.sectionTitle}
        />
      )}
      {item.__typename === 'TextRecord' && (
        <div>
          <div
            ref={ref}
            className={`prose my-8 w-full max-w-none prose-a:font-normal prose-a:text-primary`}>
            <StructuredText data={item.content} />
          </div>
        </div>
      )}
      {item.__typename === 'ImageRecord' && (
        <div className="relative my-8 w-full">
          <ImageKit
            src={item.image.url}
            alt={item.title}
            layout="responsive"
            width={item.image.width}
            height={item.image.height}
            placeholder="blur"
            blurDataURL={item.image.blurUpThumb}
          />
        </div>
      )}
      {item.__typename === 'EmbeddedVideoRecord' && (
        <div className="my-8 w-full ">
          <iframe
            width="560"
            className="w-full aspect-video"
            allow="fullscreen"
            src={`https://www.youtube-nocookie.com/embed/${getYoutubeId(
              item.videoUrl,
            )}`}></iframe>
        </div>
      )}
    </div>
  )
}
