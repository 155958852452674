import { ImageKit } from '@components'
import { IDatoCmsHighLightBlock, IDatoCmsHighLightText } from '@types'

const BlockIcons = {
  revenue: 'money-receive.svg',
  funding: 'diagram.svg',
  other: 'people.svg',
}
export const CompanyHighlight = ({
  highlightText,
  highlightBlocks,
}: {
  highlightText: IDatoCmsHighLightText[]
  highlightBlocks: IDatoCmsHighLightBlock[]
}) => {
  return highlightText && highlightText.length ? (
    <div className="w-full border-t border-gray-200 py-4 md:py-10">
      <div>
        <p className="mb-5 text-xl font-extrabold text-primary">Highlights</p>

        <ul className="ml-5 list-outside list-disc">
          {highlightText?.map((highLight, index) => (
            <li key={index} className="mb-3">
              <span className="font-semibold">{highLight.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <></>
  )
}
