import Slider from '@ant-design/react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ImageKit } from '@components'
const Options = [
  {
    id: 1,
    img: '/images/bastian-purrer.png',
    title:
      'MarketX Ventures helped me make my very first private investment — Postmates!',
    details:
      'MarketX Ventures made it a reality for investors like me to participate in the tremendous growth of private tech companies before they go public.',
    name: 'Bastian Purrer',
    position: 'Founder of Lyke',
  },
  {
    id: 2,
    img: '/images/william_lou.png',
    title:
      'MarketX Ventures made it possible for us to invest in Bytedance back in 2018!',
    details:
      'We loved the seamless process and MarketX Ventures team professionalism in delivering absolute return in just 1-2 years!',
    name: 'William Lau',
    position: 'Asia Family Office, Owner of 9 Dragons',
  },
  {
    id: 3,
    img: '/images/laurence.png',
    title:
      'MarketX Ventures helped us expand to Asia in no time, by connecting us with strategic investors in the Asian market.',
    details:
      'MarketX Ventures helped us achieve excellent results in 2 weeks which would have taken our BD team 8 months!',
    name: 'Laurence Kemball-Cook',
    position: 'CEO of Pavegen',
  },
  {
    id: 4,
    img: '/images/cheng_lu.png',
    title:
      'MarketX Ventures has been a dynamic investor in global pre-IPO deals.',
    details:
      'Not only can you get access to deals in the US and China, MarketX Ventures helps you vet deals in LatAm, Africa, and Southeast Asia!',
    name: 'Cheng Lu',
    position: 'CEO of TuSimple',
  },
]

export const WhyMarketX = () => {
  const settings = {
    dots: true,
    fade: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    arrows: false,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  }

  return (
    <div className="mt-12 bg-primary-background text-white">
      <div className="mx-auto px-6 py-10 sm:p-16 xl:w-3/4">
        <div className="flex flex-row justify-center px-4 lg:mx-5 lg:justify-start lg:pb-14 lg:pt-10">
          <p className="w-fit border-b-2 border-solid pb-3 text-center text-2xl md:text-4xl">
            Why MarketX Ventures?
          </p>
        </div>
        {/* @ts-ignore */}
        <Slider className="xl:pb-16" {...settings} dotsClass="slick-dots">
          {Options.map(item => (
            <div key={item.id}>
              <div className="flex w-full flex-col items-center lg:flex-row lg:items-start lg:justify-between">
                <div className="w-2/3 px-5 py-10 text-center lg:w-1/3 lg:py-0">
                  <ImageKit
                    alt="hero-image"
                    src={item.img}
                    blurDataURL=""
                    height={354}
                    width={354}
                  />
                </div>
                <div className="pl-3 sm:pb-10 lg:w-2/3 ">
                  <h2 className="font-caveat text-3xl font-normal leading-[1.3em] sm:text-4xl xl:text-5xl">
                    &lsquo;{item.title}&rsquo;
                  </h2>
                  <p className="py-6 font-light italic sm:py-16 sm:text-2xl sm:leading-10">
                    &ldquo;{item.details}&rdquo;
                  </p>
                  <h4 className="pb-0.5 text-xl font-bold">{item.name}</h4>
                  <h4 className="text-md uppercase">{item.position}</h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
