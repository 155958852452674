import { IGlobal } from '@types'
import { queryGlobal } from '@queries'
import { getGraphQLData } from '../../database/graphQL/apollo'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { Mixpanel, setItem, getItem } from '@utils'

export const GlobalBanner = ({
  globalBanner,
  setGlobalBanner,
}: {
  globalBanner: IGlobal
  setGlobalBanner: Function
}) => {
  const globalBannerTrackEvent = () => {
    Mixpanel.track('Global-banner-event', {
      parameter:
        globalBanner?.topBannerText + ' ' + globalBanner?.topBannerLink,
    })
  }

  const loadGlobal = async () => {
    const response = await getGraphQLData<{ global: IGlobal }>(queryGlobal)

    return response?.global
  }

  useEffect(() => {
    ;(async function () {
      const data = await loadGlobal()

      if (data && getItem('lastClosedBanner') !== data.topBannerText) {
        setGlobalBanner(data)
      }
    })()
  }, [setGlobalBanner])

  return (
    <div
      className={`${
        globalBanner?.topBannerVisible ? 'h-20 lg:h-12' : 'h-0 overflow-hidden'
      }  flex w-full flex-col items-center justify-center bg-primary transition-all lg:flex-row`}>
      <div className="relative mx-auto w-11/12 lg:w-10/12">
        <a
          onClick={() => globalBannerTrackEvent()}
          href={`${globalBanner.topBannerLink}`}
          rel="noreferrer"
          target="_blank">
          <p className="mb-0 text-center text-xs font-semibold text-white md:text-base">
            {globalBanner?.topBannerText}
          </p>
        </a>
        <div className="relative bottom-0 right-0 top-0 flex cursor-pointer justify-center lg:absolute">
          <a
            onClick={() => globalBannerTrackEvent()}
            href={`${globalBanner.topBannerLink}`}
            rel="noreferrer"
            target="_blank">
            <button className="text-xs text-white md:text-sm">
              View Details
            </button>
          </a>
          <div
            className="ml-2 flex items-center text-white"
            onClick={() => {
              setItem('lastClosedBanner', globalBanner.topBannerText)
              setGlobalBanner({})
            }}>
            <XMarkIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  )
}
