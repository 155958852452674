import { XCircleIcon } from '@heroicons/react/24/outline'
import { getImageUrl } from 'utils/getImageUrl'

interface FileUploadProgressBarProps {
  name: string
  onDelete?: Function
  fileIndex: number
  progress?: number
  url?: string
}

export const FileUploadProgressBar = ({
  name,
  onDelete,
  fileIndex,
  progress,
  url,
}: FileUploadProgressBarProps) => {
  let completed = progress === 100
  return (
    <div>
      {completed ? (
        <a
          className="text-xs text-blue-500"
          href={url}
          target="_blank"
          rel="noreferrer">
          {name}
        </a>
      ) : (
        <span className="text-xs text-gray-400">{name}</span>
      )}

      <div className="flex items-center">
        <div className="h-2 w-[95%] rounded bg-gray-100 ">
          <div
            className={`${
              !completed ? 'animate-progress' : ''
            } h-2 rounded bg-primary`}
          />
        </div>
        {completed ? (
          <XCircleIcon
            onClick={() => {
              if (onDelete) {
              }
            }}
            className={`${
              !completed ? 'opacity-0' : ''
            } ml-2 h-4 w-4 cursor-pointer text-primary`}
          />
        ) : (
          <img
            src={getImageUrl({
              src: '/images/loader.svg',
            })}
            className="ml-2 h-4 w-4"
            alt="loading"
          />
        )}
      </div>
    </div>
  )
}
