import { Table } from '@components/Table'
import { abbreviateNumber, standardDate } from '@utils'
import { useInView } from 'react-intersection-observer'
import { IDatoCmsFundingRound } from '../../types/datoCmsTypes'

export const CompanyFundingRounds = ({
  fundingRounds,
  setActive,
}: {
  fundingRounds: IDatoCmsFundingRound[]
  setActive?: Function
}) => {
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 0.5,
    onChange: inView => {
      if (inView && setActive) {
        setActive('fundingRounds')
      }
    },
  })
  return (
    <div
      className="mb-4 mt-12 w-full border-t border-gray-200 pt-8"
      ref={ref}
      id="fundingRounds">
      <p className="mb-8 font-extrabold text-primary md:text-lg">
        Funding Rounds
      </p>
      <Table
        className="mt-8 w-full overflow-x-auto rounded-xl border"
        data={fundingRounds}
        columns={[
          {
            name: 'Round',
            accessor: 'title',
          },
          {
            name: 'Date',
            accessor: 'date',
            render: value => {
              return value ? `${standardDate(value, true)}` : '--'
            },
          },
          {
            name: 'Valuation',
            accessor: 'valuation',
            render: value => {
              return value ? `${abbreviateNumber(value)}` : '--'
            },
            align: 'right',
          },
          {
            name: 'Amount Raised',
            accessor: 'amountRaised',
            className: 'hidden md:block',
            render: value => {
              return value ? `${abbreviateNumber(value)}` : '--'
            },
            align: 'right',
          },
        ]}
      />
    </div>
  )
}
