import classNames from 'classnames'
import React from 'react'

export const Chevron: React.FC<{
  size?: string
  fill?: string
  direction?: 'left' | 'right' | 'up' | 'down'
}> = ({
  size = 'w-10 h-10',
  fill = 'fill-mx-dark-purple',
  direction = 'up',
}) => {
  return (
    <span
      className={classNames('flex items-center justify-center', size, {
        'rotate-180 transform': direction === 'down',
        '-rotate-90 transform': direction === 'left',
        'rotate-90 transform': direction === 'right',
      })}>
      <svg
        width="auto"
        height="auto"
        viewBox="0 0 39 19"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.00991824 16.4994C0.396721 17.4454 1.32902 18.0213 2.18197 18.6383C2.87623 19.1401 3.99697 19.0908 4.72099 18.6136C4.8896 18.4984 5.03837 18.375 5.19706 18.2516C9.83871 14.3851 14.5002 10.5268 19.1518 6.66024C19.2608 6.56975 19.3303 6.45457 19.4394 6.3394C19.6179 6.47925 19.727 6.56975 19.8361 6.65201C24.5174 10.5432 29.2087 14.4345 33.8999 18.3257C34.733 19.0167 35.7645 19.2306 36.6075 18.7123C37.3811 18.2352 38.0754 17.6429 38.6606 17.0094C39.216 16.4171 39.0474 15.6109 38.4622 15.0186C38.3729 14.9281 38.2837 14.8458 38.1845 14.7635C32.5213 10.0661 26.8482 5.36042 21.185 0.662983C20.332 -0.044512 19.3501 -0.192593 18.4277 0.25165C18.1996 0.358597 18.0013 0.514902 17.8128 0.671209C12.1397 5.36042 6.46657 10.0579 0.813282 14.7718C0.466148 15.0597 0.267788 15.4628 3.80604e-08 15.8083C0.00991825 16.0387 0.00991825 16.269 0.00991824 16.4994Z"
          className={fill}
        />
      </svg>
    </span>
  )
}
