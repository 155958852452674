import { ImageKit } from '@components'
import { IDatoCmsTeamMember } from '@types'

export const CompanyTeam = ({
  team,
  list,
  grayAvatar,
}: {
  team?: IDatoCmsTeamMember[]
  list?: boolean
  grayAvatar?: boolean
}) =>
  team ? (
    <div className="mb-4 mt-4 block w-full">
      <p
        className={` ${
          list
            ? 'text-lg font-semibold'
            : ' font-extrabold text-primary md:text-lg'
        }  mb-4 border-b  border-gray-200 pb-3`}>
        Executive Members
      </p>
      <div
        className={
          list ? `flex flex-col` : `grid grid-cols-1 gap-6 sm:grid-cols-2`
        }>
        {team.map((member, idx) => {
          return (
            <div key={idx} className="group flex w-full border-b pb-2 pt-2">
              <div className="h-10 w-10 min-w-[2.5rem] overflow-clip rounded-full border border-gray-200">
                {member.photo ? (
                  <ImageKit
                    alt={member.name}
                    src={member.photo.url}
                    width={40}
                    height={40}
                    className={
                      grayAvatar
                        ? `grayscale group-hover:grayscale-0`
                        : `grayscale-0`
                    }
                    placeholder="blur"
                    blurDataURL={member.photo.blurUpThumb}
                  />
                ) : (
                  <p className="flex h-full w-full items-center justify-center bg-gradient-to-br from-themeBlack-light to-themeBlack-dark text-xl font-bold uppercase leading-none text-white">
                    {member.name.charAt(0)}
                  </p>
                )}
              </div>
              <div className="ml-2 w-full">
                <p className="text-sm font-semibold">{member.name}</p>
                <p className="my-2 text-xs text-gray-600">{member.title}</p>
                {member.linkedinUrl && (
                  <a
                    href={`${member.linkedinUrl}`}
                    target="_blank"
                    rel="noreferrer">
                    <ImageKit
                      alt="Tooltip image"
                      src="/images/linkedin.svg"
                      height={16}
                      width={16}
                    />
                  </a>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  ) : null
