import ContentLoader from 'react-content-loader'
export const InvestmentCardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={380}
    backgroundColor="#F3F3F3"
    foregroundColor="#ECEBEB"
    {...props}>
    <rect x="4" y="78" rx="5" ry="5" width="220" height="10" />
    <rect x="6" y="118" rx="5" ry="5" width="220" height="10" />
    <rect x="8" y="156" rx="5" ry="5" width="220" height="10" />
    <rect x="7" y="200" rx="5" ry="5" width="220" height="10" />
    <rect x="8" y="2" rx="0" ry="0" width="43" height="39" />
    <rect x="5" y="290" rx="5" ry="5" width="220" height="10" />
    <rect x="4" y="246" rx="5" ry="5" width="220" height="10" />
    <rect x="6" y="336" rx="5" ry="5" width="220" height="10" />
    <rect x="62" y="28" rx="5" ry="5" width="125" height="6" />
    <rect x="61" y="14" rx="5" ry="5" width="106" height="5" />
    <rect x="600" y="75" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="117" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="158" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="200" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="244" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="291" rx="5" ry="5" width="90" height="9" />
    <rect x="600" y="335" rx="5" ry="5" width="90" height="9" />
  </ContentLoader>
)

export const InvestmentProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={380}
    viewBox="0 0 400 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <circle cx="55" cy="66" r="8" />
    <rect x="70" y="61" rx="5" ry="5" width="220" height="10" />
    <circle cx="55" cy="109" r="8" />
    <rect x="70" y="104" rx="5" ry="5" width="220" height="10" />
    <circle cx="55" cy="149" r="8" />
    <rect x="70" y="144" rx="5" ry="5" width="220" height="10" />
    <circle cx="55" cy="189" r="8" />
    <rect x="70" y="185" rx="5" ry="5" width="220" height="10" />
    <rect x="6" y="5" rx="0" ry="0" width="38" height="33" />
    <rect x="51" y="20" rx="5" ry="5" width="95" height="7" />
    <circle cx="55" cy="235" r="8" />
    <rect x="70" y="230" rx="5" ry="5" width="220" height="10" />
    <circle cx="55" cy="278" r="8" />
    <rect x="70" y="273" rx="5" ry="5" width="220" height="10" />
    <circle cx="55" cy="318" r="8" />
    <rect x="70" y="313" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

export const InvestmentDocumentsLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={400}
    height={380}
    viewBox="0 0 400 380"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="6" y="5" rx="0" ry="0" width="38" height="33" />
    <rect x="51" y="26" rx="5" ry="5" width="95" height="7" />
    <rect x="52" y="15" rx="5" ry="5" width="77" height="6" />
    <rect x="60" y="64" rx="0" ry="0" width="30" height="28" />
    <rect x="99" y="82" rx="5" ry="5" width="181" height="7" />
    <rect x="100" y="72" rx="5" ry="5" width="77" height="6" />
    <circle cx="108" cy="121" r="8" />
    <rect x="123" y="117" rx="5" ry="5" width="220" height="10" />
    <circle cx="110" cy="156" r="8" />
    <rect x="124" y="152" rx="5" ry="5" width="220" height="10" />
    <circle cx="111" cy="194" r="8" />
    <rect x="126" y="190" rx="5" ry="5" width="220" height="10" />
    <rect x="64" y="227" rx="0" ry="0" width="30" height="28" />
    <rect x="103" y="245" rx="5" ry="5" width="181" height="7" />
    <rect x="104" y="235" rx="5" ry="5" width="77" height="6" />
    <circle cx="112" cy="284" r="8" />
    <rect x="127" y="280" rx="5" ry="5" width="220" height="10" />
    <circle cx="114" cy="319" r="8" />
    <rect x="128" y="315" rx="5" ry="5" width="220" height="10" />
    <circle cx="115" cy="357" r="8" />
    <rect x="130" y="353" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

export const TableLoader = (props: any) => (
  <ContentLoader
    width="100%"
    height={600}
    viewBox="0 0 1000 550"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}>
    <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />
    <circle cx="879" cy="123" r="11" />
    <circle cx="914" cy="123" r="11" />
    <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
    <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
    <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />
    <circle cx="880" cy="184" r="11" />
    <circle cx="915" cy="184" r="11" />
    <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
    <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
    <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
    <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />
    <circle cx="881" cy="242" r="11" />
    <circle cx="916" cy="242" r="11" />
    <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />
    <circle cx="882" cy="303" r="11" />
    <circle cx="917" cy="303" r="11" />
    <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
    <rect x="58" y="335" rx="3" ry="3" width="897" height="2" />
    <circle cx="881" cy="363" r="11" />
    <circle cx="916" cy="363" r="11" />
    <rect x="106" y="355" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="354" rx="3" ry="3" width="141" height="15" />
    <rect x="57" y="395" rx="3" ry="3" width="897" height="2" />
    <circle cx="882" cy="424" r="11" />
    <circle cx="917" cy="424" r="11" />
    <rect x="107" y="416" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="415" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="453" rx="3" ry="3" width="897" height="2" />
    <rect x="51" y="49" rx="3" ry="3" width="2" height="465" />
    <rect x="955" y="49" rx="3" ry="3" width="2" height="465" />
    <circle cx="882" cy="484" r="11" />
    <circle cx="917" cy="484" r="11" />
    <rect x="107" y="476" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="475" rx="3" ry="3" width="141" height="15" />
    <rect x="55" y="513" rx="3" ry="3" width="897" height="2" />
    <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
    <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
    <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
    <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
    <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
    <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />
  </ContentLoader>
)

export const CardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={300}
    viewBox="0 0 500 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="66" y="42" rx="0" ry="0" width="400" height="4" />
    <rect x="61" y="42" rx="0" ry="0" width="6" height="105" />
    <rect x="55" y="100" rx="0" ry="0" width="1" height="3" />
    <rect x="460" y="45" rx="0" ry="0" width="6" height="105" />
    <rect x="61" y="147" rx="0" ry="0" width="400" height="4" />
    <rect x="170" y="72" rx="0" ry="0" width="193" height="7" />
    <rect x="225" y="102" rx="0" ry="0" width="98" height="27" />
  </ContentLoader>
)

export const InviteLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#464a50"
    {...props}>
    <rect x="28" y="25" rx="5" ry="5" width="140" height="5" />
    <rect x="28" y="45" rx="5" ry="5" width="140" height="5" />
    <rect x="28" y="65" rx="5" ry="5" width="140" height="5" />
    <rect x="28" y="85" rx="5" ry="5" width="140" height="5" />
    <rect x="28" y="115" rx="5" ry="5" width="53" height="4" />
    <rect x="217" y="18" rx="0" ry="0" width="152" height="119" />
  </ContentLoader>
)

export const DealsProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={350}
    viewBox="0 0 400 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="85" height="19" />
    <rect x="0" y="30" rx="0" ry="0" width="465" height="32" />
  </ContentLoader>
)

export const InvestmentSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={250}
    viewBox="0 0 100% 324"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="5" rx="0" ry="0" width="100%" height="200" />
  </ContentLoader>
)

export const InvestmentAmountSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={324}
    viewBox="0 0 475 324"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="329" y="192" rx="0" ry="0" width="1" height="5" />
    <rect x="318" y="192" rx="0" ry="0" width="1" height="1" />
    <rect x="2" y="2" rx="0" ry="0" width="146" height="8" />
    <rect x="-2" y="23" rx="0" ry="0" width="323" height="34" />
    <rect x="2" y="65" rx="0" ry="0" width="143" height="9" />
    <circle cx="11" cy="117" r="10" />
    <rect x="83" y="172" rx="0" ry="0" width="2" height="1" />
    <rect x="38" y="105" rx="0" ry="0" width="137" height="7" />
    <rect x="37" y="124" rx="0" ry="0" width="137" height="7" />
    <circle cx="11" cy="173" r="10" />
    <rect x="40" y="161" rx="0" ry="0" width="137" height="7" />
    <rect x="39" y="180" rx="0" ry="0" width="137" height="7" />
    <circle cx="11" cy="230" r="10" />
    <rect x="42" y="218" rx="0" ry="0" width="137" height="7" />
    <rect x="41" y="237" rx="0" ry="0" width="137" height="7" />
    <rect x="50" y="271" rx="0" ry="0" width="96" height="31" />
    <rect x="163" y="270" rx="0" ry="0" width="130" height="31" />
    <rect x="40" y="201" rx="0" ry="0" width="286" height="5" />
  </ContentLoader>
)

export const InvestmentSuitabilityAttestationSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={475}
    height={474}
    viewBox="0 0 475 474"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="329" y="192" rx="0" ry="0" width="1" height="5" />
    <rect x="318" y="192" rx="0" ry="0" width="1" height="1" />
    <rect x="83" y="172" rx="0" ry="0" width="2" height="1" />
    <rect x="2" y="5" rx="0" ry="0" width="444" height="8" />
    <rect x="1" y="22" rx="0" ry="0" width="444" height="8" />
    <rect x="2" y="38" rx="0" ry="0" width="444" height="8" />
    <rect x="2" y="73" rx="0" ry="0" width="214" height="7" />
    <circle cx="19" cy="112" r="13" />
    <rect x="44" y="106" rx="0" ry="0" width="287" height="11" />
    <circle cx="20" cy="151" r="13" />
    <rect x="41" y="143" rx="0" ry="0" width="356" height="14" />
    <circle cx="20" cy="196" r="13" />
    <rect x="43" y="190" rx="0" ry="0" width="366" height="11" />
    <circle cx="19" cy="245" r="13" />
    <rect x="42" y="239" rx="0" ry="0" width="366" height="11" />
    <circle cx="18" cy="291" r="13" />
    <rect x="41" y="285" rx="0" ry="0" width="366" height="11" />
    <circle cx="19" cy="334" r="13" />
    <rect x="42" y="328" rx="0" ry="0" width="366" height="11" />
    <rect x="5" y="369" rx="0" ry="0" width="26" height="28" />
    <rect x="41" y="375" rx="0" ry="0" width="366" height="11" />
    <rect x="8" y="427" rx="0" ry="0" width="88" height="36" />
    <rect x="108" y="427" rx="0" ry="0" width="88" height="36" />
  </ContentLoader>
)

export const InvestmentInvestorDetailsSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={475}
    height={550}
    viewBox="0 0 475 550"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="329" y="192" rx="0" ry="0" width="1" height="5" />
    <rect x="318" y="192" rx="0" ry="0" width="1" height="1" />
    <rect x="83" y="172" rx="0" ry="0" width="2" height="1" />
    <rect x="143" y="150" rx="0" ry="0" width="0" height="1" />
    <rect x="229" y="290" rx="0" ry="0" width="0" height="2" />
    <rect x="4" y="11" rx="0" ry="0" width="114" height="6" />
    <rect x="3" y="31" rx="0" ry="0" width="187" height="32" />
    <rect x="285" y="8" rx="0" ry="0" width="114" height="6" />
    <rect x="284" y="28" rx="0" ry="0" width="187" height="32" />
    <rect x="4" y="100" rx="0" ry="0" width="114" height="6" />
    <rect x="3" y="120" rx="0" ry="0" width="460" height="32" />
    <rect x="5" y="160" rx="0" ry="0" width="460" height="32" />
    <rect x="2" y="220" rx="0" ry="0" width="114" height="6" />
    <rect x="1" y="240" rx="0" ry="0" width="187" height="32" />
    <rect x="283" y="217" rx="0" ry="0" width="114" height="6" />
    <rect x="282" y="237" rx="0" ry="0" width="187" height="32" />
    <rect x="3" y="300" rx="0" ry="0" width="114" height="6" />
    <rect x="2" y="320" rx="0" ry="0" width="187" height="32" />
    <rect x="284" y="297" rx="0" ry="0" width="114" height="6" />
    <rect x="283" y="317" rx="0" ry="0" width="187" height="32" />
    <rect x="6" y="381" rx="0" ry="0" width="114" height="6" />
    <rect x="5" y="401" rx="0" ry="0" width="187" height="32" />
    <rect x="287" y="378" rx="0" ry="0" width="114" height="6" />
    <rect x="286" y="398" rx="0" ry="0" width="187" height="32" />
    <rect x="3" y="457" rx="0" ry="0" width="465" height="63" />
  </ContentLoader>
)

export const InvestmentSignSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={475}
    height={200}
    viewBox="0 0 475 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="329" y="192" rx="0" ry="0" width="1" height="5" />
    <rect x="318" y="192" rx="0" ry="0" width="1" height="1" />
    <rect x="83" y="172" rx="0" ry="0" width="2" height="1" />
    <rect x="143" y="150" rx="0" ry="0" width="0" height="1" />
    <rect x="229" y="290" rx="0" ry="0" width="0" height="2" />
    <rect x="7" y="63" rx="0" ry="0" width="457" height="6" />
    <rect x="7" y="64" rx="0" ry="0" width="6" height="109" />
    <rect x="8" y="168" rx="0" ry="0" width="455" height="6" />
    <rect x="460" y="66" rx="0" ry="0" width="5" height="108" />
    <rect x="152" y="16" rx="0" ry="0" width="168" height="9" />
    <rect x="151" y="92" rx="0" ry="0" width="186" height="9" />
    <rect x="153" y="134" rx="0" ry="0" width="186" height="9" />
  </ContentLoader>
)

export const InvestmentCommitmentSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={475}
    height={230}
    viewBox="0 0 475 230"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="329" y="192" rx="0" ry="0" width="1" height="5" />
    <rect x="318" y="192" rx="0" ry="0" width="1" height="1" />
    <rect x="83" y="172" rx="0" ry="0" width="2" height="1" />
    <rect x="134" y="32" rx="0" ry="0" width="226" height="10" />
    <rect x="7" y="69" rx="0" ry="0" width="469" height="12" />
    <rect x="143" y="150" rx="0" ry="0" width="0" height="1" />
    <rect x="78" y="93" rx="0" ry="0" width="339" height="11" />
    <rect x="107" y="152" rx="0" ry="0" width="130" height="43" />
    <rect x="229" y="290" rx="0" ry="0" width="0" height="2" />
    <rect x="252" y="153" rx="0" ry="0" width="130" height="43" />
  </ContentLoader>
)

export const OnBoardingProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={350}
    viewBox="0 0 500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="9" y="4" rx="0" ry="0" width="250" height="22" />
    <rect x="9" y="64" rx="0" ry="0" width="200" height="22" />
    <rect x="9" y="128" rx="0" ry="0" width="530" height="70" />
    <rect x="9" y="250" rx="0" ry="0" width="85" height="19" />
    <rect x="9" y="280" rx="0" ry="0" width="200" height="32" />
    <rect x="230" y="250" rx="0" ry="0" width="85" height="19" />
    <rect x="230" y="280" rx="0" ry="0" width="200" height="32" />
    <rect x="9" y="350" rx="0" ry="0" width="95" height="40" />
  </ContentLoader>
)

export const OnBoardingWhatBestDescribeYouProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={350}
    viewBox="0 0 950 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="300" height="22" />
    <rect x="0" y="64" rx="0" ry="0" width="250" height="22" />
    <rect x="34" y="180" rx="6" ry="6" width="500" height="12" />
    <rect x="34" y="230" rx="6" ry="6" width="800" height="12" />
    <rect x="34" y="380" rx="6" ry="6" width="500" height="12" />
    <rect x="34" y="430" rx="6" ry="6" width="800" height="12" />
    <rect x="34" y="580" rx="6" ry="6" width="500" height="12" />
    <rect x="34" y="630" rx="6" ry="6" width="800" height="12" />
    <rect x="34" y="780" rx="6" ry="6" width="500" height="12" />
    <rect x="34" y="830" rx="6" ry="6" width="800" height="12" />
  </ContentLoader>
)

export const OnBoardingAccreditedInvestorProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 950 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="300" height="22" />
    <rect x="0" y="64" rx="0" ry="0" width="250" height="22" />
    <rect x="0" y="128" rx="0" ry="0" width="900" height="150" />
    <circle cx="40" cy="425" r="30" />
    <rect x="100" y="390" rx="6" ry="6" width="800" height="15" />
    <rect x="100" y="450" rx="6" ry="6" width="800" height="15" />
    <circle cx="40" cy="575" r="30" />
    <rect x="100" y="540" rx="6" ry="6" width="800" height="15" />
    <rect x="100" y="600" rx="6" ry="6" width="800" height="15" />
    <circle cx="40" cy="725" r="30" />
    <rect x="100" y="690" rx="6" ry="6" width="800" height="15" />
    <rect x="100" y="750" rx="6" ry="6" width="800" height="15" />
  </ContentLoader>
)

export const OnBoardingInvestmentExperienceProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 950 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="400" height="22" />
    <rect x="0" y="80" rx="3" ry="3" width="900" height="24" />
    <rect x="0" y="120" rx="3" ry="3" width="900" height="24" />
    <rect x="0" y="250" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="290" rx="3" ry="3" width="850" height="60" />
    <rect x="0" y="450" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="490" rx="3" ry="3" width="850" height="60" />
    <rect x="0" y="650" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="690" rx="3" ry="3" width="850" height="60" />
  </ContentLoader>
)

export const OnBoardingGetToKnowProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 950 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="400" height="22" />
    <rect x="0" y="80" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="120" rx="3" ry="3" width="850" height="120" />
    <rect x="0" y="290" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="330" rx="3" ry="3" width="850" height="60" />
    <rect x="0" y="450" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="490" rx="3" ry="3" width="850" height="60" />
    <rect x="0" y="650" rx="3" ry="3" width="500" height="20" />
    <rect x="0" y="690" rx="3" ry="3" width="850" height="60" />
  </ContentLoader>
)

export const OnBoardingWelcomeProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 1150 1150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="600" height="22" />
    <rect x="0" y="120" rx="3" ry="3" width="1200" height="20" />
    <rect x="0" y="180" rx="3" ry="3" width="1200" height="20" />
    <rect x="0" y="240" rx="3" ry="3" width="1200" height="20" />
    <rect x="0" y="400" rx="0" ry="0" width="350" height="100" />
  </ContentLoader>
)

export const OnBoardingShareHolderProgressLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 950 900"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="400" height="24" />
    <rect x="0" y="100" rx="3" ry="3" width="900" height="24" />
    <rect x="0" y="200" rx="3" ry="3" width="900" height="20" />
    <rect x="0" y="250" rx="3" ry="3" width="900" height="20" />
    <rect x="0" y="400" rx="3" ry="3" width="900" height="80" />
    <rect x="0" y="600" rx="3" ry="3" width="420" height="80" />
    <rect x="480" y="600" rx="3" ry="3" width="420" height="80" />
    <rect x="0" y="800" rx="3" ry="3" width="900" height="20" />
  </ContentLoader>
)

export const InvestmentPaymentSectionLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={475}
    height={200}
    viewBox="0 0 475 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="7" y="63" rx="0" ry="0" width="457" height="6" />
    <rect x="7" y="64" rx="0" ry="0" width="6" height="109" />
    <rect x="8" y="168" rx="0" ry="0" width="455" height="6" />
    <rect x="460" y="66" rx="0" ry="0" width="5" height="108" />
    <rect x="153" y="92" rx="0" ry="0" width="186" height="9" />
    <rect x="153" y="134" rx="0" ry="0" width="186" height="9" />
  </ContentLoader>
)

export const InvestmentWirePaymentLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={500}
    height={250}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="62" y="22" rx="5" ry="5" width="231" height="10" />
    <rect x="8" y="64" rx="5" ry="5" width="120" height="7" />
    <rect x="228" y="64" rx="5" ry="5" width="120" height="7" />
    <rect x="8" y="103" rx="5" ry="5" width="120" height="7" />
    <rect x="228" y="103" rx="5" ry="5" width="120" height="7" />
    <rect x="8" y="140" rx="5" ry="5" width="120" height="7" />
    <rect x="228" y="140" rx="5" ry="5" width="120" height="7" />
  </ContentLoader>
)

export const ProfilesLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={180}
    viewBox="0 0 576 124"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="10" y="12" rx="0" ry="0" width="130" height="8" />
    <rect x="10" y="50" rx="0" ry="0" width="80" height="4" />
    <rect x="250" y="50" rx="0" ry="0" width="80" height="4" />
    <rect x="360" y="50" rx="0" ry="0" width="80" height="4" />
    <rect x="250" y="70" rx="0" ry="0" width="80" height="4" />
    <rect x="360" y="70" rx="0" ry="0" width="80" height="4" />
    <rect x="120" y="70" rx="0" ry="0" width="80" height="4" />
    <rect x="120" y="90" rx="0" ry="0" width="80" height="4" />
    <rect x="10" y="110" rx="0" ry="0" width="80" height="4" />
    <rect x="120" y="110" rx="0" ry="0" width="80" height="4" />
    <rect x="10" y="70" rx="0" ry="0" width="80" height="4" />
    <rect x="10" y="90" rx="0" ry="0" width="80" height="4" />
    <rect x="120" y="50" rx="0" ry="0" width="80" height="4" />
    <rect x="250" y="90" rx="0" ry="0" width="80" height="4" />
    <rect x="250" y="110" rx="0" ry="0" width="80" height="4" />
    <rect x="360" y="90" rx="0" ry="0" width="80" height="4" />
    <rect x="360" y="110" rx="0" ry="0" width="80" height="4" />
  </ContentLoader>
)
