import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface DateInputProps {
  label: string
  value: Date | null
  onChange: Function
  description?: string
  required?: boolean
  isPastDate?: boolean
}
export const DateInput = ({
  label,
  value,
  onChange,
  description,
  required,
  isPastDate = false,
}: DateInputProps) => {
  return (
    <div>
      <label className="mb-1 block text-base font-medium text-themeBlack">
        {label}
        {required && <sup className="text-red-500">*</sup>}
      </label>

      <DatePicker
        minDate={isPastDate ? null : new Date()}
        className="block w-full rounded border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        selected={value}
        onChange={(date: Date) => onChange(date)}
        required={required}
      />
      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]">{description}</p>
      )}
    </div>
  )
}
