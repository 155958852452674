import { Button, AppLayout, Container } from '@components'
import TabNavigation from '@components/TabNavigation/TabNavigation'
import { AuthContext } from '../../context/auth'
import Link from 'next/link'
import { useContext } from 'react'
import { adminRoutes } from '../../routes'

export const AdminLayout = ({
  children,
  showNavigation = true,
  showBanner = true,
}: {
  children?: any
  showNavigation?: boolean
  showBanner?: boolean
}) => {
  const authContext = useContext(AuthContext)

  return authContext.authenticated ? (
    <AppLayout showNavigation={showNavigation} showBanner={showBanner}>
      <Container>
        <TabNavigation routes={adminRoutes} />
        <div>
          {children || (
            <div className="py-4">
              <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" />
            </div>
          )}
        </div>
      </Container>
    </AppLayout>
  ) : (
    <Link legacyBehavior href="/login">
      <a>
        <Button>Sign in to view</Button>
      </a>
    </Link>
  )
}
