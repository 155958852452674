export const DashboardIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72909 3.20831C3.20825 3.20831 3.20825 3.39898 3.20825 5.72915V5.75206C3.20825 6.76681 3.20825 7.50015 3.45667 7.80998C3.69959 8.11065 4.421 8.24998 5.72909 8.24998C7.03717 8.24998 7.75859 8.10973 8.0015 7.80906C8.24992 7.50015 8.24992 6.76681 8.24992 5.75115C8.24992 3.39898 8.24992 3.20831 5.72909 3.20831ZM5.72909 9.62498C4.18359 9.62498 3.024 9.46273 2.38692 8.67165C1.83325 7.98506 1.83325 7.04823 1.83325 5.75206L2.52075 5.72915H1.83325C1.83325 3.09831 1.99917 1.83331 5.72909 1.83331C9.459 1.83331 9.62492 3.09831 9.62492 5.72915C9.62492 7.04731 9.62492 7.98506 9.07125 8.67165C8.43417 9.46273 7.27459 9.62498 5.72909 9.62498Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8123 3.20831C13.2915 3.20831 13.2915 3.39898 13.2915 5.72915V5.75206C13.2915 6.76681 13.2915 7.50015 13.5399 7.80998C13.7828 8.11065 14.5043 8.24998 15.8123 8.24998C17.1204 8.24998 17.8418 8.10973 18.0848 7.80906C18.3332 7.50015 18.3332 6.76681 18.3332 5.75115C18.3332 3.39898 18.3332 3.20831 15.8123 3.20831ZM15.8123 9.62498C14.2668 9.62498 13.1073 9.46273 12.4702 8.67165C11.9165 7.98506 11.9165 7.04823 11.9165 5.75206L12.604 5.72915H11.9165C11.9165 3.09831 12.0824 1.83331 15.8123 1.83331C19.5423 1.83331 19.7082 3.09831 19.7082 5.72915C19.7082 7.04731 19.7082 7.98506 19.1545 8.67165C18.5174 9.46273 17.3578 9.62498 15.8123 9.62498Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72909 13.2916C3.20825 13.2916 3.20825 13.4823 3.20825 15.8125V15.8354C3.20825 16.8501 3.20825 17.5835 3.45667 17.8933C3.69959 18.194 4.421 18.3333 5.72909 18.3333C7.03717 18.3333 7.75859 18.193 8.0015 17.8924C8.24992 17.5835 8.24992 16.8501 8.24992 15.8345C8.24992 13.4823 8.24992 13.2916 5.72909 13.2916ZM5.72909 19.7083C4.18359 19.7083 3.024 19.546 2.38692 18.755C1.83325 18.0684 1.83325 17.1315 1.83325 15.8354L2.52075 15.8125H1.83325C1.83325 13.1816 1.99917 11.9166 5.72909 11.9166C9.459 11.9166 9.62492 13.1816 9.62492 15.8125C9.62492 17.1306 9.62492 18.0684 9.07125 18.755C8.43417 19.546 7.27459 19.7083 5.72909 19.7083Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8123 13.2916C13.2915 13.2916 13.2915 13.4823 13.2915 15.8125V15.8354C13.2915 16.8501 13.2915 17.5835 13.5399 17.8933C13.7828 18.194 14.5043 18.3333 15.8123 18.3333C17.1204 18.3333 17.8418 18.193 18.0848 17.8924C18.3332 17.5835 18.3332 16.8501 18.3332 15.8345C18.3332 13.4823 18.3332 13.2916 15.8123 13.2916ZM15.8123 19.7083C14.2668 19.7083 13.1073 19.546 12.4702 18.755C11.9165 18.0684 11.9165 17.1315 11.9165 15.8354L12.604 15.8125H11.9165C11.9165 13.1816 12.0824 11.9166 15.8123 11.9166C19.5423 11.9166 19.7082 13.1816 19.7082 15.8125C19.7082 17.1306 19.7082 18.0684 19.1545 18.755C18.5174 19.546 17.3578 19.7083 15.8123 19.7083Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const FundFormationIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g>
        <path
          d="M7.94922 13.1357C7.94922 14.3182 8.85672 15.2715 9.98422 15.2715H12.2851C13.2659 15.2715 14.0634 14.4374 14.0634 13.4107C14.0634 12.2924 13.5776 11.8982 12.8534 11.6415L9.15922 10.3582C8.43505 10.1015 7.94922 9.70738 7.94922 8.58904C7.94922 7.56238 8.74672 6.72821 9.72755 6.72821H12.0284C13.1559 6.72821 14.0634 7.68154 14.0634 8.86404"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 5.5V16.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7499 20.1666H8.24992C3.66659 20.1666 1.83325 18.3333 1.83325 13.75V8.24998C1.83325 3.66665 3.66659 1.83331 8.24992 1.83331H13.7499C18.3333 1.83331 20.1666 3.66665 20.1666 8.24998V13.75C20.1666 18.3333 18.3333 20.1666 13.7499 20.1666Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export const OpportunitiesIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00551 3.20831C4.34351 3.20831 3.20776 4.56131 3.20776 7.73023V14.8793C3.20776 17.798 4.81926 19.4049 7.74526 19.4049H14.8788C17.7974 19.4049 19.4043 17.798 19.4043 14.8793V14.8766L19.43 10.3253C19.43 7.20956 18.3612 5.99498 15.6158 5.99498H13.0216C12.1517 5.99406 11.3212 5.57881 10.7987 4.88398L9.96176 3.77115C9.69868 3.41823 9.27793 3.20923 8.83793 3.20831H7.00551ZM14.8788 20.7799H7.74526C4.09876 20.7799 1.83276 18.5185 1.83276 14.8793V7.73023C1.83276 3.81698 3.57351 1.83331 7.00551 1.83331H8.83885C9.7106 1.83423 10.5411 2.2504 11.0618 2.94615L11.8968 4.05715C12.1618 4.40915 12.5825 4.61906 13.0225 4.61998H15.6158C19.1559 4.61998 20.805 6.43406 20.805 10.329L20.7793 14.8821C20.7784 18.5194 18.5179 20.7799 14.8788 20.7799Z"
            fill="currentColor"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3228 14.4034H7.31567C6.93617 14.4034 6.62817 14.0954 6.62817 13.7159C6.62817 13.3364 6.93617 13.0284 7.31567 13.0284H15.3228C15.7023 13.0284 16.0103 13.3364 16.0103 13.7159C16.0103 14.0954 15.7023 14.4034 15.3228 14.4034Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
export const PortfolioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <g>
        <path
          d="M8.24992 20.1666H13.7499C18.3333 20.1666 20.1666 18.3333 20.1666 13.75V8.24998C20.1666 3.66665 18.3333 1.83331 13.7499 1.83331H8.24992C3.66659 1.83331 1.83325 3.66665 1.83325 8.24998V13.75C1.83325 18.3333 3.66659 20.1666 8.24992 20.1666Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25008 9.16667C9.2626 9.16667 10.0834 8.34585 10.0834 7.33333C10.0834 6.32081 9.2626 5.5 8.25008 5.5C7.23756 5.5 6.41675 6.32081 6.41675 7.33333C6.41675 8.34585 7.23756 9.16667 8.25008 9.16667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.44751 17.3708L6.96668 14.3366C7.69084 13.8508 8.73584 13.9058 9.38668 14.465L9.68918 14.7308C10.4042 15.345 11.5592 15.345 12.2742 14.7308L16.0875 11.4583C16.8025 10.8441 17.9575 10.8441 18.6725 11.4583L20.1667 12.7416"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
export const AdminIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
      <g>
        <path
          d="M11.6534 10.4171C11.5576 10.4075 11.4426 10.4075 11.3372 10.4171C9.05637 10.3404 7.24512 8.47169 7.24512 6.17169C7.24512 3.82377 9.14262 1.91669 11.5001 1.91669C13.848 1.91669 15.7551 3.82377 15.7551 6.17169C15.7455 8.47169 13.9343 10.3404 11.6534 10.4171Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.86169 13.9533C4.54252 15.5058 4.54252 18.0358 6.86169 19.5787C9.49711 21.3421 13.8192 21.3421 16.4546 19.5787C18.7738 18.0262 18.7738 15.4962 16.4546 13.9533C13.8288 12.1996 9.50669 12.1996 6.86169 13.9533Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
