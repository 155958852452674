import React from 'react'
export interface PercentageInputProps {
  // required
  label: string
  onChange: Function
  value: number | null
  name: string

  // optional
  defaultValue?: number
  placeholder?: string
  description?: string
  required?: boolean
  error?: boolean
  min?: number
  max?: number
  disabled?: boolean
}

export const PercentageInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  placeholder,
  description,
  required,
  error,
  min,
  max,
  disabled,
}: PercentageInputProps) => {
  const classess = {
    default:
      'shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded',
    error:
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded',
  }

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-base font-medium text-gray-700">
        {label}
        {required && <sup className="text-red-500">*</sup>}
      </label>

      <div className="relative mt-1">
        <input
          step=".1"
          id={name}
          name={name}
          defaultValue={defaultValue}
          type="number"
          min={min}
          max={max}
          className={error ? classess.error : classess.default}
          onChange={e => onChange(e)}
          value={value || ''}
          required={required}
          placeholder={placeholder}
          aria-describedby={name}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <div className="inline-flex items-center rounded px-2 font-sans text-sm font-medium text-gray-500">
            %
          </div>
        </div>
      </div>

      {description && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
