import { Tooltip, ImageKit } from '@components'

interface ButtonGroupOption {
  label: string
  value: string | boolean | number
  disabled?: boolean
}
interface ButtonGroupProps {
  label?: string
  value: string | boolean | number
  options: ButtonGroupOption[]
  onChange: Function
  description?: string
  tooltip?: string
}

export const ButtonGroup = ({
  value,
  options,
  label,
  onChange,
  description,
  tooltip,
}: ButtonGroupProps) => {
  const classes = {
    default: 'border-gray-300 text-themeBlack hover:bg-gray-50',
    active:
      'z-10 border bg-primary border-primary-dark hover:border-primary-dark text-white',
  }
  return (
    <div>
      <div className="relative flex items-center">
        <label className="mb-1 block text-base font-medium text-themeBlack">
          {label}
        </label>
        {tooltip && (
          <Tooltip position="top" message={tooltip}>
            <div className="mb-0 ml-2 cursor-pointer">
              <ImageKit
                src="/images/question.svg"
                alt="Tooltip image"
                width={16}
                height={16}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <span className="relative z-0 inline-flex rounded-md shadow-sm">
        {options?.map((option, optionIndex) => {
          return (
            <button
              disabled={option.disabled}
              onClick={() => onChange(option.value)}
              key={optionIndex}
              type="button"
              className={`first:rounded-l-md last:rounded-r-md ${
                value === option.value ? classes.active : classes.default
              } relative -ml-px inline-flex items-center border px-2 py-2 text-base font-normal focus:z-10 focus:outline-none md:px-8`}>
              {option.label}
            </button>
          )
        })}
      </span>
      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]">{description}</p>
      )}
    </div>
  )
}
