import { DiscountBadge, ImageKit, Tooltip, Button } from '@components'
import {
  ChartBarSquareIcon,
  TagIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline'
import { abbreviateNumber } from '@utils'
import { useContext, useMemo } from 'react'
import { AuthContext } from '../../context/auth'
import { ICompanyWithDeal } from '@types'

const getValuationDisplay = (
  company: ICompanyWithDeal,
): {
  value: string
  label: string
  tooltip: string
} => {
  if (
    company.shareSource == 'secondary' &&
    company.impliedValuation?.toString() !== '0'
  ) {
    return {
      value: abbreviateNumber(company.impliedValuation),
      label: 'Implied V.',
      tooltip: 'Implied Valuation',
    }
  } else {
    return {
      value: abbreviateNumber(company.valuation),
      label: 'Valuation',
      tooltip: 'Last Round Valuation',
    }
  }
}

export const CompanyCard = ({
  company,
  showViewButton = true,
}: {
  company: ICompanyWithDeal
  showViewButton?: boolean
}) => {
  const authContext = useContext(AuthContext)
  const valuationDisplay = useMemo(
    () => getValuationDisplay(company),
    [company],
  )
  const hasDeal = company?.valuation || company.sharePrice || company.allocation

  return (
    <div className="group flex h-full w-full max-w-full flex-col overflow-hidden rounded border-gray-100 bg-themeIvory">
      <div className="relative h-28 w-full rounded-t">
        {company.bgUrl ? (
          <ImageKit
            alt={company.name}
            src={company.bgUrl?.url}
            fill
            style={{ objectFit: 'cover', objectPosition: 'right' }}
            className="scale-100 rounded-t duration-1000 ease-out group-hover:scale-[1.03] group-hover:duration-1000"
            placeholder="blur"
            blurDataURL={company.bgUrl?.blurUpThumb}
          />
        ) : (
          <div className="h-28 w-full rounded-t bg-gradient-to-br from-primary to-primary-dark" />
        )}

        {company.discountPercent && authContext.authenticated && (
          <div className="absolute -top-1 right-6 w-20 shadow-black drop-shadow-md">
            <DiscountBadge discountPercent={company.discountPercent} />
          </div>
        )}

        {company.logoUrl?.url && (
          <div className="absolute -bottom-8 left-5 h-16 w-16 overflow-clip rounded-lg border border-gray-100">
            <ImageKit
              alt={company.name}
              src={company.logoUrl?.url}
              width={64}
              height={64}
              style={{ objectFit: 'contain' }}
              className="bg-white"
            />
          </div>
        )}

        {company?.logoImage?.url && (
          <div className="absolute -bottom-8 left-7 h-16 w-16 overflow-clip rounded-lg border border-gray-100">
            <ImageKit
              alt={company.name}
              src={company.logoImage.url}
              width={64}
              height={64}
              style={{ objectFit: 'contain' }}
              className="bg-white"
            />
          </div>
        )}
      </div>

      <div className="relative h-full flex flex-col">
        <div className="absolute top-4 right-5">
          <span
            className={`text-white font-bold px-3 py-1 rounded text-xs ${
              hasDeal ? 'bg-primary' : 'bg-primary-teal-dark'
            }`}>
            {hasDeal ? 'AVAILABLE' : 'ON REQUEST'}
          </span>
        </div>

        <div className={`mx-6 mt-12 flex-1`}>
          <div className="text-xl font-bold">{company.name}</div>

          <div className="text-xs uppercase text-gray-500 mt-2 mb-4">
            {company.stage2 && (
              <span className="border-r pr-2 mr-2 border-gray-500 last:border-r-0">
                {company.stage2} Stage
              </span>
            )}
            {company.tags?.map(tag => (
              <span
                key={tag.name}
                className="border-r pr-2 mr-2 border-gray-500 last:border-r-0">
                {tag.name}
              </span>
            ))}
          </div>

          <div className="font-weight-300 my-2">{company.hook}</div>
        </div>

        {showViewButton && hasDeal && (
          <div className="grid grid-cols-1 rounded-b bg-themeIvory-dark px-6 pt-2 sm:grid-cols-3 md:pt-5 ">
            {company.sharePrice && (
              <div className="flex items-center">
                <TagIcon className="h-7 w-7 min-w-[1.75rem] text-gray-500" />
                <div className="ml-2 md:ml-3">
                  <div
                    className={`text-lg font-black text-primary-teal ${
                      authContext.authenticated || 'blur-[6px]'
                    }`}>
                    {`${abbreviateNumber(company.sharePrice)}`}
                  </div>
                  <div className="whitespace-nowrap text-xs uppercase text-gray-500">
                    share price
                  </div>
                </div>
              </div>
            )}
            {company.valuation && (
              <div className="flex items-center ">
                <ChartBarSquareIcon className="h-7 w-7 min-w-[1.75rem] text-gray-500" />
                <div className="ml-2 md:ml-3">
                  <div
                    className={`text-lg font-black text-primary-teal ${
                      authContext.authenticated || 'blur-[6px]'
                    }`}>
                    {valuationDisplay.value}
                  </div>
                  <div className="flex flex-row items-center whitespace-nowrap text-xs uppercase text-gray-500">
                    {valuationDisplay.label}
                  </div>
                </div>
              </div>
            )}
            {company.allocation && (
              <div className="flex items-center">
                <ViewColumnsIcon className="h-7 w-7 min-w-[1.75rem] text-gray-500" />
                <div className="ml-2 md:ml-3">
                  <div
                    className={`text-lg font-black text-primary-teal ${
                      authContext.authenticated || 'blur-[6px]'
                    }`}>
                    {`${abbreviateNumber(company.allocation)}`}
                  </div>
                  <div className="whitespace-nowrap text-xs uppercase text-gray-500">
                    allocation
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {showViewButton && (
          <div className={`px-5 py-4 ${hasDeal && 'bg-themeIvory-dark'}`}>
            {company?.valuation || company.sharePrice || company.allocation ? (
              <Button className="w-full">View</Button>
            ) : (
              <Button className="w-full">View</Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
