import { useInView } from 'react-intersection-observer'

import { Accordion } from '@components/Accordion'
import { AccordionItem } from '@components/Accordion'

const FAQOptions = [
  {
    title: 'What is an Accredited Investor?',
    Content: (
      <>
        <div className="bg-[#f7f7f7] p-5 text-sm">
          An accredited investor is a person, according to the Securities and
          Exchange Commission’s (SEC) definition, who has the knowledge,
          financial capacity and other measures of financial sophistication, to
          make investments not registered with the SEC. In order to qualify as
          an accredited investor, you must have a net worth of over $1,000,000
          excluding the value of your primary residence (individually or with
          spouse or partner) or have an income of $200,000 if you are single or
          $300,000 with spouse or partner in each of the prior two years, and
          reasonably expects the same for the current year.
        </div>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The other professional criteria defined by SEC include:
        </p>
        <ul className="list-disc bg-[#f7f7f7] px-10 py-2 text-sm">
          <li className="py-2">
            Investment professionals in good standing holding the general
            securities representative license (Series 7), the investment adviser
            representative license (Series 65), or the private securities
            offerings representative license (Series 82)
          </li>
          <li className="py-2">
            Directors, executive officers, or general partners (GP) of the
            company selling the securities (or of a GP of that company)
          </li>
          <li className="py-2">
            Any “family client” of a “family office” that qualifies as an
            accredited investor
          </li>
          <li className="py-2">
            For investments in a private fund, “knowledgeable employees” of the
            fund
          </li>
        </ul>
        <p className="bg-[#f7f7f7] px-5 py-4 text-sm">
          Click{' '}
          <a
            href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
            target="_blank"
            rel="noreferrer"
            className="text-[#415ED3] underline">
            here
          </a>{' '}
          for the current SEC definition of Accredited Investor.
        </p>
      </>
    ),
  },
  {
    title: 'How can entities qualify as Accredited Investors?',
    Content: (
      <>
        <div className="bg-[#f7f7f7] p-5 text-sm">
          Depending upon the structure of the entity or its assets, the
          following entities may qualify as an accredited investor by their
          investments or assets:
        </div>
        <ul className="list-disc bg-[#f7f7f7] px-10 pb-2 text-sm">
          <li className="py-2">
            Entities owning investments in excess of $5 million
          </li>
          <li className="py-2">
            The following entities with assets in excess of $5 million:
            corporations, partnerships, LLCs, trusts, 501(c)(3) organizations,
            employee benefit plans, “family office” and any “family client” of
            that office
          </li>
          <li className="py-2">
            Entities where all equity owners are accredited investors
          </li>
          <li className="py-2">
            Investment advisers (SEC- or state-registered or exempt reporting
            advisers) and SEC-registered broker-dealers
          </li>
          <li className="py-2">
            A bank, savings and loan association, insurance company, registered
            investment company, business development company, or small business
            investment company or rural business investment company
          </li>
        </ul>
      </>
    ),
  },
  {
    title:
      'Do I have to be an Accredited Investor to invest on the MarketX Ventures platform?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Most of our offerings are not registered with the SEC, so you must be
          an Accredited Investor in order to invest with MarketX Ventures.
        </p>
      </>
    ),
  },
  {
    title: 'What is the minimum investment amount?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          This varies for each investment, and is usually displayed on the
          Opportunity pages and the Subscription Agreement.
        </p>
      </>
    ),
  },
  {
    title: 'What does MarketX Ventures charge for the investment?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          We charge an annual management fee and a carry fee for each
          investment.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Management fees are charged by the Fund Manager to the Fund. The
          Management Fee is a percentage of each investor’s Committed Capital
          that may be charged upfront at closing, or annually, or a combination
          thereof. For the annual Management Fee, future Capital Calls may be
          made from Investors to pay this fee.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The Carry fee percentage is a share of the profits that the Fund
          Manager is entitled to. Whenever a distribution is made to investors,
          the manager charges the percentage Carry Fee of the profits
          (difference between the initial investment and liquidation value). The
          Carry Fees is only charged on the future investment profit or gain
          upon a liquidation event, and not on the initial investment principle.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          There could be another fee structure in place of the management fees
          plus carry for a specific investment and if so, such fees will be
          displayed for the investment.
        </p>
      </>
    ),
  },
  {
    title:
      'Do I own stocks in the companies I invested in on the MarketX Ventures platform?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          You do not directly own shares in the company, rather you own a
          membership interest in an LLC that owns the shares in the company that
          you invest in.
        </p>
      </>
    ),
  },
  {
    title: 'How are the investments structured?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Unless otherwise stated, the investment is structured as an LLC, with
          MarketX Securities LLC as the manager. You are investing in an LLC
          formed and managed by MarketX Securities LLC. The LLC owns the shares
          of the Company. Each investor subscribes to the LLC in the form of
          membership interests, relative to how much they contributed, and then
          the total funds contributed to the LLC will be used to buy the
          specified investment.
        </p>
      </>
    ),
  },
  {
    title: 'What are Pre-IPOs?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Pre-Initial Public Offerings (Pre-IPOs) allow private companies to
          raise money by selling shares. However, such investments traditionally
          required significant initial investments, preventing retail investors
          from investing. Here at MarketX Ventures we are democratizing such
          private investment and breaking down such barriers by offering
          Pre-IPOs via a LLC structure at significantly reduced minimums.
        </p>
      </>
    ),
  },
  {
    title: 'How are share prices determined?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          There is a private market for shares in private companies, and we
          determine offering prices based on the private market for these
          shares, based on publicly available information such as the last round
          of financing, investor demand, secondary transaction price history,
          and other other public information.
        </p>
      </>
    ),
  },
  {
    title: 'What paperwork do I receive for my investment?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The paperwork for the fund is similar to that of any other form of
          fund investment. In general, an investor will receive three documents:
          the Private Placement Memorandum (PPM), the Operating Agreement and
          the Subscription Agreement.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The Private Placement Memorandum (PPM), a confidential offering
          document sometimes known as an investment prospectus, is a securities
          disclosure document used in a private offering of securities by a
          private placement issuer or an investment fund (collectively, the
          “Issuer”). The PPM contains information about the Issuer, its
          securities, its management team, investment parameters, disclosures
          and the potential investment rights, risks and rewards, to allow the
          prospective investor to make an informed investment decision.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The Operating Agreement describes how the LLC is structured, managed,
          and operated, including establishing each member owner&apos;s
          percentage of ownership in the LLC, his or her share of profits (or
          losses), his or her rights and responsibilities, etc. Each investor in
          an LLC is a party to the Operating Agreement.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The Subscription Agreement describes the terms for an investor to
          invest in the fund (the LLC, and is the notification by the investor
          to the Fund’s Manager of the investors interest in participating in
          the investment. Once a Subscription Agreement is submitted by an
          investor, the Manager will consider it and other applicable
          information, and if accepted, the Manager will sign the Subscription
          Agreement at Closing as acceptance of the Subscription. Investors will
          also need to complete a W-9 or a W-8 BEN for foreign investors and the
          Suitability form.
        </p>
      </>
    ),
  },
  {
    title: 'How may the investment be taxed?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Our LLC is a “pass-through” entity for tax purposes. The Manager
          intends to elect that the LLC be treated as a partnership and as such,
          any gains and losses would pass through to the investors. Such gains
          and losses are reported to investors annually on the form K1. Note: We
          are not tax experts, and the aforementioned information serves for
          informational purposes only and not as personal tax advice. Please
          consult your tax advisor or accountant for information regarding your
          specific circumstances.
        </p>
      </>
    ),
  },
  {
    title:
      'How will MarketX communicate with me about my investment and how frequently?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          We will communicate with you generally via emails, on any MarketX
          Ventures news, required investor actions or public news that has to do
          with your investments, such as funding information, news about
          secondary transactions, company or market news, etc. You can view your
          investments by going to your Portfolio upon logging into MarketX
          Ventures (
          <a
            href="https://www.marketxventures.com"
            className="text-[#415ED3]"
            target="_blank"
            rel="noreferrer">
            www.marketxventures.com
          </a>
          ), and access your documents and K1s on your Investment Details page
          for each of your investments.
        </p>
      </>
    ),
  },
  {
    title: 'How do I invest on the MarketX platform?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          1. Verify your Accredited Investor status:
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          MarketX Ventures provides access to vetted, early-to-growth stage and
          pre-IPO companies. To invest in MarketX Ventures private investment
          opportunities through a MarketX-managed or a third-party managed fund,
          you will need to be an Accredited Investor, per the{' '}
          <a
            href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
            target="_blank"
            rel="noreferrer"
            className="text-[#415ED3]">
            U.S. SEC definition
          </a>
          .
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Simply sign up, activate your MarketX Ventures and verify your
          accredited investor status as part of your activation process as
          required by SEC rules.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">2. Browse companies:</p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Review the list of MarketX-vetted companies and investment
          opportunities as well as their details to understand the investment
          highlights, previous notable investors, previous funding history, the
          company’s product, core team, business model etc. and the investment
          terms.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          3. Submit investment request and complete closing:
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          You can submit a commitment to invest and one of our MarketX Ventures
          Specialists will contact you to facilitate the investment closing. The
          closing process will start with signing the relevant investment
          related documents, transferring the investment funds plus any upfront
          fees to the specified bank account (by specified payment due date),
          and providing information for and signing tax-related documents. You
          can track your closing process from your Investment-In-Progress
          dashboard.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          4. Track your portfolio and engage with MarketX Ventures:
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          You can track your closed investments from your Portfolio dashboard.
          Engage with MarketX Ventures through your MarketX Ventures Specialist,
          our business team, technical support team, various online webinars on
          upcoming investments, future updates to closed investments, uploaded
          tax related documents, as well as occasional off-line events (by
          invitations only).
        </p>
      </>
    ),
  },
  {
    title:
      'When should I expect the company I am investing in to go public or get acquired?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          MarketX Ventures cannot guarantee an exit nor a timeline for any
          liquidity event for any of our offerings. However, the majority of
          investment opportunities on our platform are companies that have
          received institutional financing from late-stage or growth investment
          funds, that typically have an investment horizon of 2-5 years. Given
          the liquidity of investments available via the MarketX Ventures
          platform, these investments are not suitable for all investors. Please
          consider your investment time horizon before you make an investment
          with MarketX Ventures.
        </p>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          An investment involves significant risks where an investor could lose
          all or a substantial amount of his or her investment, and is suitable
          only for persons who have limited need for liquidity and who meet the
          suitability standards set forth by FINRA. Each potential investor
          should not construe the contents on MarketX Ventures as legal, tax,
          investment or other advice, and should obtain the advice of legal,
          accounting, tax and other advisors before making any investment
          decisions.
        </p>
      </>
    ),
  },
  {
    title: 'The company I invested in has had an IPO: what happens next?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          Your MarketX Ventures Specialist will contact you and facilitate the
          transfer of economic interests to you. Typically, the common
          stockholders prior to an IPO are subject to a 180-day lockup after the
          IPO date. During the lockup period, sale or transfer of shares is
          generally prohibited, so the transfer will take place after the lockup
          period.
        </p>
      </>
    ),
  },
  {
    title: 'The company I invested in was acquired: what happens next?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          The proceeds received by the shareholder (stock and/or cash) would be
          transferred to the Fund, after which MarketX Ventures (the Managing
          Member) would distribute these proceeds to the Investors in accordance
          with the OPerating Agreement.
        </p>
      </>
    ),
  },
  {
    title:
      'Do I have the option to sell before the company goes public? What are the methods if I want to exit or cash out my investment?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          While we view the subscription of Fund interest as a long-term
          commitment, your interests in a Fund are generally transferable which
          allows you the possibility to sell your interest. We will help you
          list your fund interest on the MarketX Ventures platform so other
          investors can see it. While we can assist you in selling your interest
          in the fund, we cannot guarantee we’ll be able to find a buyer, or
          close a transaction for you. You are also able to use other means on
          your own to find a suitable buyer of your interest.
        </p>
      </>
    ),
  },
  {
    title:
      'Can there be a secondary offering and if so how much of my ownership will be diluted?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          A company you invest in may very well raise an additional financing
          round(s). While your percentage ownership in the Fund will remain the
          same, the Funds percentage ownership in the Company would decrease (as
          more shares are created).The Fund and MarketX Ventures as Manager of
          the Fund typically will have no voting rights or input into any
          decision the Company makes regarding capital raising.
        </p>
      </>
    ),
  },
  {
    title: 'Is there a share price on the day I decide to sell?',
    Content: (
      <>
        <p className="bg-[#f7f7f7] px-5 py-2 text-sm">
          For private securities there currently is no recognized exchange that
          publishes a price publicly that the shares of a Company traded for or
          indicative of a price that a trade could be executed at. If you decide
          to sell, through whatever means you are able to identify a buyer, the
          share price will be a price negotiated to close the sale. You will be
          selling your interest in a Fund, you do not directly own shares in the
          Company. There are no assurances that there will be new information to
          corroborate any share price at any time. In the instance where you
          seek MarketX Ventures assistance in finding a buyer for your interest
          in a Fund, we cannot guarantee the ability to find you such a buyer to
          exit your investment before a liquidity event.
        </p>
      </>
    ),
  },
]
export const FAQSection = ({ setActive }: { setActive?: Function }) => {
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 0.3,
    onChange: inView => {
      if (inView && setActive) {
        setActive('faq')
      }
    },
  })

  return (
    <div ref={ref} id="faq">
      <p className="mb-8 font-extrabold text-primary md:text-lg">
        Frequently Asked Questions
      </p>

      <Accordion>
        {FAQOptions.map((faqOption, index) => {
          return (
            <div key={index}>
              <AccordionItem title={faqOption.title}>
                {faqOption.Content}
              </AccordionItem>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}
