import { UserIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'

const data = [
  {
    id: 1,
    text: 'My Profile',
    link: '/profiles',
    icon: (
      <>
        <UserIcon className="mr-2 h-5 w-5" />
      </>
    ),
  },
  {
    id: 2,
    text: 'Accreditation Status',
    link: '/profiles/accreditation-status',
    svgIcon: true,
  },
]

export const MyProfile = ({ activeTab }: { activeTab?: string }) => {
  return (
    <div className="mt-4 w-full md:mt-0 lg:w-1/4">
      {data.map(item => (
        <Link key={item.id} href={item.link}>
          <div
            className={`mb-2 cursor-pointer rounded-xl border py-3.5 ${
              activeTab === item.text
                ? 'bg-primary-dark text-white'
                : 'bg-white text-primary'
            }`}>
            <div className="flex items-center justify-center">
              {item.icon && item.icon}
              {item.svgIcon && (
                <div className="mr-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.0002 2.52084C7.83236 2.52084 5.271 5.0822 5.271 8.25C5.271 9.43948 5.62274 10.5259 6.22695 11.4329C7.11328 12.745 8.51275 13.6672 10.1368 13.9039C10.1436 13.9048 10.1504 13.9059 10.1571 13.9071C10.4277 13.9549 10.7085 13.9792 11.0002 13.9792C11.2918 13.9792 11.5726 13.9549 11.8432 13.9071C11.8476 13.9064 11.8521 13.9056 11.8565 13.9049C11.8589 13.9045 11.8612 13.9042 11.8635 13.9039C13.4876 13.6672 14.887 12.745 15.7734 11.4329C16.3776 10.5259 16.7293 9.43948 16.7293 8.25C16.7293 5.0822 14.168 2.52084 11.0002 2.52084ZM3.896 8.25C3.896 4.32281 7.07297 1.14584 11.0002 1.14584C14.9274 1.14584 18.1043 4.32281 18.1043 8.25C18.1043 9.71815 17.6682 11.0695 16.9164 12.1972L16.9142 12.2005C15.8223 13.8181 14.0912 14.9658 12.0719 15.263C11.7224 15.3241 11.365 15.3542 11.0002 15.3542C10.6354 15.3542 10.2779 15.3241 9.92839 15.263C7.90914 14.9658 6.17807 13.8181 5.08616 12.2005L5.08395 12.1972L5.08396 12.1972C4.33217 11.0695 3.896 9.71815 3.896 8.25Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.72169 11.1406C5.92603 11.1603 6.1109 11.2702 6.22574 11.4404C7.11201 12.7533 8.51198 13.6763 10.1367 13.913C10.1435 13.914 10.1503 13.9151 10.1571 13.9163C10.3949 13.9583 10.6407 13.9821 10.8947 13.9873C10.9293 13.9819 10.9644 13.9792 10.9998 13.9792C11.0354 13.9791 11.0707 13.9819 11.1054 13.9873C11.3595 13.9821 11.6052 13.9583 11.8431 13.9163C11.8499 13.9151 11.8566 13.914 11.8634 13.913C13.4882 13.6763 14.8881 12.7533 15.7744 11.4404C15.8893 11.2702 16.0741 11.1603 16.2785 11.1406C16.4828 11.121 16.6852 11.1937 16.8304 11.3389L20.4035 14.912C21.2997 15.7957 20.8468 17.3135 19.6377 17.5998L18.1292 17.9563C18.1287 17.9565 18.1282 17.9566 18.1277 17.9567C18.0266 17.9819 17.974 18.049 17.9604 18.112L17.9572 18.1259L17.6365 19.473C17.6364 19.4732 17.6365 19.4729 17.6365 19.473C17.3312 20.7571 15.6989 21.1381 14.8507 20.1376L11.0006 15.7143L7.14969 20.1465C6.30155 21.1474 4.66907 20.7667 4.36377 19.4826L4.04372 18.1383C4.04363 18.1379 4.04354 18.1376 4.04345 18.1372C4.02002 18.0427 3.94786 17.9828 3.88427 17.9686L3.87526 17.9666L2.36277 17.6091C1.1449 17.3206 0.708211 15.8004 1.59478 14.9139L5.16978 11.3389C5.31493 11.1937 5.51735 11.121 5.72169 11.1406ZM12.3912 15.2177L15.8987 19.2474C16.0233 19.3955 16.2559 19.3355 16.2987 19.1553L16.6182 17.8134C16.7536 17.2025 17.2305 16.7599 17.8043 16.6203L17.8086 16.6192L19.3208 16.2618C19.5039 16.2185 19.5463 15.9973 19.4376 15.8906L19.4331 15.8861L16.4135 12.8666C15.3941 14.0564 13.9922 14.9063 12.3912 15.2177ZM9.61036 15.2179C8.00879 14.9068 6.60635 14.0567 5.58661 12.8666L2.56705 15.8861C2.44528 16.0079 2.5039 16.2295 2.67936 16.271L4.18772 16.6275C4.78136 16.7616 5.23832 17.2318 5.37977 17.8133L5.38056 17.8166L5.70138 19.1641C5.74417 19.3442 5.9769 19.4047 6.10148 19.2566L6.10854 19.2482L9.61036 15.2179Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.5318 5.48166L12.0726 6.56332C12.146 6.70999 12.3385 6.85666 12.5126 6.88416L13.4935 7.04916C14.1168 7.14999 14.2635 7.60833 13.8143 8.0575L13.0535 8.81832C12.9251 8.94665 12.8518 9.19416 12.8976 9.3775L13.1176 10.3217C13.2918 11.0642 12.8976 11.3575 12.2376 10.9633L11.321 10.4225C11.156 10.3216 10.881 10.3216 10.716 10.4225L9.7993 10.9633C9.1393 11.3483 8.74514 11.0642 8.9193 10.3217L9.1393 9.3775C9.17597 9.20333 9.1118 8.94665 8.98347 8.81832L8.22264 8.0575C7.77347 7.60833 7.92014 7.15916 8.54347 7.04916L9.5243 6.88416C9.6893 6.85666 9.8818 6.70999 9.95514 6.56332L10.496 5.48166C10.7618 4.895 11.2385 4.895 11.5318 5.48166Z"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
              <p className="text-center text-lg font-normal capitalize">
                {item.text}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}
