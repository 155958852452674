import { ImageKit, Button } from '@components'
import { IEvent } from '@types'
import moment from 'moment'

export const EventCard = ({ event }: { event: IEvent }) => {
  return (
    <div className="relative rounded-2xl bg-themeGray-light3 ">
      <div
        className="h-72 rounded-t-2xl bg-cover"
        style={{
          backgroundImage: `url(${event.image.url})`,
        }}>
        <span className="relative left-4 top-56 flex">
          {event.isMxSpeaking ? (
            <ImageKit
              src="/images/speakerphone.svg"
              alt="speakerphone"
              width={40}
              height={40}
              className="mr-4"
            />
          ) : (
            <ImageKit
              src="/images/handshake.svg"
              alt="handshake"
              width={40}
              height={40}
            />
          )}
        </span>
      </div>
      <div className="px-5 pb-2 pt-6 font-normal">
        <div className="flex flex-col">
          <p className="text-3xl text-primary">
            {!event.dateEnd
              ? moment(event.date).format('DD MMM, YYYY')
              : `${moment(event.date).format('DD')}-${moment(
                  event.dateEnd,
                ).format('DD MMM, YYYY')}`}
          </p>
          <p className="my-6 text-2xl">{event.title}</p>

          <p className="text-sm uppercase">{event.location}</p>
          <a href={`${event.link}`} rel="noreferrer" target="_blank">
            <Button className="my-4 w-full">Learn More</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
