import { useState, SyntheticEvent } from 'react'
import { Button } from '@components'
import axios from 'axios'
import { PuffLoader } from 'react-spinners'
import { CheckIcon } from '@heroicons/react/24/outline'

export const JoinList = () => {
  const [email, setEmail] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)

  const submitForm = (e: SyntheticEvent) => {
    e.preventDefault()

    setIsLoading(true)

    axios
      .post(process.env.NEXT_PUBLIC_API_URL + '/users', {
        email,
      })
      .then(response => {
        setEmail('')
        setIsLoading(false)
        setIsEmailSent(true)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <div className="mx-auto w-full md:w-4/5">
      <p className="py-2 text-center text-base font-normal">
        Join our mailing list to receive our newsletter and investment updates.
      </p>

      {isLoading ? (
        <div className="mt-2 flex items-center justify-center">
          <PuffLoader size={22} color={'white'} />
        </div>
      ) : isEmailSent ? (
        <div className="flex items-center justify-center">
          <CheckIcon className="mr-2 h-4 w-4" />
          <p className="py-2 text-center text-base font-normal">
            You are subscribed!
          </p>
        </div>
      ) : (
        <form onSubmit={submitForm}>
          <input
            type="email"
            required
            name="email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
            placeholder="Enter your email"
            className="block w-full rounded border-gray-300 text-center text-black focus:border-primary focus:ring-primary sm:text-sm"
          />
          <div className="mt-2">
            <Button submit className="w-full">
              Subscribe
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
