export const CompanyStatMobile = ({
  title,
  subtitle,
}: {
  title: string
  subtitle: string
}) => {
  return (
    <div className="flex w-full flex-col items-center pb-4 text-center xl:pb-0">
      <p className="mb-1 text-lg font-semibold text-themeBlack md:text-2xl">
        {title}
      </p>
      <p className="text-xs text-gray-500 sm:text-sm">{subtitle}</p>
    </div>
  )
}
