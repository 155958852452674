import React from 'react'
export interface EmailInputProps {
  // required
  label: string
  onChange: Function
  value: string
  name: string

  // optional
  defaultValue?: string
  description?: string
  required?: boolean
  error?: boolean
  errorText?: string
  placeholder?: string
  readOnly?: boolean
}

export const EmailInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  description,
  required,
  error,
  errorText,
  placeholder,
  readOnly,
}: EmailInputProps) => {
  const classess = {
    default:
      'shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded',
    error:
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded',
  }

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-base font-medium text-gray-700">
        {label}
        {required && <sup className="text-red-500">*</sup>}
      </label>

      <div className="mt-1">
        <input
          autoComplete="none"
          id={name}
          name={name}
          defaultValue={defaultValue}
          type="email"
          className={error ? classess.error : classess.default}
          onChange={e => onChange(e)}
          value={value}
          required={required}
          placeholder={placeholder}
          aria-describedby={name}
          readOnly={readOnly}
        />
        {error && <p className="mt-1 text-xs text-red-500">{errorText}</p>}
      </div>

      {description && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
