import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Route } from 'routes'

export interface TabProps {
  routes: Route[]
}

const TabNavigation = ({ routes }: TabProps) => {
  const router = useRouter()

  const getActiveLink = (path: string, relatedPaths?: string[]) => {
    if (path === '/' && router.pathname === path) {
      return true
    }
    const relatedRoute =
      relatedPaths?.length &&
      relatedPaths.find(item => router.pathname.indexOf(item) !== -1)
    if (relatedRoute) {
      return true
    }
    return path !== '/' && router.pathname.indexOf(path) !== -1
  }

  return (
    <nav className="flex space-x-1 border-b">
      {routes?.map(route => (
        <Link legacyBehavior key={route.name} href={route.path}>
          <a
            className={classNames(
              getActiveLink(route.path, route.relatedPaths)
                ? 'border-primary-light border-opacity-20 bg-primary-light bg-opacity-5 font-medium text-primary'
                : 'text-black',
              'group relative flex items-center rounded-t border border-b-0 px-3 py-2 text-sm hover:bg-primary-light hover:bg-opacity-5',
            )}>
            {route.icon && (
              <div aria-hidden="true" className="mr-2 h-6 w-6 flex-shrink-0">
                <route.icon />
              </div>
            )}
            {route.name}
          </a>
        </Link>
      ))}
    </nav>
  )
}

export default TabNavigation
