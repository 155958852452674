import React from 'react'
import Select from 'react-select'
import Styles from './ReactSelect.module.css'
export interface ReactSelectOption {
  value: string | number
  text?: string
  label?: string
  disabled?: boolean
}
export interface ReactSelectInputProps {
  // required
  label: string
  onChange: Function
  name: string

  // optional
  description?: string
  required?: boolean
  error?: boolean
  options: ReactSelectOption[]
  disabled?: boolean
  value?: ReactSelectOption | null
}

export const ReactSelect = ({
  label,
  onChange,
  name,
  description,
  required,
  options,
  value,
}: ReactSelectInputProps) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="mb-1 block text-base font-medium text-gray-700">
        {label}
        {required && <sup className="text-red-500">*</sup>}
      </label>
      <Select
        classNamePrefix={Styles.autoCompleteSelect}
        id={name}
        name={name}
        options={options}
        value={value}
        className="text-sm"
        onChange={(value: any) => {
          onChange(value)
        }}
      />
      <input
        autoComplete="none"
        type="text"
        data-readonly
        id={`${name}-v`}
        name={`${name}-v`}
        className="absolute -mb-1 h-px p-0 text-themeBlack opacity-0"
        value={value?.value}
        required={required}
      />
      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
