import Link from 'next/link'
import { getImageUrl } from 'utils/getImageUrl'
interface TeamCardProps {
  image?: string
  linkedIn?: string
  name?: string
  position?: string
  slug?: string
}
export const TeamCard = ({
  image,
  name,
  position,
  linkedIn,
  slug,
}: TeamCardProps) => {
  return (
    <div className="relative">
      <Link href={'/team/' + slug}>
        <div className="group relative z-0 aspect-[3/4] cursor-pointer rounded-md border border-transparent hover:border-primary ">
          <div
            style={{
              backgroundImage: `url(${image})`,
            }}
            className={`group relative z-30 h-full w-full rounded-xl bg-cover bg-center bg-no-repeat`}
          />
        </div>
      </Link>
      <div className="flex flex-col items-center justify-center">
        <Link href={'/team/' + slug}>
          <h4 className="text-center text-lg font-bold text-themeBlack-heading lg:pt-6 lg:text-xl">
            {name}
          </h4>
          <p className="pt-2 text-center text-sm font-normal text-themeBlack lg:text-base">
            {position}
          </p>
        </Link>
        <div className="mt-3 rounded-md bg-[#2486E0] p-3">
          <a href={linkedIn} target="_blank" rel="noreferrer">
            <img
              src={getImageUrl({
                src: '/images/social/icon-in.png',
              })}
              className="h-4 w-4"
            />
          </a>
        </div>
      </div>
    </div>
  )
}
