import { ICompany } from '@types'
import { InvestmentSectionLoader } from '@loaders'
import { ImageKit } from '@components'

export const InvestmentHeroSection = ({
  company,
  pageTitle,
  pageDetails,
  loading,
}: {
  pageTitle: string
  pageDetails?: string
  company: ICompany
  loading?: boolean
}) => {
  return (
    <div className="relative mt-10 md:-mt-4">
      {loading ? (
        <InvestmentSectionLoader />
      ) : (
        <>
          <div className="relative h-64 w-full md:h-72">
            {company?.bgUrl ? (
              <ImageKit
                alt={company.name}
                src={company.bgUrl?.url}
                fill
                style={{
                  objectFit: 'cover',
                }}
                placeholder="blur"
                blurDataURL={company.bgUrl?.blurUpThumb}
              />
            ) : company?.backgroundImage?.url ? (
              <ImageKit
                alt={company.name}
                src={company.backgroundImage.url}
                fill
                style={{
                  objectFit: 'cover',
                }}
              />
            ) : company?.background_url ? (
              <ImageKit
                alt={company.name}
                src={company.background_url}
                fill
                style={{
                  objectFit: 'cover',
                }}
              />
            ) : (
              <div className="h-28 w-full rounded-t bg-gradient-to-br from-primary to-primary-dark" />
            )}
          </div>
          <div className="absolute inset-0 bg-gradient-to-r from-[#676E7A] p-4 md:p-0">
            <div className="my-1 ml-1 md:my-20 md:ml-16">
              <div className="flex flex-col items-start md:flex-row md:items-center">
                <div className="flex items-start md:items-center">
                  {company?.logoUrl?.url ? (
                    <ImageKit
                      alt={company.name}
                      src={company.logoUrl?.url}
                      width={32}
                      height={32}
                      style={{
                        objectFit: 'contain',
                      }}
                      className="mr-3.5 bg-white"
                    />
                  ) : (
                    company.logo_url && (
                      <ImageKit
                        alt={company.name}
                        src={company?.logo_url}
                        width={32}
                        height={32}
                        style={{
                          objectFit: 'contain',
                        }}
                        className="mr-3.5 bg-white"
                      />
                    )
                  )}
                  <p className="ml-3.5 border-white pr-2.5 text-base font-bold tracking-wide text-white md:border-r md:text-3xl">
                    {company?.name}
                  </p>
                </div>
                <div className="pl-0 pt-4 text-base font-semibold text-white md:pl-2.5 md:pt-0 md:text-lg">
                  {pageTitle}
                </div>
              </div>
              <div className="mt-4 w-4/6 text-xs font-normal text-white md:mt-6 md:w-2/5 md:text-sm">
                {pageDetails && pageDetails}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
