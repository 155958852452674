export const Question = ({
  question,
  checked,
  questionIdx,
  onCheckChange,
}: {
  question: string
  checked: boolean
  questionIdx: number
  onCheckChange: Function
}) => {
  return (
    <div className="mb-2 flex text-sm">
      <input
        type="checkbox"
        id={`question_${questionIdx}`}
        className="mr-2 mt-1 h-[13px] w-[13px]"
        onChange={() => onCheckChange(questionIdx)}
        checked={checked}
      />
      <label htmlFor={`question_${questionIdx}`}>{question}</label>
    </div>
  )
}
