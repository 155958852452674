import { useTimer } from 'react-timer-hook'

export const Timer = ({
  expiryTimestamp,
  backgroundTransparent,
}: {
  expiryTimestamp: any
  backgroundTransparent?: boolean
}) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  })

  return (
    <div
      className={`${
        backgroundTransparent ? 'bg-transparent' : 'bg-[#374151] '
      }rounded-full text-white`}
      style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '24px' }}>
        <div className="mx-auto flex w-fit py-1.5">
          <div>
            <p className="font-bold">{days}</p>
            <p className="text-[8px] font-normal leading-[9.68px]">Days</p>
          </div>
          <span className="px-1">:</span>
          <div>
            <p className="font-bold">{hours}</p>
            <p className="text-[8px] font-normal leading-[9.68px]">Hours</p>
          </div>
          <span className="px-1">:</span>
          <div>
            <p className="font-bold">{minutes}</p>
            <p className="text-[8px] font-normal leading-[9.68px]">Minutes</p>
          </div>
          <span className="px-1">:</span>
          <div>
            <p className="font-bold">{seconds}</p>
            <p className="text-[8px] font-normal leading-[9.68px]">Seconds</p>
          </div>
        </div>
      </div>
    </div>
  )
}
