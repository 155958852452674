import { Tooltip, ImageKit } from '@components'
export interface TextInputProps {
  // required
  label: string
  onChange: Function
  value?: string
  name: string

  // optional
  defaultValue?: string
  placeholder?: string
  description?: string
  required?: boolean
  error?: boolean
  tooltip?: string
  pattern?: string
  disabled?: boolean
  title?: string
  maxLength?: number
}

export const TextInput = ({
  label,
  onChange,
  value,
  name,
  defaultValue,
  placeholder,
  description,
  required,
  error,
  tooltip,
  pattern,
  disabled,
  title,
  maxLength,
}: TextInputProps) => {
  const classess = {
    default:
      'shadow-sm focus:ring-primary focus:border-primary text-themeBlack block w-full sm:text-sm border-[#D0D0D0] rounded',
    disabled:
      'bg-gray-100 shadow-sm text-themeBlack block w-full sm:text-sm border-[#D0D0D0] rounded',
    error:
      'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded',
  }

  return (
    <div>
      <div className="relative flex items-center">
        <label
          htmlFor={name}
          className="block text-base font-medium text-gray-700">
          {label}
          {required && label && <sup className="text-red-500">*</sup>}
        </label>
        {tooltip && (
          <Tooltip position="top" message={tooltip}>
            <div className="relative ml-1 flex cursor-pointer">
              <ImageKit
                src="/images/question.svg"
                alt="Tooltip image"
                height={16}
                width={16}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="mt-1">
        <input
          disabled={disabled || false}
          autoComplete="none"
          id={name}
          name={name}
          pattern={pattern}
          defaultValue={defaultValue}
          type="text"
          className={
            error
              ? classess.error
              : disabled
              ? classess.disabled
              : classess.default
          }
          onChange={e => onChange(e)}
          {...(value !== undefined ? { value } : {})}
          required={required}
          placeholder={placeholder}
          aria-describedby={name}
          title={title}
          maxLength={maxLength}
        />
      </div>

      {description && (
        <p className="mt-2 text-xs text-[#7D7D7D]" id={`${name}-description`}>
          {description}
        </p>
      )}
    </div>
  )
}
