import { useState, FormEventHandler, useEffect } from 'react'
import {
  Button,
  TextInput,
  SelectInput,
  ReactSelect,
  PhoneNumberInput,
  EmailInput,
} from '@components'
import { useRouter } from 'next/router'
import axios from 'axios'

interface FormValues {
  email: string
  firstName: string
  lastName: string
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  phoneNumber?: string
  postalCode?: string
  entityName?: string
}

interface ISelectOptions {
  readonly label: string
  readonly value: string
}

export const ProfileForm = ({
  handleSubmit,
  formValues,
  setFormValue,
  setStatesOfCountry,
  setCurrentState,
  currentState,
  allStates,
  submitting,
  profileType,
}: {
  handleSubmit: FormEventHandler<HTMLFormElement> | undefined
  formValues: FormValues
  setFormValue: Function
  setStatesOfCountry: Function
  setCurrentState: Function
  currentState: ISelectOptions | null
  allStates: ISelectOptions[]
  submitting: boolean
  profileType?: string
}) => {
  const router = useRouter()

  const [countries, setCountries] = useState<ISelectOptions[]>([])

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_API_URL + `/countries`)
      .then(response => {
        const _countries = response.data.map((c: any) => ({
          value: c.code,
          text: c.name,
        }))
        _countries.sort(function (a: any, b: any) {
          const nameA = a.text.toUpperCase()
          const nameB = b.text.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        setCountries(_countries)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="rounded border px-8 py-4">
        <div className="w-full lg:w-4/5">
          <p className="pb-4 text-lg font-semibold">Edit Profile</p>
          <div className="flex flex-col justify-between lg:flex-row">
            {profileType !== 'family' ? (
              <>
                <div className="mb-6 w-full lg:w-[48%]">
                  <TextInput
                    disabled
                    label="First Name"
                    name="firstName"
                    value={formValues?.firstName || ''}
                    onChange={(e: any) => {
                      setFormValue(e.target.value, 'firstName')
                    }}
                  />
                </div>
                <div className="mb-6 w-full lg:w-[48%]">
                  <TextInput
                    disabled
                    label="Last Name"
                    name="lastName"
                    value={formValues?.lastName || ''}
                    onChange={(e: any) => {
                      setFormValue(e.target.value, 'lastName')
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="mb-6 w-full lg:w-[48%]">
                <TextInput
                  label="Entity Name"
                  name="entityName"
                  value={formValues?.entityName || ''}
                  onChange={(e: any) => {
                    setFormValue(e.target.value, 'entityName')
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-between lg:flex-row">
            <div className="mb-6 w-full lg:w-[48%]">
              <TextInput
                required
                label="Address 1"
                name="addressLine1"
                value={formValues?.addressLine1 || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'addressLine1')
                }}
              />
            </div>
            <div className="mb-6 w-full lg:w-[48%]">
              <TextInput
                label="Address 2"
                name="addressLine2"
                value={formValues?.addressLine2 || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'addressLine2')
                }}
              />
            </div>
          </div>

          <div className="flex flex-col justify-between lg:flex-row">
            <div className="mb-6 w-full lg:w-[48%]">
              <SelectInput
                required
                options={countries && countries}
                name="country"
                label="Country"
                defaultValue={formValues?.country || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'country')
                  setStatesOfCountry(e.target.value)
                  setCurrentState({
                    label: 'Select',
                    value: '',
                  })
                }}
              />
            </div>
            <div className="mb-6 w-full lg:w-[48%]">
              <ReactSelect
                value={currentState}
                name="state"
                onChange={(value: any) => {
                  setFormValue('', 'city')
                  setCurrentState({
                    label: value.label,
                    value: value.value,
                  })
                }}
                label="State"
                required
                options={allStates}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:flex-row">
            <div className="mb-6 w-full lg:w-[48%]">
              <TextInput
                required
                label="City"
                name="city"
                value={formValues?.city || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'city')
                }}
              />
            </div>
            <div className="mb-6 w-full lg:w-[48%]">
              <TextInput
                label="Postal Code"
                name="postalCode"
                value={formValues?.postalCode || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'postalCode')
                }}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between lg:flex-row">
            <div className="mb-6 w-full lg:w-[48%]">
              <PhoneNumberInput
                required
                name="phoneNumber"
                label="Mobile"
                value={formValues?.phoneNumber}
                onChange={(value: string) => {
                  setFormValue(value, 'phoneNumber')
                }}
              />
            </div>
            <div className="mb-6 w-full lg:w-[48%]">
              <EmailInput
                readOnly
                label="Email Address"
                name="email"
                value={formValues?.email || ''}
                onChange={(e: any) => {
                  setFormValue(e.target.value, 'email')
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <Button
            onClick={router.back}
            light
            size="md"
            className="mr-2"
            disabled={submitting}>
            Cancel
          </Button>
          <Button size="md" submit disabled={submitting} loading={submitting}>
            Save
          </Button>
        </div>
      </div>
    </form>
  )
}
