import React, { useState, useEffect } from 'react'
import { PuffLoader } from 'react-spinners'
import { getImageUrl } from 'utils/getImageUrl'
import { Question } from './Question'

const Questions = [
  {
    text: `I am a financially sophisticated person with sufficient knowledge and experience to evaluate the merits and risks of the investment opportunities presented to me.`,
    checked: false,
  },
  {
    text: `I understand the SEC's definition of an accredited investor.`,
    checked: false,
  },
  {
    text: `All persons, self-inclusive, and entities I intend to represent on the MarketX Ventures platform qualify as accredited investors, as defined by the SEC.`,
    checked: false,
  },
  {
    text: `All persons, self-inclusive, and entities I intend to represent on the MarketX Ventures platform understand and willingly accept the inherent risks in investing in private securities, such as the possibility of incurring significant losses.`,
    checked: false,
  },
  {
    text: `I understand that providing false information or misrepresentation about the accredited investor status and financial sophistication of any persons, self-inclusive, or entities I intend to represent on the MarketX Ventures platform will result in being immediately and permanently banned from participating in any investment opportunities offered by MarketX Ventures.`,
    checked: false,
  },
]
export function AccreditationForm({
  onSubmit,
  submitting,
}: {
  onSubmit: Function
  submitting: boolean
}) {
  const [canContinue, setCanContinue] = useState(false)
  const [questions, setQuestions] = useState(Questions)

  const onQuestionChecked = (questionIdx: number) => {
    const _questions = [...questions]
    _questions[questionIdx].checked = !_questions[questionIdx].checked
    setQuestions(_questions)
    setCanContinue(questions.filter(q => !q.checked).length === 0)
  }

  const resetChecks = () => {
    const _questions = questions.map(q => ({ ...q, checked: false }))
    setQuestions(_questions)
  }

  return (
    <div className="accreditation-questionaire flex w-full flex-col items-center justify-center py-20">
      <img
        className="mx-auto mb-8 h-12 w-auto"
        src={getImageUrl({
          src: '/images/marketx-logo-purple.webp',
        })}
        alt="Workflow"
      />

      <div className="content min-h-[25rem] w-[40rem] max-w-[90%] overflow-hidden rounded-lg border bg-white">
        <h4 className="text-md bg-themeBlack-dark py-2 pl-8 font-bold text-white">
          Investor Attestation Form
        </h4>

        <div className="px-3 py-4 md:px-8">
          <p className="my-2 text-sm  font-semibold">
            I hereby certify and attest that the following statements are true
            and accurate:
          </p>

          <div>
            {questions.map((question: any, i: any) => (
              <Question
                key={i}
                question={question.text}
                questionIdx={i}
                checked={question.checked}
                onCheckChange={onQuestionChecked}
              />
            ))}
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          resetChecks()
          onSubmit()
        }}
        type="button"
        disabled={!canContinue}
        className={`flex justify-center rounded-md border border-transparent px-6 py-2 text-sm font-medium text-white shadow-sm ${
          canContinue ? 'bg-primary' : 'bg-gray-500'
        } mx-auto mt-3 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}>
        {submitting ? <PuffLoader size={24} color={'white'} /> : 'Continue'}
      </button>
    </div>
  )
}
