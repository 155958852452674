import { ImageKit } from '@components'

export interface IInvestmentHistoryProps {
  currentStep: number
}

export const InvestmentHistory = ({ currentStep }: IInvestmentHistoryProps) => {
  const steps = [
    { title: 'Investment Committed' },
    { title: 'KYC Completed' },
    { title: 'Investment documents Signed', link: '/portfolio' },
    { title: 'Tax Document Signed' },
    { title: 'Payment Received' },
    { title: 'Counter-Signatures Completed (Closed)' },
    { title: 'Finalized' },
  ]
  return (
    <>
      <div className="mb-5 mt-2 flex items-center text-2xl font-bold text-themeBlack">
        <ImageKit
          alt="Company Name"
          src="/images/status.svg"
          width={24}
          height={24}
        />
        <p className="ml-3.5 text-lg font-bold text-themeBlack">History</p>
      </div>
      <div className="border border-gray-200 px-10 pb-6 pt-5">
        {steps.map((step, stepIndex) => {
          return (
            <div className="flex items-center pb-[10.5px]" key={stepIndex}>
              {currentStep >= stepIndex ? (
                <div
                  className={`relative mr-5 flex h-6 w-6 items-center justify-center rounded-full bg-primary before:absolute before:left-2.5 before:top-[24px] before:w-[1.5px] before:bg-primary ${
                    stepIndex !== steps.length - 1 ? 'before:h-[10.5px]' : ''
                  }`}>
                  <ImageKit
                    alt=""
                    src="/images/checked.svg"
                    width={13}
                    height={13}
                  />
                </div>
              ) : currentStep === stepIndex - 1 ? (
                <div
                  className={`relative mr-5 flex h-6 w-6 items-center justify-center rounded-full border border-primary before:absolute before:left-2.5 before:top-[24px] before:h-[10.5px] before:bg-primary after:absolute after:h-[9px] after:w-[9px] after:rounded-full after:bg-primary ${
                    stepIndex !== steps.length - 1 ? 'before:w-[1.5px]' : ''
                  }`}
                />
              ) : (
                <div
                  className={`relative mr-5 flex h-6 w-6 items-center justify-center rounded-full border before:absolute  before:left-2.5 before:top-[24px] before:w-[1.5px] before:bg-[#D0D0D0] ${
                    stepIndex !== steps.length - 1 ? 'before:h-[10.5px]' : ''
                  }`}
                />
              )}
              <div
                className={`text-sm ${
                  currentStep >= stepIndex
                    ? 'text-primary'
                    : 'text-themeGray-dark'
                }`}>
                {step.title}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
