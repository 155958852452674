import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { IDatoCmsMediaGalleryItem } from '@types'
import { getYoutubeId } from '@utils'
import Slider from '@ant-design/react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ReactElement, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { ImageKit } from '@components'
import Styles from './Companies.module.css'
// Workaround for: https://github.com/akiran/react-slick/issues/1195
const IconWrapper = ({
  direction,
  slideCount,
  currentSlide,
  ...rest
}: {
  direction: 'previous' | 'next'
  slideCount?: number
  currentSlide?: number
}): ReactElement => {
  if (direction === 'previous') {
    return <ArrowLeftIcon {...rest} />
  } else {
    return <ArrowRightIcon {...rest} />
  }
}

export const CompanyMediaGallery = ({
  gallery,
  setActive,
}: {
  gallery?: IDatoCmsMediaGalleryItem[]
  setActive?: Function
}) => {
  const { ref } = useInView({
    triggerOnce: false,
    threshold: 0.6,
    onChange: inView => {
      if (inView && setActive) {
        setActive('gallery')
      }
    },
  })

  const mainSliderRef = useRef<Slider>()
  const thumbnailSliderRef = useRef<Slider>()

  return gallery?.length ? (
    <div className="border-b border-gray-200" id="gallery" ref={ref}>
      <div className="mb-4 mt-12 w-full">
        {/* @ts-ignore */}
        <Slider
          className={`${Styles.companyGallery} w-full`}
          infinite
          speed={500}
          arrows
          prevArrow={<IconWrapper direction="previous" />}
          nextArrow={<IconWrapper direction="next" />}
          slidesToShow={1}
          slidesToScroll={1}
          dots={false}
          ref={(slider: Slider) => (mainSliderRef.current = slider)}
          asNavFor={thumbnailSliderRef.current}>
          {gallery.map((media, idx) => {
            return (
              <div key={idx}>
                {media.__typename === 'ImageRecord' && (
                  <div className={Styles.companyMedia}>
                    <ImageKit
                      src={media.image.url}
                      alt={media.title}
                      fill
                      style={{ objectFit: 'cover' }}
                      className="rounded-lg"
                      placeholder="blur"
                      blurDataURL={media.image.blurUpThumb}
                    />
                  </div>
                )}
                {media.__typename === 'EmbeddedVideoRecord' &&
                  getYoutubeId(media.videoUrl) && (
                    <div className={Styles.companyMedia}>
                      <iframe
                        className="w-full"
                        allow="fullscreen"
                        src={`https://www.youtube-nocookie.com/embed/${getYoutubeId(
                          media.videoUrl,
                        )}`}></iframe>
                    </div>
                  )}
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="mb-4 w-full">
        {/* @ts-ignore */}
        <Slider
          className={`${Styles.companyGallery} w-full`}
          infinite
          speed={500}
          slidesToShow={gallery && gallery.length}
          slidesToScroll={1}
          arrows={false}
          centerMode
          dots={false}
          pauseOnFocus={true}
          focusOnSelect={true}
          ref={(slider: Slider) => (thumbnailSliderRef.current = slider)}
          asNavFor={mainSliderRef.current}>
          {gallery.length >= 2
            ? gallery.map((media, idx) => {
                return (
                  <div key={idx}>
                    <div className="mx-auto w-24 md:w-32">
                      {media.__typename === 'ImageRecord' && (
                        <div className={Styles.companyMediaThumbnail}>
                          <ImageKit
                            src={media.image.url}
                            alt={media.title}
                            fill
                            style={{
                              objectFit: 'cover',
                            }}
                            className="cursor-pointer rounded-lg"
                            placeholder="blur"
                            blurDataURL={media.image.blurUpThumb}
                          />
                        </div>
                      )}
                      {media.__typename === 'EmbeddedVideoRecord' &&
                        getYoutubeId(media.videoUrl) && (
                          <div className={Styles.companyMediaThumbnail}>
                            <iframe
                              className="pointer-events-none z-10 w-full"
                              allow="fullscreen"
                              src={`https://www.youtube-nocookie.com/embed/${getYoutubeId(
                                media.videoUrl,
                              )}`}></iframe>
                          </div>
                        )}
                    </div>
                  </div>
                )
              })
            : null}
        </Slider>
      </div>
    </div>
  ) : (
    <></>
  )
}
