import { slugify } from '@utils'

export const CompanySectionTitle = ({
  title,
  sectionTitle,
}: {
  title: string
  sectionTitle: string
}) => {
  return (
    <div
      id={slugify(sectionTitle)}
      className="mb-4 w-full border-t border-gray-200 pt-6">
      <p className="mb-6 font-extrabold text-primary md:text-xl">
        {sectionTitle}
      </p>
      <p className="mb-6 text-2xl font-extrabold tracking-tight text-themeBlack-dark md:text-3xl">
        {title}
      </p>
    </div>
  )
}
