import Tooltip from '@components/Tooltip/Tooltip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export const CompanyStatAlt = ({
  title,
  subtitle,
  tooltip,
}: {
  title?: string
  subtitle: string
  tooltip?: string
}) =>
  title ? (
    <div className="mb-6 flex w-full flex-col border-b border-primary pb-3">
      <div className="flex items-center justify-between">
        <p className="text-xs text-primary sm:text-sm">{subtitle}</p>
        {tooltip && (
          <Tooltip message={tooltip}>
            <InformationCircleIcon className="h-4 w-4 text-gray-500" />
          </Tooltip>
        )}
      </div>
      <p className="mb-1 text-2xl font-semibold text-primary md:text-3xl">
        {title}
      </p>
    </div>
  ) : null
