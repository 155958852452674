import React from 'react'
import { IDatoCmsTag, IDatoCmsSector } from '@types'
export const BlogHeader = ({
  heading,
  subHeading,
  sectors,
  tags,
}: {
  heading: string
  subHeading: string
  sectors: IDatoCmsSector[]
  tags: IDatoCmsTag[]
}) => {
  return (
    <>
      <h1 className="mb-2 text-xl font-bold text-themeBlack md:text-3xl">
        {heading}
      </h1>
      <h1 className="mb-1 pb-1 text-base text-gray-500">{subHeading}</h1>
      <div className="mb-4 flex flex-col md:flex-row">
        {sectors &&
          sectors.map((sector, ind) => (
            <span
              key={ind}
              className="mr-6 mt-1 pr-6 text-sm text-gray-500 md:border-r-2 md:border-gray-200 last:md:border-r-0">
              {sector?.name}
            </span>
          ))}
        {tags &&
          tags.map((tag, ind) => (
            <span
              key={ind}
              className="mr-3 mt-1 inline-block w-max rounded-[4px] border border-primary bg-primary/5 px-1 py-0.5 text-[8px] text-primary md:text-xs">
              {tag.name}
            </span>
          ))}
      </div>
    </>
  )
}
