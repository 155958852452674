import { useState, useRef } from 'react'
import { ImageKit } from '@components'
import { IEvent } from '@types'
import Slider from '@ant-design/react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Styles from './event.module.css'

export const EventGallery = ({
  pastEventGallery,
}: {
  pastEventGallery: IEvent
}) => {
  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const mainSliderRef = useRef<Slider>()
  const thumbnailSliderRef = useRef<Slider>()

  const navigateSlider = (slide: string) => {
    slide === 'next'
      ? mainSliderRef?.current?.slickNext()
      : mainSliderRef?.current?.slickPrev()
  }

  const beforeChange = (prev: number, next: number) => {
    setSliderIndex(next)
  }
  return (
    <div className="w-full lg:w-3/5">
      <div className="mx-0 mb-7 rounded-xl bg-white pl-0 pt-1 lg:mx-8 lg:mb-0 lg:pl-4">
        <div>
          {/* @ts-ignore */}
          <Slider
            infinite={false}
            speed={500}
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
            dots={false}
            beforeChange={beforeChange}
            ref={(slider: Slider) => (mainSliderRef.current = slider)}
            asNavFor={thumbnailSliderRef.current}>
            {pastEventGallery?.recapPictures?.map((media, idx) => {
              return (
                <div key={idx} className="pr-0 pt-2 md:pr-4">
                  <div className={`${Styles.companyMedia}`}>
                    <ImageKit
                      src={media.url}
                      alt={media.url}
                      fill
                      style={{
                        objectFit: 'cover',
                      }}
                      className="rounded-lg"
                    />
                  </div>
                </div>
              )
            })}
          </Slider>

          <div className="flex w-full flex-col justify-between md:flex-row">
            <div className="w-full py-2 lg:w-4/5 lg:py-0">
              {/* @ts-ignore */}
              <Slider
                infinite
                speed={500}
                slidesToShow={
                  pastEventGallery?.recapPictures &&
                  pastEventGallery?.recapPictures?.length < 4
                    ? pastEventGallery?.recapPictures?.length
                    : 4
                }
                variableWidth={true}
                slidesToScroll={1}
                arrows={false}
                dots={false}
                pauseOnFocus={true}
                focusOnSelect={true}
                ref={(slider: Slider) => (thumbnailSliderRef.current = slider)}
                asNavFor={mainSliderRef.current}>
                {pastEventGallery?.recapPictures?.length >= 1 ? (
                  pastEventGallery?.recapPictures?.map((media, idx) => {
                    return (
                      <div key={idx} className="w-full pr-2 lg:pr-4">
                        <div className="relative h-20 w-28">
                          <ImageKit
                            src={media.url}
                            alt={media.url}
                            fill
                            className="cursor-pointer rounded-lg object-cover"
                          />
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <></>
                )}
              </Slider>
            </div>
            <div className="flex w-full items-center justify-center md:ml-4 md:w-1/5">
              <div
                className={`mr-6 ${
                  sliderIndex === 0
                    ? 'pointer-events-none opacity-50'
                    : 'cursor-pointer'
                }`}
                onClick={() => navigateSlider('prev')}>
                <ImageKit
                  src="/images/Vector.png"
                  alt="vector"
                  width={15}
                  height={32}
                />
              </div>
              <div
                className={`cursor-pointer ${
                  sliderIndex === pastEventGallery?.recapPictures?.length - 1
                    ? 'pointer-events-none opacity-50'
                    : 'cursor-pointer'
                }`}
                onClick={() => navigateSlider('next')}>
                <ImageKit
                  src="/images/Vector-right.png"
                  alt="vector"
                  width={15}
                  height={32}
                />
              </div>
            </div>
          </div>
        </div>
        <p className="px-4 text-lg font-light md:px-0 md:py-4 md:pr-8 lg:text-2xl">
          {pastEventGallery?.recapText}
        </p>
      </div>
    </div>
  )
}
